import React, { FC, useEffect } from "react";
import { generatePath, Link, Route, Routes, useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { ModalBackdrop } from "./ModalBackdrop";

const Wrap = styled(ModalBackdrop)`
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const ImageWrap = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    position: relative;
`;
const Image = styled.img`
    object-fit: contain;
    position: absolute;
    top: 0;
    left: 0;
    min-width: 100%;
    max-width: 100%;
    min-height: 100%;
    max-height: 100%;
`;
const Controls = styled.div`
    display: flex;
    align-items: stretch;
    justify-content: stretch;
`;

const controlStyles = `
    flex: 1;
    display: flex;
    justify-content: center;
    padding: 1rem 0;
    font-weight: 800;
    background: #FFFFFFcc;
    :hover {
        background: #FFFFFF;
    }
`;
const ControlLink = styled(Link)`
    ${controlStyles}
`;
const ControlButton = styled.button`
    ${controlStyles}
`;

type LightboxProps = {
    images: string[],
    path: string,
    baseUrl: string,
};

export const LightboxSimple: FC<{
    image: string;
    onClose: () => void;
}> = ({
    image,
    onClose,
}) => (
    <Wrap
        $open={true}
        $zIndex={1000}
    >
        <ImageWrap>
            <Image src={image} />
        </ImageWrap>
        <Controls>
            <ControlButton onClick={onClose}>
                ✕
            </ControlButton>
        </Controls>
    </Wrap>
);

const LightboxInternal: FC<LightboxProps> = ({
    images,
    baseUrl,
    path,
}) => {
    const { lightboxIndex } = useParams();
    const index = parseInt(lightboxIndex || '0', 10);
    const url = `${baseUrl}/${path}/:lightboxIndex`;
    const invalid = !images || !images.length || !images[index] || isNaN(index);
    const navigate = useNavigate();

    useEffect(() => {
        if (invalid) {
            navigate(baseUrl, { replace: true });
        }
    }, [invalid, navigate, baseUrl]);

    if (invalid)
        return null;

    return (
        <Wrap
            $open={true}
            $zIndex={1000}
        >
            <ImageWrap>
                <Image src={images[index]} />
            </ImageWrap>
            <Controls>
                <ControlLink to={generatePath(url, {
                    lightboxIndex: (index === 0 ? images.length - 1 : index - 1).toString()
                })} replace={true}>
                    《
                </ControlLink>
                <ControlLink to={baseUrl}>
                    ✕
                </ControlLink>
                <ControlLink to={generatePath(url, {
                    lightboxIndex: (index === images.length - 1 ? 0 : index + 1).toString()
                })} replace={true}>
                    》
                </ControlLink>
            </Controls>
        </Wrap>
    );
}

export const Lightbox: FC<LightboxProps> = (props) => (
    <Routes>
        <Route
            path={`${props.path}/:lightboxIndex`}
            element={<LightboxInternal {...props} />}
        />
    </Routes>
);
