import React, { FC } from "react";
import { Link, useLocation, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { LinkButton } from "../Button";
import { cssVar } from "src/ui/styles/global";
import { PageData } from "src/types";
import { mediaQuery } from "src/ui/styles";

const Wrap = styled.div`
    text-align: center;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    > div {
        white-space: nowrap;
    }
    gap: .75rem;

    ${mediaQuery.verticalmedium(`
        flex-direction: row;
    `)}
`;
const StyledLink = styled(Link)<{
    $disabled?: boolean,
}>`
    font-family: var(${cssVar.maintext});
    font-size: 1.1rem;
    font-weight: 500;
    color: var(${cssVar.accent});
    padding: .25rem .5rem;
    margin: 0 .25rem;
    background-color: var(${cssVar.lightshade});
    border: 1px solid var(${cssVar.lightshade});
    border-radius: 50%;
    
    ${props => props.$disabled ? `
        pointer-events: none;
        font-weight: 200;
        color: var(${cssVar.darkaccent});
    ` : ''}

    &:hover {
        box-shadow: 2px 2px 5px var(${cssVar.darkshade});
        color: var(${cssVar.lightshade});
        background-color: var(${cssVar.darkaccent});
        border-color: var(${cssVar.lightaccent});
    }
`;
const ActivePage = styled.span`
    font-size: 1.1rem;
    color: var(${cssVar.darkshade});
    font-weight: 600;
    padding: 0 .25rem;
    margin: 0 .25rem;
`;

const pageButtons = 2;

export const Pagination: FC<{
    pageData?: PageData,
    disabled?: boolean
}> = ({
    pageData,
    disabled,
}) => {
    const [params] = useSearchParams();
    const location = useLocation();

    if (!pageData)
        return null;
    
    const url = (page: string | number) => {
        const newParams = new URLSearchParams(params);
        newParams.set('page', `${page}`);

        return `${location.pathname}?${newParams}`;
    }

    const backPageButtons = [];
    const forwardPageButtons = [];
    for (let i = pageButtons; i > 0; i--) {
        if (pageData.currentPage - i > 0) {
            const to = url(pageData.currentPage - i);
            backPageButtons.push(
                <StyledLink
                    key={to}
                    to={to}
                    $disabled={disabled}
                >
                    {pageData.currentPage - i}
                </StyledLink>
            );
        }
        const invertedI = (pageButtons - i) + 1;
        if (pageData.currentPage + invertedI <= pageData.totalPages) {
            const to = url(pageData.currentPage + invertedI);
            forwardPageButtons.push(
                <StyledLink
                    key={to}
                    to={to}
                    $disabled={disabled}
                >
                    {pageData.currentPage + invertedI}
                </StyledLink>
            )
        }
    }

    return (
        <Wrap>
            <LinkButton
                to={url(1)}
                $disabled={disabled || pageData.currentPage === 1}
            >
                First page
            </LinkButton>
            <div>
                <LinkButton
                    to={url(pageData.currentPage - 1)}
                    $disabled={disabled || pageData.currentPage === 1}
                >
                    Prev
                </LinkButton>
                {backPageButtons}
                <ActivePage>
                    {pageData.currentPage}
                </ActivePage>
                {forwardPageButtons}
                <LinkButton
                    to={url(pageData.currentPage + 1)}
                    $disabled={disabled || pageData.currentPage === pageData.totalPages || pageData.totalPages === 0}
                >
                    Next
                </LinkButton>
            </div>
        </Wrap>
    )
}

