import React, { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { FieldValues, FormProvider } from "react-hook-form";
import { cssVar } from "src/ui/styles";
import styled from "styled-components";
import { Flex } from "../Flex";
import { NavPrompt } from "../NavPrompt";
import { PagePadding } from "../PagePadding";

const StyledForm = styled.form`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;
const Scrollable = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow: auto;
    align-items: center;
`;
const Constrain = styled(PagePadding)`
    max-width: 1000px;
`;
const Footer = styled.div`
    padding: .5rem;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: var(${cssVar.darkaccent});
`;

type FormProps<T extends FieldValues, > = ComponentProps<typeof FormProvider<T>>;

export const Form = <T extends FieldValues, >({
    onSubmit,
    children,
    footer,
    shouldPrompt = true,
    ...formsApi
}: PropsWithChildren<{
    onSubmit: (values: FieldValues) => void;
    footer?: ReactNode;
    shouldPrompt?: boolean;
} & FormProps<T>>
) => (
    <StyledForm onSubmit={formsApi.handleSubmit(onSubmit)}>
        <NavPrompt active={shouldPrompt && formsApi.formState.isDirty} />
        <FormProvider {...formsApi}>
            <Scrollable>
                <Constrain>
                    <Flex $gap=".75rem" $direction="column">
                        {children}
                    </Flex>
                </Constrain>
            </Scrollable>
            {footer && (
                <Footer>{footer}</Footer>
            )}
        </FormProvider>
    </StyledForm>
);
