import styled from "styled-components";
import { cssVar } from "src/ui/styles/global";


const headerStyle = `
    font-family: var(${cssVar.headingtext});
    font-weight: 400;
    letter-spacing: .05rem;
`;

export const H1 = styled.h1`
    ${headerStyle}
    font-size: 1.75rem;
    margin: .75rem 0;
    text-transform: capitalize;

    &:first-of-type {
        margin-top: 0;
    }
`;
export const H2 = styled.h2`
    ${headerStyle}
    font-size: 1.65rem;
    margin: .65rem 0;
`;

export const H3 = styled.h3`
    ${headerStyle}
    font-size: 1.5rem;
    margin: .5rem 0;
`;

export const H4 = styled.h4`
    ${headerStyle}
    font-size: 1.35rem;
    margin: .35rem 0;
`;

export const H5 = styled.h5`
    ${headerStyle}
    font-size: 1.25rem;
    margin: .25rem 0;
`;

export const H6 = styled.h6`
    ${headerStyle}
    font-size: 1rem;
    margin: 0;
`;

export const Strong = styled.strong`
    font-weight: 800;
`;
export const Paragraph = styled.p<{ $compact?: boolean; }>`
    font-weight: 400;
    font-size: 1rem;
    letter-spacing: .03rem;
    white-space: pre-wrap;
    ${props => props.$compact && `
        padding: 0;
        margin: 0;
    `}
`;