
export enum AppRoute {
    homepage = "/",
    publicListingById = "/listing/:listingId",
    login = "/login/",
    logout = "/logout/",
    dashboard = "/app/",
    // editProfile = "/app/profile/",
    allUsers = "/app/users/",
    editUser = "/app/users/:userId",
    createUser = "/app/users/create",
    listings = "/app/listings/",
    listingById = "/app/listings/:listingId",
    postListing = "/app/listings/create",
    editListing = "/app/listings/edit/:listingId",
    editListingPhotos = "/app/listings/edit/photos/:listingId",
    editListingDetails = "/app/listings/edit/details/:listingId",
    editListingChoices = "/app/listings/edit/choices/:listingId",
    manageOffers = "/app/offers/",
    myOffers = "/app/myoffers/",
    // buyerList = "/app/buyers/",
    // buyerProfile = "/app/buyers/:userId",
    // inviteBuyer = "/app/buyers/invite",
    // sellerList = "/app/sellers/",
    // sellerProfile = "/app/sellers/:userId",
    // inviteSeller = "/app/sellers/invite",
    apiQuery = "/app/apiquery",
};
