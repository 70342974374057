import { useEffect, useRef, useState } from "react";
import { FieldValues } from "react-hook-form";

const shallowEqual = (
    objA: Record<string, any> | undefined,
    objB: Record<string, any> | undefined,
) => {
    if (!objA && !objB) {
        return true;
    }
    if ((objA && !objB) || (!objA && objB)) {
        return false;
    }
    const propsA = Object.getOwnPropertyNames(objA);
    const propsB = Object.getOwnPropertyNames(objB);

    if (propsA.length !== propsB.length) {
        return false;
    }
    const propsEqual = propsA.every(
        (val, idx) => val === propsB[idx]
    );
    if (!propsEqual) {
        return false;
    }
    for (let i = 0; i < propsA.length; i++) {
        const valueA = objA![propsA[i]];
        const valueB = objB![propsB[i]];

        if (isNaN(valueA) && isNaN(valueB)) {
            continue;
        }
        if (valueA !== valueB) {
            return false;
        }
    }
    return true;
}

export const useLocalPersist = (
    key: string,
) => {
    const [data, setData] = useState<
        Record<string, Record<string, any> | undefined>
    >(() => {
        try {
            return JSON.parse(
                window.localStorage.getItem('localPersist') || ''
            );
        } catch {
            return {};
        }
    });
    const previous = useRef<FieldValues | undefined>(undefined);
    const store = (values: FieldValues | undefined) =>
        setData(oldData => {
            const newData = {
                ...oldData,
                [key]: values,
            };
            window.localStorage.setItem(
                'localPersist',
                JSON.stringify(newData)
            );
            return newData;
        });
    
    useEffect(() => {
        previous.current = undefined;
    }, [key])

    return {
        persistedValues: data[key],
        persist: (values: FieldValues | undefined) => {
            if (!shallowEqual(values, previous.current)) {
                store(values);
            }
            previous.current = values;
        },
    };
};
