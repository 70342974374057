import { useMemo } from "react";
import { useFetch } from "src/api";
import { ListingPhoto } from "src/types";

export const defaultPhotoUrl = process.env.PUBLIC_URL + '/missingPhoto.png';

export const useListingPhotoUrls = (listingId: string) => {
    const {
        loading,
        error,
        data,
        status,
    } = useFetch<ListingPhoto[]>(`/api/listings/${listingId}/photos/`);

    const photos = useMemo(() => data && data.length ? (
            data.map(p => p.url)
        ) : [defaultPhotoUrl],
        [data]
    );

    return {
        photos,
        loading,
        error,
        rawData: data,
        status,
    };
};
