import React, { FC, useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import { useLocalPersist } from "../primitives/form";
import { OfferForm } from "./OfferForm";
import { useIsLoggedIn } from "src/api";
import { LinkButton } from "../primitives";
import { useLoginRouteWithReturn } from "../pages";

const Container = styled.div`
    position: fixed;
    bottom: 0;
    right: 1rem;
    margin-left: 1rem;
    z-index: 900;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;
const FormOverflow = styled.div<{ $hidden: boolean }>`
    max-height: ${props => props.$hidden ? '0' : '70vh'};
    transition: max-height ${props => props.$hidden ? '.3s' : '1s'} ease;
    overflow: hidden;
    display: flex;
`;
const FormBackground = styled.div`
    background: white;
    border: 1px solid #666;
    min-width: 50vw;
    border-radius: 1rem 0 0 0;
    overflow: auto;
    display: flex;
`;

const Tab = styled.button`
    background-color: white;
    border: 1px solid #666;
    border-bottom: none;
    padding: 1rem 2rem;
    border-radius: .4rem .4rem 0 0;
    font-size: 1.3rem;
    flex-grow: 0;
    cursor: pointer;
    position: relative;
    top: 1px;
`;

const WrapLoginButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    flex: 1;
`;

export const OfferNowContainer: FC = () => {
    const { listingId = '' } = useParams();
    const [hidden, setHidden] = useState<boolean>(true);
    const toggleHidden = useCallback(() => setHidden(h => !h), []);
    const {
        persistedValues
    } = useLocalPersist(`offer/${listingId}`);
    const loggedIn = useIsLoggedIn();
    const loginRoute = useLoginRouteWithReturn();

    // hasOffer? if so, show non-editable Offer display rather than
    // form and "View Offer" rather than "Make Offer"

    return (
        <Container>
            <Tab onClick={toggleHidden}>
                {persistedValues && persistedValues.price ? (
                    'Continue Offer'
                ) : hidden ? (
                    'Make Offer'
                ) : (
                    'Hide Offer'
                )}
            </Tab>
            <FormOverflow $hidden={hidden}>
                <FormBackground>
                    {loggedIn ? (
                        <OfferForm
                            listingId={listingId}
                            shouldPrompt={false}
                        />
                    ) : (
                        <WrapLoginButton>
                            <LinkButton to={loginRoute}>
                                Log In for more options
                            </LinkButton>
                        </WrapLoginButton>
                    )}
                </FormBackground>
            </FormOverflow>
        </Container>
    );
};
