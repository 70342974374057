import React, { FC } from "react";
import { useFormContext } from "react-hook-form";
import styled from "styled-components";
import { mediaQuery } from "../styles";
import { useFetchCountryOptions, useFetchRegionsOptions } from "./countriesRegions";
import { FormInput, Fieldset, OnOptionsLoaded } from "src/ui/primitives/form";

const CityRegionPostal = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .25rem;
    div:first-child {
        min-width: 100%;
    }
    ${mediaQuery.verticalmedium(`
        div:first-child {
            min-width: 50%;
        }
    `)}
`;

const defaultCountry = "US";

export const AddressInputs: FC<{
    fieldPrefix?: string;
    legend?: string;
    disabled?: boolean;
    required?: boolean;
}> = ({
    fieldPrefix = '',
    legend = 'Address',
    disabled = false,
    required = false,
}) => {
    const {
        register,
        watch,
        setValue,
    } = useFormContext();
    const fetchCountryOptions = useFetchCountryOptions();

    const prefix = fieldPrefix
        ? `${fieldPrefix}`
        : '';

    const fetchRegionOptions = useFetchRegionsOptions(
        watch(`${prefix}country`) || defaultCountry
    );

    const region = watch(`${prefix}region`);
    // can't use usecallback for any OnOptionsLoaded
    const handleRegionsLoaded: OnOptionsLoaded = (element, options) => {
        if (options && options.length) {
            const validRegion = !!options.find(o => o.value === region);
            if (!validRegion && element) {
                setValue(`${prefix}region`, null);
                element.value = '';
            }
        }
    };

    return (
        <Fieldset legend={legend}>
            <FormInput
                loadOptions={fetchCountryOptions}
                label="Country"
                defaultValue={defaultCountry}
                {...register(`${prefix}country`, {
                    required: "Country is required",
                    disabled,
                })}
            />
            <FormInput
                placeholder="Address 1"
                label="Address 1"
                {...register(`${prefix}address1`, {
                    required: required ? "Address 1 is required" : false,
                    maxLength: {
                        value: 65000,
                        message: "Address 1 is too long",
                    },
                    disabled,
                })}
            />
            <FormInput
                placeholder="Address 2"
                label="Address 2"
                {...register(`${prefix}address2`, {
                    maxLength: {
                        value: 65000,
                        message: "Address 2 is too long",
                    },
                    disabled,
                })}
            />
            <CityRegionPostal>
                <FormInput
                    placeholder="City"
                    label="City"
                    {...register(`${prefix}city`, {
                        required: required ? "City is required" : false,
                        maxLength: {
                            value: 65000,
                            message: "City is too long",
                        },
                        disabled,
                    })}
                />
                <FormInput
                    placeholder="Region"
                    label="Region"
                    loadOptions={fetchRegionOptions}
                    onOptionsLoaded={handleRegionsLoaded}
                    {...register(`${prefix}region`, {
                        required: required ? "Region is required" : false,
                        maxLength: {
                            value: 65000,
                            message: "Region is too long",
                        },
                        disabled,
                    })}
                />
                <FormInput
                    placeholder="Postal"
                    label="Postal"
                    {...register(`${prefix}postal`, {
                        required: required ? "Postal Code is required" : false,
                        maxLength: {
                            value: 65000,
                            message: "Postal is too long",
                        },
                        disabled,
                    })}
                />
            </CityRegionPostal>
        </Fieldset>
    );
};
