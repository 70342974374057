import React, { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { Button } from "./Button";
import { Input } from "./form";
import { Paragraph } from "./text";

const Wrap = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: .75rem;
`;

const makeNewParams = (params: URLSearchParams, term: string) => {
    const newParams = new URLSearchParams(params);
    if (term) {
        newParams.set('search', term);
    } else {
        newParams.delete('search');
    }
    if (newParams.has('page')) {
        newParams.set('page', '1');
    }

    return newParams;
};
export const SearchByParam: FC = () => {
    const [params, setParams] = useSearchParams();
    const [term, setTerm] = useState<string>(
        () => params.get('search') || ''
    );
    const handleInput: ChangeEventHandler<HTMLInputElement> = useCallback(
        (event) => setTerm(event.target.value),
        []
    );
    const commitTerm = useCallback(
        () => setParams(
            makeNewParams(params, term)
        ),
        [params, setParams, term]
    );
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (ev) => {
            if (ev.key === "Enter")
                commitTerm();
        },
        [commitTerm]
    );
    const handleClear = useCallback(() => {
        setTerm('');
        setParams(
            makeNewParams(params, '')
        );
    }, [params, setParams]);
    const currentSearch = params.get('search');

    return <>
        <Wrap>
            <Input
                type="text"
                value={term}
                placeholder="Search"
                onInput={handleInput}
                onKeyDown={handleKeyDown}
            />
            <Button type="button" onClick={commitTerm}>
                Search
            </Button>
            <Button
                type="button"
                onClick={handleClear}
                disabled={!currentSearch}
            >
                Clear
            </Button>
        </Wrap>
        {currentSearch && (
            <Paragraph>
                Showing results for "{currentSearch}"
            </Paragraph>
        )}
    </>;
};
