import React, { FC, useEffect, useRef } from "react";
import styled from "styled-components";
import { cssVar } from "src/ui/styles";
import { useUser } from "src/ui/user";
import { Loading } from "../primitives";
import { Link } from "react-router-dom";
import { useUserMenuRoutes } from "./hooks";
import { Icon } from "../primitives/Icon";

const UserMenuTrigger = styled.button`
    display: flex;
    position: relative;
    border: none;
    background: none;
    padding: 1rem;
    line-height: 1.5rem;
    border-top: 1px solid var(${cssVar.lightaccent});
    color: var(${cssVar.lightshade});
    font-weight: 400;
    font-size: 1.15rem;
    justify-content: space-between;
    text-align: left;
    overflow: hidden;
    &:hover, &.active {
        background: var(${cssVar.lightaccent});
        color: var(${cssVar.accent});
        border-color: var(${cssVar.accent});
    }
    &.active {
        background: var(${cssVar.lightshade});
    }
`;
const StyledIcon = styled(Icon)`
    position: absolute;
    top: 1rem;
    right: 1rem;
`;
const Text = styled.div`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    > span {
        display: block;
        font-size: .9rem;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
`;
const Wrap = styled.div`
    position: relative;
    margin: 0 .5rem;
`;
const Flyout = styled.div<{
    $open: boolean;
}>`
    position: absolute;
    bottom: 100%;
    background: white;
    border-radius: .5rem .5rem 0 0;
    overflow: hidden;
    max-height: ${props => props.$open ? '30vh' : '0'};
    transition: max-height .3s ease;
    width: 100%;
    outline: none;
    box-sizing: border-box;
`;
const StyledLink = styled(Link)`
    display: block;
    padding: 1rem;
    &:hover {
        background: var(${cssVar.transparentshade});
    }
`;

export const UserMenu: FC<{
    isOpen: boolean,
    toggle: () => void,
    close: () => void,
    onItemClick: () => void,
}> = ({
    isOpen,
    toggle,
    close,
    onItemClick,
}) => {
    const user = useUser();
    const flyoutRef = useRef<HTMLDivElement>(null);
    const triggerRef = useRef<HTMLButtonElement>(null);
    const menuItems = useUserMenuRoutes();

    useEffect(() => {
        if (!isOpen) {
            return;
        }
        flyoutRef.current?.focus();
        const handler = (ev: Event) => {
            if (
                !flyoutRef.current?.contains(ev.target as Node)
                && !triggerRef.current?.contains(ev.target as Node)
            ) {
                close();
            }
        }
        document.body.addEventListener('click', handler);
        document.body.addEventListener('focus', handler, true);

        return () => {
            document.body.removeEventListener('click', handler);
            document.body.removeEventListener('focus', handler, true);
        };
    }, [isOpen, close]);

    return <>
        <Wrap>
            <Flyout
                $open={isOpen}
                ref={flyoutRef}
                tabIndex={isOpen ? 0 : -1}
            >
                {menuItems.map(item => (
                    <StyledLink
                        key={item.route}
                        to={item.route}
                        tabIndex={isOpen ? 0 : -1}
                        onClick={onItemClick}
                    >
                        {item.label}
                    </StyledLink>
                ))}
            </Flyout>
        </Wrap>
        <UserMenuTrigger
            onClick={toggle}
            ref={triggerRef}
        >
            {!user ? <Loading /> : (
                <Text title={`${user.name} (${user.email})`}>
                    {user.name}
                    <span>{user.email}</span>
                    <StyledIcon
                        name="caretUp"
                        color="#FFFFFF66"
                    />
                </Text>
            )}
        </UserMenuTrigger>
    </>;
};
