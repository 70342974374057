import React, {
    createContext,
    FC,
    PropsWithChildren,
    useContext,
    useMemo,
    useRef,
    useState,
} from "react";
import { fetchApi } from "src/api"
import { CacheKeys, CacheTypes, cacheUrls } from "./sources";

// https://www.typescriptlang.org/docs/handbook/2/mapped-types.html
type CacheFetchers = {
    [Property in keyof CacheTypes]: (params?: URLSearchParams) => Promise<CacheTypes[Property]>
};

type Cache = Partial<Record<string, object>>;
type CacheLoadState = Partial<Record<string, boolean>>;

type CacheContextValue = {
    cache: Cache,
    loading: CacheLoadState,
    fetchers: CacheFetchers,
};

const blankFetchers = Object.values(CacheKeys).reduce(
    (fetchers, key) => {
        fetchers[key] = () => Promise.resolve({});
        return fetchers;
    },
    {} as CacheFetchers
);

const cacheContext = createContext<CacheContextValue>({
    cache: {},
    loading: {},
    fetchers: blankFetchers,
});

export const CacheProvider: FC<PropsWithChildren> = ({
    children,
}) => {
    const [cache, setCache] = useState<Cache>({});
    const [loading, setLoading] = useState<CacheLoadState>({});
    const promisesRef = useRef<
        Partial<Record<string, Promise<object>>>
    >({});

    const fetchers = useMemo(() =>
        Object.values(CacheKeys).reduce(
            (cacheFetcher, cacheKey) => {
                // @ts-ignore
                cacheFetcher[cacheKey as keyof CacheFetchers] = async (
                    params: URLSearchParams = new URLSearchParams(),
                ) => {
                    if (params) {
                        params.sort();
                    }
                    const apiUrl = cacheUrls[cacheKey];
                    const key = `${apiUrl}?${params}`;

                    const cachedPromise = promisesRef.current[key];
                    if (cachedPromise) {
                        return await cachedPromise;
                    }

                    const promise = new Promise<object>(async (resolve) => {
                        setLoading(oldLoading => ({
                            ...oldLoading,
                            [key]: true,
                        }));
                        const response = await fetchApi(`${apiUrl}?${params}`);
        
                        if (response.status !== 200) {
                            throw new Error(`Unable to fetch cachable from "${apiUrl}"`);
                        }
        
                        const result = await response.json();
        
                        setCache(oldCache => ({
                            ...oldCache,
                            [key]: result,
                        }));
                        setLoading(oldLoading => ({
                            ...oldLoading,
                            [key]: false,
                        }));
                        resolve(result);
                    });
                    promisesRef.current[key] = promise;
    
                    return await promise;
                };
    
                return cacheFetcher;
            },
            {} as CacheFetchers
        ),
        []
    );

    const result: CacheContextValue = useMemo(() => ({
        cache,
        loading,
        fetchers,
    }), [cache, loading, fetchers]);

    return (
        <cacheContext.Provider value={result}>
            {children}
        </cacheContext.Provider>
    );
};

export const useCache = () => useContext(cacheContext);
