import styled from "styled-components";

export const Flex = styled.div<{
    $direction?: 'row' | 'column',
    $gap?: string,
    $align?: string,
    $justify?: string,
    $flex?: string,
    $children?: boolean,
    $pad?: string,
}>`
    display: flex;
    ${props => `
        flex-direction: ${props.$direction || 'row'};
        gap: ${props.$gap || '0'};
        align-items: ${props.$align || 'stretch'};
        justify-content: ${props.$justify || 'stretch'};
        ${props.$flex && `flex: ${props.$flex};`};
        ${props.$pad && `padding: ${props.$pad};`}
        ${props.$children && `
            > * {
                flex: 1;
            }
        `}
    `}
`;