import React, { FC } from "react";
import styled from "styled-components";

const StyledPath = styled.path`
    fill: #532b77;
`;

export const LogoIcon: FC<{
    size?: number;
    className?: string;
}> = ({ size = 192, className }) => (
    <svg
        version="1.2"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${size} ${size}`}
        width={size}
        height={size}
        className={className}
    >
        <StyledPath fillRule="evenodd" d="m181.3 100.2c-42 13.1-70.3 41.1-81.2 80.7-1.9 6.6-6.7 6.5-9-0.4-12.5-38.7-38.5-67.2-79.9-80-7.3-2.3-7.3-6.8 0.4-9.2 40.7-12.8 69.7-41.1 80.2-81.4 1.3-4.9 6.9-5.2 8.4 0 12 40.7 39.7 69.8 81.8 82.1 6 1.7 5.5 6.3-0.7 8.2zm-63.7 15.5c-7.2-13.1-6.8-26.7 0.1-39.4 0.9-1.6-0.5-2.9-2-2-12.2 7.2-26 7-38.9 0.3-2.5-1.3-3.5-0.2-2.3 2.1 6.8 13.1 6.2 26.1 0 38.5-1.2 2.2 0 3.4 2 2.2 12.2-6.8 25.7-6.8 39 0.2 2 1 3.2 0 2.1-1.9z"/>
    </svg>
);
