import React, { FC } from "react";
import styled from "styled-components";
import { mediaQuery } from "../styles";

const Wrap = styled.div`
    display: flex;
    justify-content: center;
`;
const StyledFrame = styled.iframe`
    margin: .5rem auto;
    width: 100%;
    aspect-ratio: 2;
    ${mediaQuery.large(`
        width: 75%;
    `)}
`;

export const Matterport: FC<{
    url?: string | undefined | null
}> = ({ url }) => {
    if (!url)
        return null;

    return (
        <Wrap>
            <StyledFrame
                src={url}
                frameBorder="0"
                allowFullScreen
                allow="xr-spatial-tracking"
            />
        </Wrap>
    );
};
