import { useCallback, useRef, useEffect } from "react";

/*
    example usage:

    const [anyState, setANyState] = useState();
    const startAsync = useAsyncRequestCancel();

    useEffect(() => {
        (async () => {
        const thisRequestCancelled = startAsync();

        const result = await aThingThatTakesALongTime();

        if (thisRequestCancelled()) {
            // there are two ways that a request can be marked
            // "cancelled". first, if startAsync has been called
            // again. it can be assumed that this request is old
            // and should be ignored. that newer process that
            // called startAsync is the newer request using fresh
            // data and will eventually populate state.
            // the second way a request can be cancelled is if
            // this component has unmounted, in which case it
            // should not set state since that will result in
            // a warning about a memory leak.
            return;
        }
        setSomeState(result);
        })();
    }, []);

    return someState;
*/

type isRequestCancelled = () => boolean;
type startAsyncRequest = () => isRequestCancelled;

export const useAsyncRequestCancel = (): startAsyncRequest => {
    const countRef = useRef<number>(0);
    const tokenRef = useRef<number | undefined>();
    const unmountedRef = useRef<boolean>(false);

    useEffect(
        () => {
            unmountedRef.current = false;

            return () => {
                unmountedRef.current = true;
                // this effect only runs once, so it only gets cleaned
                // up when the component is unmounting.
                // we can know the component is unmounted by setting an effect
                // that will only be cleaned up at the end of the component
                // lifecycle.
            }
        },
        []
    );

    return useCallback(() => {
        const thisRequestToken = countRef.current++;
        tokenRef.current = thisRequestToken;

        // has the tokenRef changed since this function generated uuid?
        // if so, something else has called this which updated tokenRef
        // so another process must be going. Cancel async.
        return () =>
            thisRequestToken !== tokenRef.current || unmountedRef.current === true;
    }, []);
};
