import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { mediaQuery } from "../styles";


const Wrap = styled.div`
    display: flex;
    justify-content: center;
`;
const StyledFrame = styled.iframe`
    margin: .5rem auto;
    width: 100%;
    aspect-ratio: 560/315;
    ${mediaQuery.large(`
        width: 75%;
    `)}
`;

const extractYoutubeId = (url?: string) => {
    // https://stackoverflow.com/a/54200105
    if (!url)
        return null;

    const parts = url.split(/(vi\/|v=|\/v\/|youtu\.be\/|\/embed\/)/);

    return parts[2] !== undefined
        ? parts[2].split(/[^0-9a-z_-]/i)[0]
        : parts[0];
 }

export const YoutubeVideo: FC<{
    url?: string | undefined | null,
    title?: string,
}> = ({
    url,
    title,
}) => {
    const id = useMemo(() => {
        if (!url)
            return undefined;
        
        return extractYoutubeId(url);
        // if (url.match(/v=.*?\&/)) {

        // } else if (url.match(/\/v\/.*?[\/\?$]/)) {

        // } else if (url.match(/youtu.be\/.*?[\/\?$]/)) {

        // } else if (url.match(/\/embed\/.*?[\/\?$]/)) {

        // } else if (url.match(/youtube.com\/e\/.*?[\/\?$]/)) {

        // }
    }, [url]);

    if (!url || !id)
        return null;

    return (
        <Wrap>
            <StyledFrame
                src={`https://www.youtube.com/embed/${id}`}
                title={title || "YouTube video player"}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
            />
        </Wrap>
    );
}