import React, { FC } from "react";
import styled from "styled-components";

const Wrap = styled.div<{
    $src: string;
    $height: string;
    $suppressBlur: boolean;
}>`
    display: flex;
    height: ${props => props.$height};
    min-height: ${props => props.$height};
    max-height: ${props => props.$height};
    position: relative;
    overflow: hidden;
    ::before {
        content: ' ';
        position: absolute;
        top: -5%;
        left: -5%;
        width: 110%;
        height: 110%;
        background-color: #DDD;
        opacity: .5;
        ${props => !props.$suppressBlur && `
            background: url(${props.$src});
            background-size: cover;
            background-position: center center;
            filter: blur(20px);
        `}
    }
    img {
        position: absolute;
        top: 3%;
        left: 3%;
        width: 94%;
        height: 94%;
        object-fit: contain;
    }
`;

export const ConstrainedImg: FC<{
    src: string;
    height: string;
    alt?: string;
    className?: string;
    suppressBlur?: boolean;
}> = ({ src, height, alt, className, suppressBlur }) => (
    <Wrap
        $src={src}
        $height={height}
        $suppressBlur={!!suppressBlur}
        className={className}
    >
        <img src={src} alt={alt} title={alt} />
    </Wrap>
)