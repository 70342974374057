import React, { ComponentProps, FC } from "react";
import styled from "styled-components";
import { mediaQuery } from "../styles";
import { Icon } from "./Icon";

export const IconGrid = styled.div<{ $small?: boolean; }>`
    display: flex;
    align-items: center;
    ${props => props.$small ? `
        flex-wrap: nowrap;
        justify-content: space-between;
    ` : `
        flex-wrap: wrap;
    `}
    > div {
        ${props => props.$small ? `
            width: fit-content;
            font-size: 1.1rem;

            /*
                // // THIS IS PROBLEMATIC WHEN THE NUMBERS RUN TOGETHER
                // select last half of elements
                // https://stackoverflow.com/a/15467744
                // https://dev.to/mingyena/nth-child-trick-get-the-first-last-half-of-elements-30d5
                &:first-child:last-child,
                &:nth-child(n+2):nth-last-child(-n+2),
                &:nth-child(n+3):nth-last-child(-n+3),
                &:nth-child(n+4):nth-last-child(-n+4) {
                    flex-direction: row-reverse;
                }
            */
        ` : `
            min-width: 50%;
            max-width: 50%;
            :nth-of-type(even) {
                flex-direction: row-reverse;
            }
            ${mediaQuery.verticalmedium(`
                :nth-of-type(even) {
                    flex-direction: row;
                }
                min-width: 33.33%;
                max-width: 33.33%;
            `)}
            ${mediaQuery.xlarge(`
                > label {
                    display: block;
                }
            `)}
        `}
    }
`;
const IconBlockWrap = styled.div`
    box-sizing: border-box;
    display: flex;
    gap: .5rem;
    margin: .5rem 0;
    font-size: 1.5rem;
    > label {
        display: none;
        font-size: 1rem;
        margin-top: .3rem;
        ::after {
            content: ':';
        }
    }
    > span {
        font-size: 1.2rem;
        opacity: .8;
    }

    @media (pointer: coarse), (hover: none) {
        position: relative;
        &:focus::after {
            content: attr(title);
            font-size: 1.3rem;
            position: absolute;
            bottom: 100%;
            color: #000;
            background-color: #fff;
            border: 1px solid #999;
            width: fit-content;
            padding: 3px;
            border-radius: .3rem;
            box-shadow: 0 .15rem .5rem #00000033;
        }
    }
`;
export const IconGridItem: FC<{
    icon: ComponentProps<typeof Icon>["name"],
    fields?: Record<string, any> | undefined,
    name: string,
    title: string,
}> = ({
    icon,
    fields,
    name,
    title,
}) => {
    if (!fields || !fields[name])
        return null;

    const value = fields[name];
    const unit = fields[name + '$unit'];

    return (
        <IconBlockWrap tabIndex={0} title={`${title}: ${value}`}>
            <Icon name={icon} />
            <label>{title}</label>
            {value} {unit && <span>{unit}</span>}
        </IconBlockWrap>
    );
};

