
export const sectionNameToLabel: Record<string, string> = {
    'urls': 'Web URLs',
    'listing_agent': 'Listing Agent Info',
    'area_information': 'Area Information',
};

export const fieldNameToLabel: Record<string, string> = {
    'description': 'Property Description',
    'terms': 'Terms and Conditions',
    'disclaimer': 'Disclaimer',
    'public_remarks': 'Public Remarks',
    'directions': 'Directions',
    'tax_legal_description': 'Tax Legal Description',
    'property_condition': 'Property Condition',
    'audio_tour': 'Audio Tour URL',
    'matterport_url': 'Matterport URL',
    'bidding_url': 'Bidding URL',
    'bidding_provider_url': 'Bidding Provider URL',
    'youtube_url': 'Youtube URL',
    'brochure_url': 'Brochure URL',
    'listing_agent_name': 'Listing Agent Name',
    'listing_agent_phone': 'Listing Agent Phone',
    'listing_agent_email': 'Listing Agent Email',
    'listing_agent_url': 'Listing Agent URL',
    'listing_agent_mls_id': 'Listing Agent MLS ID',
    'listing_office': 'Listing Office',
    'listing_office_key': 'Listing Office Key',
    'broker_name': 'Broker Name',
    'franchise_affiliation': 'Franchise Affiliation',
    'listing_office_phone': 'Listing Office Hours',
    'real_estate_license_number': 'Real Estate License Number',
    'county_or_parish': 'County or Parish',
    'parcel_number': 'Parcel Number',
    'subdivision_name': 'Subdivision Name',
    'elementary_school': 'Elementary School',
    'elementary_school_district': 'Elementary School District',
    'middle_or_junior_school': 'Middle or Junior School',
    'middle_or_junior_school_district': 'Middle or Junior School District',
    'high_school': 'High School',
    'high_school_district': 'High School District',
    'bedrooms': 'Bedrooms',
    'bathrooms_full': 'Full Bathrooms',
    'bathrooms_three_quarters': 'Three-Quarter Baths',
    'bathrooms_half': 'Half Baths',
    'bathrooms_one_quarter': 'One-Quarter Baths',
    'units': 'Units',
    'year_built': 'Year Built',
    'basement': 'Basement?',
    'living_area': 'Living Area',
    'lot_size': 'Lot Size',
    'stories_total': 'Total Stories',
    'parking_total': 'Total Parking',
    'elevation': 'Elevation',
    'fireplaces': 'Fireplaces',
    'waterfront': 'Waterfront?',
    'entry_level': 'Level of Entry',
    'office_address': 'Listing Office Address',
    'start_date': 'Start Date',
    'end_date': 'End Date',
    'available_date': 'Available Date',
    'listing_contract_date': 'Contract Date',
};

export const multilineFields: Set<string> = new Set([
    'description',
    'terms',
    'disclaimer',
    'public_remarks',
    'directions',
    'tax_legal_description',
    'property_condition',
]);