import React, { ElementType, FC, useCallback, useEffect } from "react";
import {
    FieldValues,
    RegisterOptions,
    useForm,
} from "react-hook-form";
import {
    Button,
    H1,
    Loading,
    PagePadding,
} from "src/ui/primitives";
import { StepNavigation } from "./StepNavigation";
import { useFetch } from "src/api";
import {
    ListingDetailSection,
    ListingDetailField,
} from "src/types";
import {
    FieldError,
    FieldErrorScrollOnMount,
    Fieldset,
    Form,
    pickDirtyFieldValues,
} from "src/ui/primitives/form";
import {
    fieldNameToLabel,
    sectionNameToLabel,
} from "./mappings";
import { useParams } from "react-router-dom";
import { ListingDetailTextField } from "./ListingDetailTextField";
import { ListingDetailNumberField } from "./ListingDetailNumberField";
import { ListingDetailDateField } from "./ListingDetailDateField";
import styled from "styled-components";
import { AddressInputs } from "src/ui/address";

const SectionWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 1rem;
`;

const fieldTypeToComponentMap: Partial<
    Record<
        ListingDetailField["type"],
        ElementType
    >
> = {
    'text': ListingDetailTextField,
    'email': ListingDetailTextField,
    'url': ListingDetailTextField,
    'number': ListingDetailNumberField,
    'date': ListingDetailDateField,
};


export const ListingDetailsForm: FC = () => {
    const { listingId } = useParams();
    const {
        loading: loadingSections,
        error: errorSections,
        data: sections,
        status: sectionStatus,
    } = useFetch<ListingDetailSection[]>('/api/listings/fields/sections');
    const {
        loading: loadingFields,
        error: errorFields,
        data: fields,
        status: fieldStatus,
        replaceData: replaceFieldsData,
    } = useFetch<Record<string, string | number>>(`/api/listings/${listingId}/fields/`);
    const {
        fetcher: saveForm,
        loading: saving,
        error: saveError,
        status: saveStatus,
    } = useFetch();
    const form = useForm();
    const {
        reset,
        formState: { isDirty },
    } = form;

    const submit = useCallback(async (values: FieldValues) => {
        const dirtyValues = pickDirtyFieldValues(values, fields || {});
        const { response, json } = await saveForm(
            `/api/listings/${listingId}/fields`,
            {
                method: 'PUT',
                body: JSON.stringify(dirtyValues),
            }
        );
        if (response && response.status > 199 && response.status < 300) {
            replaceFieldsData(json);
        }
    }, [listingId, fields, replaceFieldsData, saveForm]);

    useEffect(() => {
        if (fields) {
            reset(fields);
        }
    }, [reset, fields]);

    if (loadingSections || loadingFields)
        return <>
            <StepNavigation />
            <Loading />
        </>;
        
    if (errorSections || sectionStatus !== 200 || !sections || errorFields || fieldStatus !== 200)
        return <>
            <StepNavigation />
            <PagePadding>
                <FieldError>
                    An error occurred, unable to load listing sections.
                </FieldError>
            </PagePadding>
        </>;

    return <>
        <StepNavigation />
        <Form
            onSubmit={submit}
            footer={
                <Button
                    type="submit"
                    disabled={!isDirty || saving}
                >
                    {isDirty ? 'Save Changes' : 'Changes saved'}
                </Button>
            }
            {...form}
        >
            <H1>Listing Details</H1>
            {(saveError || (saveStatus && saveStatus !== 200)) && (
                <FieldErrorScrollOnMount>
                    Something went wrong, unable to save.
                </FieldErrorScrollOnMount>
            )}
            <SectionWrap>
                {sections.map(section => {
                    const fields = section.fields.map(field => {
                        const fieldLabel = fieldNameToLabel[field.name] || field.name;

                        if (field.type === 'address') {
                            return (
                                <AddressInputs
                                    key={field.name}
                                    disabled={saving}
                                    fieldPrefix={field.name + '$'}
                                    legend={fieldLabel}
                                />
                            );
                        }

                        const registerOptions: RegisterOptions = {
                            disabled: saving,
                        };

                        if (field.required) {
                            registerOptions.required = `${fieldLabel} is required`;
                        }

                        const InputComponent: ElementType | undefined = fieldTypeToComponentMap[field.type];

                        if (!InputComponent)
                            return (
                                <div>Unknown data type.</div>
                            );

                        return (
                            <InputComponent
                                key={field.name}
                                field={field}
                                registerOptions={registerOptions}
                                fieldLabel={fieldLabel}
                            />
                        );
                    });

                    return section.section_name ? (
                        <Fieldset
                            legend={sectionNameToLabel[section.section_name]}
                            flexRow={true}
                            key={`section-${section.section_name}`}
                        >
                            {fields}
                        </Fieldset>
                    ) : fields;
                })}
            </SectionWrap>
        </Form>
    </>;
}