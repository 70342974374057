import React, { FC } from "react";
import styled, { keyframes } from "styled-components";
import { cssVar } from "../styles";

const dotAnim = keyframes`
    66% {
        opacity: 0.5;
        transform: rotate(360deg);
    }
    100% {
        transform: rotate(360deg);
    }
`;

const durationMs = 2000;
const Dot = styled.div`
    width: 1rem;
    height: 1rem;
    background: var(${cssVar.darkaccent});
    border-radius: .25rem;

    animation: ${dotAnim} ${durationMs}ms infinite cubic-bezier(0.715, 0.010, 0.410, 1.610);

    &:nth-child(1) {
        left: -1.5rem;
    }
    &:nth-child(2) {
        animation-delay: ${durationMs * 0.3}ms;
        left: 0;
    }
    &:nth-child(3) {
        animation-delay: ${durationMs * 0.6}ms;
        left: 1.5rem;
    }
`;
const DotWrap = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 3.75rem;
    height: 1rem;
    margin-bottom: .5rem;
`;
const Wrap = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #FFFFFF66;
    padding: .3rem;
    border-radius: .3rem;
`;
const Flex = styled.div`
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
`;

export const Loading: FC = () => (
    <Flex>
        <Wrap>
            <DotWrap>
                <Dot />
                <Dot />
                <Dot />
            </DotWrap>
            Please wait
        </Wrap>
    </Flex>
);
