import styled from "styled-components";
import { cssVar } from "src/ui/styles/global";
import { Link } from "react-router-dom";

const buttonStyles = `
    font-family: var(${cssVar.maintext});
    font-size: 1.1rem;
    font-weight: 500;
    padding: .5rem .75rem;
    border-radius: .5rem;
    box-shadow: 3px 3px 5px 0 var(${cssVar.lightaccent});
    background-color: var(${cssVar.accent});
    color: var(${cssVar.lightshade});
    border-color: var(${cssVar.darkaccent});

    &:hover {
        box-shadow: 5px 5px 10px 0 var(${cssVar.lightaccent});;
        background-color: var(${cssVar.darkaccent});
        border-color: var(${cssVar.accent});
    }

    & ~ & {
        margin-left: .75rem;
    }
`
const disabledStyles = `
    box-shadow: none;
    background-color: var(${cssVar.lightshade});
    color: var(${cssVar.lightaccent});
    border-color: var(${cssVar.lightaccent});
    border: .05rem solid;
`

export const Button = styled.button`
    ${buttonStyles}

    &:disabled {
        ${disabledStyles}
    }
`;
export const LinkButton = styled(Link)<{
    $disabled?: boolean,
}>`
    ${buttonStyles}
    white-space: nowrap;
    display: inline-block;

    ${props => props.$disabled ? `
        ${disabledStyles}
        pointer-events: none;
    ` : ''}
`;
