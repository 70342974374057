import styled from "styled-components";
import { cssVar } from "src/ui/styles";

export const ModalBackdrop = styled.div<{
    $open: boolean,
    $zIndex: number,
}>`
    background: var(${cssVar.transparentthick});
    backdrop-filter: blur(5px);
    position: fixed;
    top: 0;
    left: 0;
    ${props => props.$open ? `
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        max-height: 100%;
        opacity: 1;
    ` : `
        width: 0;
        height: 0;
        opacity: 0;
    `}
    transition: opacity .3s ease;
    z-index: ${props => props.$zIndex};
`;
