import { useCallback } from "react";
import { useCache } from "src/api";
import { InputOption } from "src/ui/primitives/form";

export const useFetchCountryOptions = () => {
    const { fetchers } = useCache();

    return useCallback(async () => {
        const countries = await fetchers.countries();

        return Object.entries(countries).map(
            ([value, label]) => ({ value, label } as InputOption)
        );
    }, [fetchers]);
};

export const useFetchRegionsOptions = (countryCode: string) => {
    const { fetchers } = useCache();

    return useCallback(async () => {
        if (!countryCode) {
            return [];
        }
        const regions = await fetchers.regions(
            new URLSearchParams({ countryCode })
        );
        const entries = Object.entries(regions);

        return entries.length ? [
            { label: 'Please select', value: '' },
            ...entries.map(
                ([value, label]) => ({ value, label } as InputOption)
            ),
        ] : [];
    }, [fetchers, countryCode]);
};
