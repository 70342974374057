import React, {
    FC,
    createContext,
    ReactNode,
    useContext,
    useEffect,
    useState,
    useCallback,
} from "react";
import { fetchApi, useApiTokenContext } from "src/api";
import { User } from "src/types";
import { Logout } from "../pages";
import { Loading } from "../primitives";

const userContext = createContext<User | undefined>(undefined);
const userClearContext = createContext<() => void>(() => {});

export const UserProvider: FC<{ children: ReactNode }> = ({ children }) => {
    const [user, setUser] = useState<User | undefined>();
    const { token } = useApiTokenContext();
    const [authFailed, setAuthFailed] = useState<boolean>(false);

    const clearUser = useCallback(() => setUser(undefined), []);

    useEffect(() => {
        if (!token) {
            return;
        }
        (async () => {
            const response = await fetchApi('/api/user');
            if (response.status === 401) {
                setAuthFailed(true);
                return;
            }
            if (response.status !== 200) {
                throw new Error('Unable to fetch user');
            }
            const json = await response.json();

            setUser(json.user);
        })();
    }, [token]);

    if (authFailed) {
        return <Logout />;
    }

    if (token && !user) {
        return <Loading />;
    }

    return (
        <userClearContext.Provider value={clearUser}>
            <userContext.Provider value={user}>
                {children}
            </userContext.Provider>
        </userClearContext.Provider>
    );
};

export const useUser = () => useContext(userContext);
export const useClearUser = () => useContext(userClearContext);
