import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { PageData, PaginatedReponse } from "src/types";
import { fetchApi } from "./fetch";
import { useAsyncRequestCancel } from "./useAsyncRequestCancel";

export const useFetchPaginate = <T, >(
    urlBase: string,
    urlParams?: URLSearchParams,
): {
    loading: boolean;
    error: boolean;
    items: T[] | undefined;
    pageData: PageData | undefined;
 } => {
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<boolean>(false);
    const [data, setData] = useState<PaginatedReponse<T>>();
    const startAsync = useAsyncRequestCancel();
    const [pageParams] = useSearchParams();

    const pageStr = pageParams.get('page');
    let page = pageStr
        ? parseInt(pageStr, 10)
        : 1;
    if (isNaN(page)) {
        page = 1;
    }

    useEffect(() => {
        setLoading(true);
        const isRequestCancelled = startAsync();
        (async () => {
            try {
                const fetchParams = new URLSearchParams(urlParams);
                fetchParams.set('page', `${page}`);

                const response = await fetchApi(
                    `${urlBase}?${fetchParams}`
                );
                const json = await response.json();
                if (isRequestCancelled())
                    return;
                if (response.status !== 200) {
                    throw new Error('FAILED');
                }
                setData(json);
            } catch {
                if (isRequestCancelled())
                    return;
                setError(true);
                setData(undefined);
            } finally {
                if (isRequestCancelled())
                    return;
                setLoading(false);
            }
        })();
    }, [urlBase, page, urlParams, startAsync]);

    return {
        loading,
        error,
        items: data ? data.items : undefined,
        pageData: data ? data.pageData : undefined,
    };
};
