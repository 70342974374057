import React, { FC, useCallback } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { FieldValues, useForm } from "react-hook-form";
import { useFetch } from "src/api";
import {
    Button,
    Loading,
} from "src/ui/primitives";
import {
    emailRegexp,
    FieldError,
    FieldErrorScrollOnMount,
    Form,
    FormInput,
    InputOption,
    useWhenClean,
} from "src/ui/primitives/form";
import { Role, User } from "src/types";
import { roleToDisplayText } from "src/ui/roles";
import { pickDirtyFieldValues } from "src/ui/primitives/form/dirtyFieldValues";
import { AppRoute } from "src/ui/routes";

export const EditUserForm: FC = () => {
    const { userId } = useParams();
    const {
        loading,
        error,
        data,
        status,
    } = useFetch<User>('/api/users/' + userId);

    if (loading)
        return <Loading />;

    if (error || status !== 200 || !data)
        return (
            <FieldError>
                An error occurred, unable to load user.
            </FieldError>
        );

    return <UserForm user={data} />
};

const statusOptions: InputOption[] = [
    {
        label: 'Active',
        value: '1',
    },
    {
        label: 'Inactive',
        value: '0',
    },
];
const roleOptions: InputOption[] = Object.values(Role).map(
    role => ({
        value: role,
        label: roleToDisplayText[role],
    })
);

export const UserForm: FC<{
    user?: Partial<User>
}> = ({
    user,
}) => {
    const [pageParams] = useSearchParams();
    const backUrl = pageParams.get('back') || AppRoute.allUsers;
    const formMethods = useForm({
        defaultValues: user ? { ...user, password: '' } : undefined,
    });
    const { register, reset } = formMethods;
    const {
        fetcher,
        loading,
        error,
        status,
    } = useFetch();
    const navigate = useNavigate();
    const whenClean = useWhenClean(
        formMethods.formState.isDirty
    );
    const submit = useCallback(async (values: FieldValues) => {
        const dirtyValues = user
            ? pickDirtyFieldValues(values, user)
            : values;
        
        const { response, json } = await fetcher(
            '/api/users/' + (user ? user.id : ''),
            {
                method: user ? 'PUT' : 'POST',
                body: JSON.stringify(dirtyValues)
            }
        );

        if (response && response.status > 199 && response.status < 300) {
            reset(json);
            whenClean(() => navigate(backUrl));
        }
    }, [user, navigate, fetcher, backUrl, whenClean, reset]);

    return <>
        <Form
            onSubmit={submit}
            footer={
                <Button
                    type="submit"
                    disabled={loading}
                >
                    Save Changes
                </Button>
            }
            {...formMethods}
        >
            {(error || (status && (status < 200 || status > 299))) && (
                <FieldErrorScrollOnMount>
                    Something went wrong, unable to save user.
                </FieldErrorScrollOnMount>
            )}
            <FormInput
                type="text"
                placeholder="Name"
                label="Name"
                {...register("name", {
                    required: "Name is required",
                    maxLength: {
                        value: 65000,
                        message: "Name is too long",
                    },
                    disabled: loading,
                })}
            />
            <FormInput
                type="text"
                placeholder="Email"
                label="Email"
                {...register("email", {
                    required: "Email is required",
                    pattern: {
                        value: emailRegexp,
                        message: "Must be a valid email address",
                    },
                    disabled: loading,
                })}
            />
            <FormInput
                label="Status"
                options={statusOptions}
                {...register("status", {
                    required: "Status is required",
                    valueAsNumber: true,
                    disabled: loading,
                })}
            />
            <FormInput
                label="Role"
                options={roleOptions}
                {...register("role", {
                    required: "Role is required",
                    disabled: loading,
                })}
            />
            {!user && (
                <FormInput
                    label="Password"
                    type="password"
                    {...register("password", {
                        required: "Password is required",
                        disabled: loading,
                    })}
                />
            )}
        </Form>
    </>;
};
