import React, { FC } from "react";
import { H1, PagePadding, Paragraph } from "src/ui/primitives";

export const MyOffers: FC = () => (
    <PagePadding>
        <H1>My Offers</H1>
        <Paragraph>
            Buyers can view any offers they've made and their current status. Review & re-offer on rejected offers. Offers on each property will include the offer, and the questionnaire information.
        </Paragraph>
    </PagePadding>
);
