import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { installEventContext } from './ui/install';
// import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// // If you want to start measuring performance in your app, pass a function
// // to log results (for example: reportWebVitals(console.log))
// // or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();

window.addEventListener('beforeinstallprompt', (event) => {
    event.preventDefault();
    installEventContext.event = event;
});

