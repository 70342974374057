import { useMemo } from "react";
import { Permission, Role, RolePermissions } from "../types";
import { useUser } from "./user";


export const useRole = () => {
    const user = useUser();

    // TODO: validate that role is something we expect?
    return user && user.role;
};

export const roleHasPermission = (
    role: Role,
    permission: Permission,
) => RolePermissions[role].includes(permission);

export type ActivePermissionMap = Record<Permission, boolean>;
export const useActivePermissions = (): ActivePermissionMap => {
    const role = useRole();

    return useMemo<ActivePermissionMap>(() => {
        const permissions = Object.values(Permission);

        return permissions.reduce((map, permission) => {
            map[permission as Permission] = role
                ? roleHasPermission(
                    role,
                    permission as Permission
                )
                : false;
            return map;
        }, {} as ActivePermissionMap)
    }, [role]);
};

export const roleToDisplayText: Record<Role, string> = {
    [Role.admin]: 'Admin',
    [Role.buyer]: 'Investor',
    [Role.buyerEnroller]: 'Investor Enroller',
    [Role.seller]: 'Agent',
    [Role.sellerEnroller]: 'Agent Enroller',
    [Role.sellerOrgAdmin]: 'Broker',
};
