import { useCallback, useEffect, useState } from "react";
import { fetchApi } from "./fetch";
import { useAsyncRequestCancel } from "./useAsyncRequestCancel";
import { PaginatedReponse } from "src/types";

export const useFetch = <T, >(
    url?: string,
) => {
    const [data, setData] = useState<T>();
    const [status, setStatus] = useState<number>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(!!url);
    const startAsync = useAsyncRequestCancel();

    const fetcher = useCallback(async (
        url: string,
        options?: RequestInit,
    ) => {
        setLoading(true);
        setError(false);
        const isRequestCancelled = startAsync();
        let response;
        let json;
        try {
            response = await fetchApi(url, options);
            json = await response.json()
            if (!isRequestCancelled()) {
                setStatus(response.status);
                setData(json);
            }
        } catch {
            if (!isRequestCancelled()) {
                setData(undefined);
                setError(true);
            }
        } finally {
            if (!isRequestCancelled()) {
                setLoading(false);
            }
        }
        return { response, json };
    }, [startAsync]);

    const reload = useCallback(async () => {
        if (url)
            return await fetcher(url);
        else
            console.warn('Cannot reload fetcher when no URL is present');
    }, [url, fetcher]);

    useEffect(() => {
        if (url)
            fetcher(url);
    }, [url, fetcher]);

    return {
        fetcher,
        reload,
        replaceData: setData,
        data,
        status,
        error,
        loading,
    };
};

export const useFetchAllPages = <T, >(
    url: string,
) => {
    const {
        fetcher,
        ...rest
    } = useFetch<PaginatedReponse<T>>();
    const [allData, setAllData] = useState<T[]>();
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        (async () => {
            setLoading(true);

            let currentPage = 0;
            let totalPages = 1;
            let collectedData: T[] = [];
            while (currentPage < totalPages) {
                const { response, json } = await fetcher(url + `?page=${currentPage + 1}`);

                if (response?.status !== 200) {
                    setLoading(false);
                    throw new Error(`Unable to fetch page "${url}?page=${currentPage + 1}"`);
                }

                collectedData = collectedData.concat(json.items);
                currentPage = json.pageData.currentPage;
                totalPages = json.pageData.totalPages;
            }
            setAllData(collectedData);
            setLoading(false);
        })();
    }, [url, fetcher]);

    return {
        ...rest,
        loading,
        data: allData,
    };
};
