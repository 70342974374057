import React, { ComponentType } from "react";
import { cssVar, mediaQuery } from "src/ui/styles";
import styled, { keyframes } from "styled-components";
import { PageData } from "src/types";
import { TableColumn } from "./types";
import { Pagination } from "../pagination";
import { Loading } from "../Loading";
import { Link } from "react-router-dom";

const TableWrap = styled.div`
    display: flex;
    flex-direction: column;
    min-width: 100%;
    box-sizing: border-box;
    margin-bottom: 2rem;
    position: relative;

    ${mediaQuery.verticalmedium(`
        display: table;
        border-collapse: collapse;
    `)}
`;
export const TableRow = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    border: 1px solid black;
    margin: 2px;
    border-radius: 5px;
    position: relative;

    &:nth-child(even) {
        background-color: var(${cssVar.transparentshade});
    }

    ${mediaQuery.verticalmedium(`
        display: table-row;
        border: none;
        border-bottom: 1px solid black;
        margin: 0;
        border-radius: 0;
    `)}
`;
const TableHeadRow = styled(TableRow)`
    background-color: var(${cssVar.darkaccent});
    color: var(${cssVar.lightshade});
    display: none;
    font-weight: 600;
    ${mediaQuery.verticalmedium(`
        display: table-row;
    `)}
`;
const TableCell = styled.div`
    display: inline-block;
    padding: 1rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;

    &:first-of-type {
        padding-left: .5rem;
    }

    ${mediaQuery.verticalmedium(`
        display: table-cell;
        padding: 1rem 0;
    `)}
`;
const Label = styled.label`
    display: inline;
    color: var(${cssVar.darkshade});
    font-weight: 600;
    
    ::after {
        content: ':';
        margin-right: .3rem;
    }
    ${mediaQuery.verticalmedium(`
        display: none;
    `)}
`;
export const EditRowLink = styled(Link)`
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    bottom: 0;
    right: 0;
    border: none;
    background: var(${cssVar.darkaccent});
    color: var(${cssVar.lightshade});
    border-radius: 5px;
    font-weight: 800;
    font-size: 1.3rem;
    cursor: pointer;

    ::after {
        content: '⋮';
    }
    ${mediaQuery.verticalmedium(`
        position: static;
        width: 30px;
        height: 30px;
        font-size: 1rem;
    `)}
`;

const loadingAnim = keyframes`
    from {
        background: #00000000;
    }
    to {
        background: #00000099;
    }
`;
const LoadingHost = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #FFFFFFAA;
    animation: ${loadingAnim} 1s;
`;

export type TableProps<T, > = {
    loading?: boolean,
    columns: TableColumn<T>[],
    items: T[] | undefined,
    onRowClick?: (data: T) => void,
    pageData?: PageData,
    pickRowComponent?: (data: T, rowIndex: number) => ComponentType | undefined,
};

export const Table = <T extends { id: number }, >({
    loading,
    columns,
    items,
    onRowClick,
    pageData,
    pickRowComponent,
}: TableProps<T>) => <>
    <TableWrap>
        <TableHeadRow>
            {columns.map((column, columnIndex) =>
                <TableCell key={String(column.dataKey) + columnIndex}>
                    {column.header}
                </TableCell>
            )}
        </TableHeadRow>
        {loading && (
            <LoadingHost>
                <Loading />
            </LoadingHost>
        )}
        {(items || []).map((dataRow: T, rowIndex: number) => {
            const RowComponent = pickRowComponent
                ? pickRowComponent(dataRow, rowIndex) || TableRow
                : TableRow;

            return (
                <RowComponent
                    onClick={() => onRowClick && onRowClick(dataRow)}
                    key={dataRow.id}
                >
                    {columns.map((column, columnIndex) =>
                        <TableCell
                            key={`${dataRow.id}-${String(column.dataKey)}-${columnIndex}`}
                        >
                            {column.header && <Label>{column.header}</Label>}
                            {(column.renderCell && column.renderCell(dataRow)) || `${dataRow[column.dataKey]}`}
                        </TableCell>
                    )}
                </RowComponent>
            );
        })}
    </TableWrap>
    {pageData && <Pagination pageData={pageData} />}
</>;
