// import { getCookie } from "./util";

import {
    useCallback,
    useEffect,
} from "react";
import { useNavigate } from "react-router-dom";
import { AppRoute } from "src/ui/routes";
import { fetchApi } from "./fetch";
import { setStoredApiToken } from "./storage";
import { useApiTokenContext } from "./TokenContext";

export const useAuthenticateApi = () => {
    const { refresh } = useApiTokenContext();

    return useCallback(async (
        email: string,
        password: string
    ) => {
        const response = await fetchApi('/api/login', {
            method: "POST",
            body: JSON.stringify({ email, password }),
        });
        if (response.status !== 200) {
            throw new Error('Failed to authenticate');
        }
        const json = await response.json();
    
        setStoredApiToken(json.token);
        refresh();
    }, [refresh]);
};

export const useRedirectWithoutToken = () => {
    const { token } = useApiTokenContext();
    const navigate = useNavigate();

    useEffect(() => {
        if (!token) {
            navigate(AppRoute.login, { replace: true });
        }
    }, [token, navigate]);

    return !!token;
};
