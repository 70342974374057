import React, { FC } from "react";
import { H1, PagePadding, Paragraph } from "src/ui/primitives";

export const ManageOffers: FC = () => (
    <PagePadding>
        <H1>Manage Offers</H1>
        <Paragraph>
            Sellers can manage their offers in progress.
        </Paragraph>
        <Paragraph>
            Review Counter Offers. See Hold Offers. Start Closing Process on Accepted Offers.  Offers on each property will include the offer, and the questionnaire information.
        </Paragraph>
    </PagePadding>
);
