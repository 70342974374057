import React, { FC, useMemo } from "react";
import { generatePath, Link, useParams } from "react-router-dom";
import styled from "styled-components";
import { AppRoute } from "src/ui/routes";
import {
    H1,
    H3,
    IconGrid,
    IconGridItem,
    Lightbox,
    Loading,
    Matterport,
    PagePadding,
    Paragraph,
    YoutubeVideo,
} from "src/ui/primitives";
import { useFetch } from "src/api";
import { Listing, ListingOptionChoice, ListingStatus } from "src/types";
import { FieldError, Fieldset } from "src/ui/primitives/form";
import { ConstrainedImg } from "src/ui/primitives/ConstrainedImg";
import { cssVar, mediaQuery } from "src/ui/styles";
import { useUser } from "src/ui/user";
import { StepNavigation } from "./form/StepNavigation";
import { OfferNowContainer } from "src/ui/offers";
import { useListingPhotoUrls } from "./useListingPhotoUrls";
import { useActivePermissions } from "src/ui/roles";
import { Icon } from "src/ui/primitives/Icon";
import { choiceTypeToLabel } from "./form";

const gap = '.5rem';
const Images = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 1rem 0;
    gap: ${gap};
`;
const ImageLink = styled(Link)`
    width: calc(50% - ${gap});
    ${mediaQuery.verticalmedium(`
        width: calc(25% - ${gap});
    `)}
    ${mediaQuery.medium(`
        width: calc(20% - ${gap});
    `)}
    ${mediaQuery.large(`
        width: calc(25% - ${gap});
    `)}
    ${mediaQuery.xlarge(`
        width: calc(20% - ${gap});
    `)}
    ${mediaQuery.xxlarge(`
        width: calc(16.66% - ${gap});
    `)}
`;
const PendingFlag = styled.div`
    background-color: var(${cssVar.transparentthick});
    color: var(${cssVar.lightshade});
    font-size: .8rem;
    padding: .2rem .8rem;
    border-radius: 1rem;
    display: inline-block;
    margin-right: 1rem;
`;
const ChoicesGroup = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    background: rgba(0, 0, 0, 0.06);
    padding: .2rem .5rem;
    margin-bottom: .2rem;
    border-radius: .3rem;
    > div:first-of-type {
        margin-right: .5rem;
    }
`;
const Choice = styled.div`
    background-color: var(${cssVar.transparentthick});
    color: var(${cssVar.lightshade});
    padding: .2rem .8rem;
    border-radius: 1rem;
    display: inline-block;
    margin: .2rem;
`;
const FieldsetWithMargin = styled(Fieldset)`
    margin-bottom: .5rem;
`;
const Collapsible: FC<{
    value: string | number | null | undefined,
    title: string,
}> = ({ value, title }) => !value ? null : (
    <FieldsetWithMargin
        legend={title}
        collapsible
        startCollapsed
    >
        <Paragraph $compact>
            {value}
        </Paragraph>
    </FieldsetWithMargin>
);
const StyledLink = styled.a`
    display: block;
    background: #00000009;
    border-radius: .5rem;
    padding: .3rem .5rem;
    display: flex;
    gap: .5rem;
    color: var(${cssVar.headingtext});
    > span {
        opacity: .6;
    }
    :hover {
        background: #00000011;
        color: black;
    }
`;
const ExternalLink: FC<{
    href?: string | number | null,
    text: string,
}> = ({ href, text }) => !href || typeof href !== 'string' ? null : (
    <StyledLink href={href} target="_blank" rel="noreferrer">
        <Icon name="externalLink" color="#BBB" />
        {text} <span>{href}</span>
    </StyledLink>
);

// taken from https://developers.google.com/maps/documentation/urls/guide#forming-the-url
export const getGoogleMapsUrl = (address: string) => address && `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(address)}`;

export const ListingById: FC<{
    route?: string,
}> = ({
    route = AppRoute.listingById,
}) => {
    const { listingId } = useParams();
    const {
        loading: loadingListing,
        error: errorListing,
        data: listing,
        status: statusListing,
    } = useFetch<Listing>('/api/listings/' + listingId);
    const {
        loading: loadingFields,
        error: errorFields,
        data: fields,
        status: statusFields,
    } = useFetch<Record<string, string | number>>(`/api/listings/${listingId}/fields/`);
    const {
        loading: loadingChoices,
        error: errorChoices,
        data: choices,
        // status: statusChoices, // if choices don't load, show everything else
    } = useFetch<ListingOptionChoice[]>(`/api/listings/${listingId}/choices/`);
    const {
        loading: loadingPhotos,
        error: errorPhotos,
        status: statusPhotos,
        photos,
    } = useListingPhotoUrls(`${listingId}`);
    const user = useUser();
    const activePermissions = useActivePermissions();

    const loading = loadingListing || loadingFields || loadingPhotos || loadingChoices;
    const error = errorListing || errorFields || errorPhotos || errorChoices;

    const displayedPhotos = useMemo(
        () => photos.slice(1, 21),
        [photos]
    );
    const photosNotDisplayed = useMemo(
        () => photos.length - displayedPhotos.length - 1,
        [photos, displayedPhotos]
    );
    const choicesByCategory = useMemo(
        () => (choices || []).reduce(
            (byCategory, choice) => {
                if (!byCategory[choice.type])
                    byCategory[choice.type] = [];
                byCategory[choice.type].push(choice.name);
                return byCategory;
            },
            {} as Record<string, string[]>
        ),
        [choices]
    );

    if (loading)
        return <Loading />;

    if (error || statusListing !== 200 || !listing || statusFields !== 200 || statusPhotos !== 200)
        return <FieldError>Unable to load listing</FieldError>;

    const isOwner = listing.user_id === user?.id;
    const canEdit = activePermissions.editAnyListing;
    const address = [
        listing.address1,
        listing.city,
        [listing.region, listing.postal].join(' '),
    ].join(', ');

    return <>
        {(isOwner || canEdit) && (
            <StepNavigation />
        )}
        <Lightbox
            images={photos}
            path="photos"
            baseUrl={generatePath(route, {
                listingId: `${listing.id}`
            })}
        />
        <Link to="photos/0">
            <ConstrainedImg
                src={photos[0]}
                height="50vh"
                alt={`Cover Photo for listing "${listing.title}"`}
            />
        </Link>
        {!isOwner && (
            <OfferNowContainer />
        )}
        <PagePadding>
            {address && (
                <H1>
                    {listing.status === ListingStatus.pending && (
                        <PendingFlag>Pending</PendingFlag>
                    )}
                    <a href={getGoogleMapsUrl(address)} target="_blank" rel="noreferrer">
                        {address}
                    </a>
                </H1>
            )}
            <Paragraph>
                Listing by: {listing.seller_name}
            </Paragraph>
            <IconGrid>
                <IconGridItem
                    icon="bed"
                    title="Bedrooms"
                    fields={fields}
                    name="bedrooms"
                />
                <IconGridItem
                    icon="bath"
                    title="Bathrooms"
                    fields={fields}
                    name="bathrooms_full"
                />
                <IconGridItem
                    icon="halfBath"
                    title="Half Bathrooms"
                    fields={fields}
                    name="bathrooms_half"
                />
                <IconGridItem
                    icon="ruler"
                    title="Living Area"
                    fields={fields}
                    name="living_area"
                />
                <IconGridItem
                    icon="tree"
                    title="Lot Size"
                    fields={fields}
                    name="lot_size"
                />
                <IconGridItem
                    icon="cells"
                    title="Units"
                    fields={fields}
                    name="units"
                />
                <IconGridItem
                    icon="calendar"
                    title="Year Built"
                    fields={fields}
                    name="year_built"
                />
                <IconGridItem
                    icon="stairs"
                    title="Stories"
                    fields={fields}
                    name="stories_total"
                />
                <IconGridItem
                    icon="car"
                    title="Parking"
                    fields={fields}
                    name="parking_total"
                />
                <IconGridItem
                    icon="mountain"
                    title="Elevation"
                    fields={fields}
                    name="elevation"
                />
            </IconGrid>
            <H3>{listing.title}</H3>
            {fields?.description && (
                <Paragraph>
                    {fields.description}
                </Paragraph>
            )}
            <FieldsetWithMargin legend="Links">
                <ExternalLink
                    href={window.location.href}
                    text="This listing"
                />
                <ExternalLink
                    href={fields?.audio_tour}
                    text="Audio Tour"
                />
                <ExternalLink
                    href={fields?.bidding_url}
                    text="Bidding Url"
                />
                <ExternalLink
                    href={fields?.bidding_provider_url}
                    text="Bidding Provider Url"
                />
                <ExternalLink
                    href={fields?.brochure_url}
                    text="Brochure Url"
                />
            </FieldsetWithMargin>
            <Matterport
                url={typeof fields?.matterport_url === 'string'
                    ? fields?.matterport_url
                    : undefined}
            />
            <YoutubeVideo
                url={typeof fields?.youtube_url === 'string'
                    ? fields.youtube_url
                    : undefined}
            />
            <Collapsible
                value={fields?.disclaimer}
                title="Disclaimer"
            />
            <Collapsible
                value={fields?.terms}
                title="Terms"
            />
            <Collapsible
                value={fields?.public_remarks}
                title="Public Remarks"
            />
            <Collapsible
                value={fields?.directions}
                title="Directions"
            />
            <Collapsible
                value={fields?.tax_legal_description}
                title="Tax Legal Description"
            />
            <Collapsible
                value={fields?.property_condition}
                title="Property Condition"
            />
            {choices && choices.length && (
                Object.entries(choicesByCategory).map(([choiceType, choices]) => (
                    <ChoicesGroup key={choiceType}>
                        <div>{choiceTypeToLabel[choiceType]}</div>
                        <div>{choices.map(choice => (
                            <Choice key={choice}>{choice}</Choice>
                        ))}</div>
                    </ChoicesGroup>
                ))
            )}
            <Images>
                {displayedPhotos.map((photo, i) =>
                    <ImageLink to={`photos/${i + 1}`} key={i}>
                        <ConstrainedImg
                            src={photo}
                            height="5rem"
                            suppressBlur={true}
                        />
                    </ImageLink>
                )}
            </Images>
            {photosNotDisplayed ? (
                <Paragraph>
                    {photosNotDisplayed} other photos not displayed. View them by clicking a photo then skipping forward.
                </Paragraph>
            ) : null}
        </PagePadding>
    </>;
};


/**
 * ALL POSSIBLE FIELDS (NOT COUNTING CHOICES)
 * 
 *
    // fields?.bedrooms
    // fields?.bathrooms_full
    fields?.bathrooms_three_quarters
    // fields?.bathrooms_half
    fields?.bathrooms_one_quarter
    // fields?.units
    // fields?.year_built
    fields?.basement
    fields?.start_date
    fields?.end_date
    // fields?.description
    // fields?.terms
    // fields?.disclaimer
    // fields?.public_remarks
    // fields?.directions
    // fields?.tax_legal_description
    // fields?.property_condition
    // fields?.parcel_number
    // fields?.audio_tour
    // fields?.matterport_url
    // fields?.bidding_url
    // fields?.bidding_provider_url
    // fields?.youtube_url
    // fields?.brochure_url
    fields?.listing_agent_name
    fields?.listing_agent_phone
    fields?.listing_agent_email
    fields?.listing_agent_url
    fields?.listing_agent_mls_id
    fields?.listing_office
    fields?.office_address
    fields?.listing_office_key
    fields?.broker_name
    fields?.franchise_affiliation
    fields?.listing_office_phone
    fields?.real_estate_license_number
    // fields?.living_area
    // fields?.lot_size
    fields?.county_or_parish
    fields?.subdivision_name
    fields?.elementary_school
    fields?.elementary_school_district
    fields?.middle_or_junior_school
    fields?.middle_or_junior_school_district
    fields?.high_school
    fields?.high_school_district
    // fields?.stories_total
    // fields?.parking_total
    fields?.fireplaces
    fields?.entry_level
    fields?.waterfront
    fields?.listing_contract_date
    fields?.available_date
    // fields?.elevation
 */