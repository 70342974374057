import React, { FC, PropsWithChildren, ReactNode, useCallback, useEffect, useRef, useState } from "react";
import { cssVar } from "src/ui/styles";
import styled from "styled-components";
import { Icon } from "../Icon";

const StyledFieldset = styled.fieldset`
    border-radius: .5rem;
    border: 1px solid #00000011;
    background: #00000009;
    padding: 1rem .5rem .5rem .5rem;
    min-width: 100%;
    box-sizing: border-box;

    > legend {
        background: #ddd;
        color: var(${cssVar.darkshade});
        padding: .35rem 1.2rem;
        border-radius: .3rem 1rem .3rem 1rem;
    }
`;
const Content = styled.div<{ $hidden: boolean, $flexRow: boolean }>`
    max-height: ${props => props.$hidden ? '3rem' : '300vh'};
    transition: max-height ${props => props.$hidden ? '.3s' : '1s'} ease;
    overflow: hidden;
    position: relative;
    flex: 1;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    min-width: 100%;
    ${props => props.$flexRow && `
        flex-direction: row;
        flex-wrap: wrap;
        width: 100%;
    `}
    gap: 1rem;
    > fieldset {
        margin: 0 -0.2rem;
    }
    ::after {
        content: ' ';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        z-index: 10;
        height: ${props => props.$hidden ? '2.5rem' : '0'};
        background: linear-gradient(0deg, #eee 10%, #eeeeee00 100%);
        opacity: ${props => props.$hidden ? '1' : '0'};
        transition: height .5s ease;
    }
`;
const CollapseIcon = styled(Icon)`
    margin-right: .5rem;
    margin-left: -.3rem;
`;

export const Fieldset: FC<PropsWithChildren<{
    legend: ReactNode;
    flexRow?: boolean;
    className?: string;
    collapsible?: boolean;
    startCollapsed?: boolean;
}>> = ({
    children,
    legend,
    flexRow,
    className,
    collapsible,
    startCollapsed,
}) => {
    const [collapsed, setCollapsed] = useState<boolean>(!!startCollapsed);
    const toggleCollapse = useCallback(() => setCollapsed(c => !c), []);
    const contentRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (contentRef.current?.scrollHeight && contentRef.current?.scrollHeight < 100) {
            setCollapsed(false);
        }
    }, []);

    return (
        <StyledFieldset className={className}>
            <legend onClick={collapsible ? toggleCollapse : undefined}>
                {collapsible ? (
                    <CollapseIcon
                        name={collapsed ? "caretRight" : "caretDown"}
                    />
                ) : null}
                {legend}
            </legend>
            <Content
                $hidden={collapsed}
                $flexRow={!!flexRow}
                ref={contentRef}
            >
                {children}
            </Content>
        </StyledFieldset>
    );
};
