import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { ListingDetailField } from "src/types";
import {
    FormInput,
    emailRegexp,
    uriRegexp,
} from "src/ui/primitives/form";
import { multilineFields } from "./mappings";
import styled from "styled-components";

const Input = styled(FormInput)`
    min-width: 100%;
`;

export const ListingDetailTextField: FC<{
    field: ListingDetailField,
    registerOptions: RegisterOptions,
    fieldLabel: string,
}> = ({
    field,
    registerOptions,
    fieldLabel,
}) => {
    const { register, setValue } = useFormContext();
    
    const placeholder = field.type === 'url' ? (
        'www.example.com or https://www.example.com'
    ) : field.type === 'email'? (
        'example@example.com'
    ) : '';

    if (field.type === 'url') {
        registerOptions.pattern = {
            value: uriRegexp,
            message: `${fieldLabel} must be a valid URL`
        };
        registerOptions.onBlur = event => {
            const { value } = event.target;
            if (value && !value.match(/^https?:\/\//)) {
                event.target.value = 'https://' + value;
                setValue(field.name, event.target.value);
            }
        };
    }

    if (field.type === 'email') {
        registerOptions.pattern = {
            value: emailRegexp,
            message: `${fieldLabel} must be a valid email`
        };
    }

    if (field.max_length) {
        registerOptions.maxLength = {
            value: field.max_length,
            message: `${fieldLabel} is too long`,
        };
    }

    return (
        <Input
            type="text"
            label={fieldLabel}
            placeholder={placeholder}
            multiline={multilineFields.has(field.name)}
            {...register(field.name, registerOptions)}
        />
    );
}