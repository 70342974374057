import React, { FC, useCallback } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { Button } from "src/ui/primitives";
import {
    Form,
    FormInput,
    InputOption,
    useLocalPersist,
} from "src/ui/primitives/form";

const OPTIONS_CASH_BUYER: InputOption[] = [
    {
        value: '',
        label: 'Are you a cash buyer?',
    },
    {
        value: '1',
        label: 'Yes, I will be buying with cash',
    },
    {
        value: '0',
        label: 'No, I will require financing'
    }
];
const OPTIONS_PRE_APPROVED: InputOption[] = [
    {
        value: '',
        label: 'Are you pre-approved?',
    },
    {
        value: '1',
        label: 'Yes, I am pre-approved'
    },
    {
        value: '0',
        label: 'No, I am not pre-approved'
    }
];

export const OfferForm: FC<{
    listingId: string,
    shouldPrompt?: boolean
}> = ({ listingId, shouldPrompt }) => {
    const {
        persistedValues,
        persist,
    } = useLocalPersist(`offer/${listingId}`);
    const formApi = useForm({
        defaultValues: persistedValues
    });
    const { register } = formApi;

    persist(formApi.watch());

    const submit = useCallback((values: FieldValues) => {
        console.log(values);
    }, []);

    return (
        <Form
            onSubmit={submit}
            footer={
                <Button
                    type="submit"
                >
                    Make Offer
                </Button>
            }
            shouldPrompt={shouldPrompt}
            {...formApi}
        >
            <FormInput
                type="number"
                placeholder="Offer Price"
                label="Offer Price"
                min="0"
                {...register("price", {
                    required: "Price is required",
                    valueAsNumber: true,
                })}
            />
            <FormInput
                options={OPTIONS_CASH_BUYER}
                {...register("cash_buyer", {
                    required: "A selection is required",
                    valueAsNumber: true,
                })}
            />
            <FormInput
                options={OPTIONS_PRE_APPROVED}
                {...register("pre_approved", {
                    required: "A selection is required",
                    valueAsNumber: true,
                })}
            />
        </Form>
    );
};
