import { AppRoute } from ".";
import { Permission } from "src/types";

export const routeToPermissionMap: Record<AppRoute, Permission[]> = {
    [AppRoute.homepage]: [],
    [AppRoute.publicListingById]: [],
    [AppRoute.login]: [
        Permission.dashboard,
    ],
    [AppRoute.logout]: [
        Permission.dashboard,
    ],
    [AppRoute.apiQuery]: [
        Permission.dashboard,
    ],
    [AppRoute.dashboard]: [
        Permission.dashboard,
    ],
    // [AppRoute.editProfile]: [
    //     Permission.dashboard,
    // ],
    [AppRoute.allUsers]: [
        Permission.createUserAnyRole,
    ],
    [AppRoute.editUser]: [
        Permission.createUserAnyRole,
    ],
    [AppRoute.createUser]: [
        Permission.createUserAnyRole,
    ],
    [AppRoute.listings]: [
        Permission.postListing,
        Permission.makeOffer,
    ],
    [AppRoute.listingById]: [
        Permission.postListing,
        Permission.makeOffer,
    ],
    [AppRoute.postListing]: [
        Permission.postListing,
    ],
    [AppRoute.editListing]: [
        Permission.postListing,
    ],
    [AppRoute.editListingPhotos]: [
        Permission.postListing,
    ],
    [AppRoute.editListingDetails]: [
        Permission.postListing,
    ],
    [AppRoute.editListingChoices]: [
        Permission.postListing,
    ],
    [AppRoute.manageOffers]: [
        Permission.postListing,
    ],
    [AppRoute.myOffers]: [
        Permission.makeOffer,
    ],
    // [AppRoute.buyerList]: [
    //     Permission.inviteUserBuyer,
    // ],
    // [AppRoute.inviteBuyer]: [
    //     Permission.inviteUserBuyer,
    // ],
    // [AppRoute.buyerProfile]: [
    //     Permission.inviteUserBuyer,
    //     Permission.postListing,
    // ],
    // [AppRoute.sellerList]: [
    //     Permission.inviteUserSeller,
    // ],
    // [AppRoute.inviteSeller]: [
    //     Permission.inviteUserSeller,
    // ],
    // [AppRoute.sellerProfile]: [
    //     Permission.inviteUserSeller,
    //     Permission.makeOffer,
    // ],
};
