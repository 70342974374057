import React, {
    ChangeEvent,
    FC,
    useCallback,
    useEffect,
    useMemo,
    useRef,
    useState,
} from "react";
import { createPortal } from "react-dom";
import { useSearchParams } from "react-router-dom";
import {
    // useFetch,
    useFetchPaginate,
} from "src/api";
import { Listing } from "src/types";
import { Button, Flex } from "src/ui/primitives";
import { FormInput } from "src/ui/primitives/form";
import { cssVar } from "src/ui/styles";
import styled from "styled-components";
// import { fieldNameToLabel } from "./form/mappings";

export type FilterState = {
    search: string;
    setSearch: (search: string) => void;
    getNumber: (name: string) => string;
    setNumber: (name: string, value?: number) => void;
    forEachNumber: (
        callback: (value: string, name: string) => void
    ) => void;
};

export const useFilterStateParams = (): FilterState => {
    const [searchParams, setSearchParams] = useSearchParams();

    const search = searchParams.get('search') || '';
    const setSearch = useCallback((search: string) => setSearchParams(sp => {
        const params = new URLSearchParams(sp);
        if (search) {
            params.set('search', search);
        } else {
            params.delete('search');
        }
        params.delete('page');

        return params;
    }), [setSearchParams]);
    const getNumber = useCallback(
        (name: string) => searchParams.get(name) || '',
        [searchParams]
    );
    const setNumber = useCallback(
        (name: string, value?: number) => setSearchParams(sp => {
            const params = new URLSearchParams(sp);
            if (value) {
                params.set(name, `${value}`);
            } else {
                params.delete(name);
            }
    
            return params;
        }), [setSearchParams]
    );
    const forEachNumber = useCallback(
        (callback: (value: string, name: string) => void) => searchParams.forEach(callback),
        [searchParams]
    );


    return useMemo(() => ({
        search,
        setSearch,
        getNumber,
        setNumber,
        forEachNumber,
    }), [search, setSearch, getNumber, setNumber, forEachNumber]);
};
const useFilterState = (
    defaultState: FilterState
): FilterState => {
    const [search, setSearch] = useState<string>(
        defaultState.search
    );

    const [numbers, setNumbers] = useState<Record<string, string>>({});
    const getNumber = useCallback(
        (name: string) => numbers[name] || '',
        [numbers]
    );
    const setNumber = useCallback(
        (name: string, value?: number) => setNumbers(n => {
            const newNumbers = {
                ...n,
                [name]: `${value}`,
            };
            if (typeof value !== 'number') {
                delete newNumbers[name];
            }
            return newNumbers;
        }),
        []
    );
    const forEachNumber = useCallback(
        (callback: (value: string, name: string) => void) => (
            Object.entries(numbers).forEach(([key, value]) => callback(value, key))
        ),
        [numbers]
    );

    return {
        search,
        setSearch,
        getNumber,
        setNumber,
        forEachNumber,
    };
};

const syncFilterStates = (
    fromState: FilterState,
    toState: FilterState,
) => {
    toState.setSearch(fromState.search);
    // toState.forEachNumber((value, name) =>
    //     toState.setNumber(name, undefined)
    // );
    // fromState.forEachNumber((value, name) =>
    //     toState.setNumber(name, parseInt(value, 10))
    // );
}

export const useFilterListingFetch = (filterState: FilterState) => {
    const params = useMemo(() => {
        const p = new URLSearchParams();

        if (filterState.search) {
            p.set('search', filterState.search);
        }
        return p;
    }, [filterState]);

    return useFetchPaginate<Listing>(
        '/api/listings',
        params
    );
};

const Panel = styled.div<{ $open: boolean }>`
    position: fixed;
    top: 0;
    right: ${props => props.$open ? '0' : '-300px'};
    transition: right .3s ease;
    width: 300px;
    max-width: 100%;
    height: 100%;
    background-color: var(${cssVar.transparentbarely});
    display: flex;
    flex-direction: column;
    backdrop-filter: blur(5px);
    label {
        color: var(${cssVar.lightshade});
    }
`;
const Scrollable = styled.div`
    flex: 1;
    overflow-x: hidden;
    overflow-y: auto;
    padding: .5rem;
    display: flex;
    flex-direction: column;
    gap: .5rem;
`;

// type Fields = Record<string, {
//     type: "number" | "date" | "url" | "email" | "text" | "address",
//     name: string,
//     required: boolean,
//     hidden: boolean,
//     max_length?: number,
//     min?: number,
//     max?: number,
//     unit_choices?: null | string[],
// }>;

export const FilterPanel: FC<{
    state: FilterState
}> = ({ state }) => {
    const searchRef = useRef<HTMLInputElement>(null);
    const [panelOpen, setPanelOpen] = useState<boolean>(false);

    const localState = useFilterState(state);
    const {
        search,
        setSearch,
        // setNumber,
        // getNumber,
    } = localState;

    // const { data } = useFetch<Fields>('/api/listings/fields');

    // const numbers = useMemo(() => data
    //     ? Object.values(data).filter(item => (
    //         item.type === 'number' && !(
    //             item.min === 0 && item.max === 1
    //         )
    //     ))
    //     : null,
    //     [data]
    // );

    const togglePanel = useCallback(() => setPanelOpen(o => !o), []);
    const applyState = useCallback(() => {
        syncFilterStates(localState, state);
        setPanelOpen(false);
    }, [localState, state]);
    const cancel = useCallback(() => {
        syncFilterStates(state, localState);
        setPanelOpen(false);
    }, [localState, state]);
    const handleSearchChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    }, [setSearch]);
    const handleSearchKeydown = useCallback((event: KeyboardEvent) => {
        if (event.key === 'Enter') {
            applyState();
        }
    }, [applyState]);
    // const handleNumberChange = (fieldName: string) => (event: ChangeEvent<HTMLInputElement>) => {
    //     const number = event.target.value
    //         ? parseInt(event.target.value, 10)
    //         : undefined;
    //     setNumber(fieldName, number);
    // };

    useEffect(() => {
        if (panelOpen) {
            searchRef.current?.focus();
            searchRef.current?.select();
        }
    }, [panelOpen]);

    // const stateRef = useRef(state);
    // useEffect(() => {
    //     if (stateRef.current !== state) {
    //         syncFilterStates(state, localState);
    //         stateRef.current = state;
    //     }
    // }, [state, localState]);

    return (
        <>
            <Flex $justify="flex-end" $align="center" $gap=".5rem">
                {state.search && (
                    <div>
                        Searching for "{state.search}"
                    </div>
                )}
                <Button type="button" onClick={togglePanel}>
                    Filter
                </Button>
            </Flex>
            {createPortal(<>
                <Panel $open={panelOpen}>
                    <Scrollable>
                        <FormInput
                            placeholder="Search"
                            label="Search"
                            value={search}
                            onChange={handleSearchChange}
                            onKeyDown={handleSearchKeydown}
                            ref={searchRef}
                        />
                        {/* {numbers && numbers.map(n => (
                            <div key={n.name}>
                                <label>
                                    {fieldNameToLabel[n.name]}
                                </label>
                                <Flex>
                                    <FormInput
                                        type="number"
                                        placeholder="Min"
                                        value={getNumber(n.name + '_min')}
                                        onChange={handleNumberChange(n.name + '_min')}
                                    />
                                    <FormInput
                                        type="number"
                                        placeholder="Max"
                                        value={getNumber(n.name + '_max')}
                                        onChange={handleNumberChange(n.name + '_max')}
                                    />
                                </Flex>
                            </div>
                        ))} */}
                    </Scrollable>
                    <Flex $children $pad=".5rem">
                        <Button type="button" onClick={applyState}>
                            Apply
                        </Button>
                        <Button type="button" onClick={cancel}>
                            Cancel
                        </Button>
                    </Flex>
                </Panel>
            </>, document.body)}
        </>
    );
}