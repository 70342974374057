import { useMemo } from "react";
import { useActivePermissions } from "src/ui/roles";
import { AppRoute, routeToPermissionMap } from "src/ui/routes";

export const routeToMainMenuMap: Partial<
    Record<AppRoute, string>
> = {
    [AppRoute.dashboard]: "Dashboard",
    [AppRoute.allUsers]: "All Users",
    [AppRoute.manageOffers]: "Manage Offers",
    [AppRoute.myOffers]: "View My Offers",
    [AppRoute.listings]: "View Listings",
    [AppRoute.postListing]: "Post Listing",
    // [AppRoute.inviteBuyer]: "Invite Buyer",
    // [AppRoute.buyerList]: "View Buyers List",
    // [AppRoute.inviteSeller]: "Invite Seller",
    // [AppRoute.sellerList]: "View Sellers List",
};
export const routeToUserMenuMap: Partial<
    Record<AppRoute, string>
> = {
    // [AppRoute.editProfile]: "Edit My Profile",
    [AppRoute.logout]: "Logout",
};

type NavigationRoute = {
    route: string,
    label: string,
};
export const useNavigationRoutes = (
    routeMap: Partial<Record<AppRoute, string>>,
): NavigationRoute[] => {
    const permissions = useActivePermissions();

    return useMemo(() => {
        const items: NavigationRoute[] = [];
        Object.entries(routeMap).forEach(
            ([route, label]) => {
                const hasPermission = routeToPermissionMap[route as AppRoute].find(
                    (requiredPermission) => permissions[requiredPermission]
                );
                if (hasPermission) {
                    items.push({ route, label });
                }
            }
        );
        return items;
    }, [permissions, routeMap]);
};

export const useTopLevelRoutes = () =>
    useNavigationRoutes(routeToMainMenuMap);

export const useUserMenuRoutes = () =>
    useNavigationRoutes(routeToUserMenuMap);

export const useRoutesWithPermission = () => {
    const permissions = useActivePermissions();

    return useMemo(() => {
        const routes: string[] = [];
        Object.values(AppRoute).forEach(
            (route) => {
                const hasPermission = routeToPermissionMap[route as AppRoute].find(
                    (requiredPermission) => permissions[requiredPermission]
                );
                if (hasPermission) {
                    routes.push(route);
                }
            }
        );
        return routes;
    }, [permissions]);
};
