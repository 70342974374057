import React, { FC, useRef, useEffect } from "react";
import { generatePath, NavLink, useParams } from "react-router-dom";
import { Flex } from "src/ui/primitives";
import { AppRoute } from "src/ui/routes";
import styled from "styled-components";

const Wrap = styled.div`
    display: flex;
    justify-content: space-between;
    padding: .5rem;
    overflow-x: auto;
    flex-shrink: 0;
    position: relative;
`;
const StepLink = styled(NavLink)`
    display: inline-block;
    padding: 1rem;
    white-space: nowrap;
    border-radius: .25rem;
    &.active {
        background: white;
    }
`;

export const StepNavigation: FC = () => {
    const { listingId } = useParams();
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (listingId && ref.current) {
            const active = ref.current.querySelector('.active');
            active && (active as HTMLElement).scrollIntoView({
                block: 'nearest',
                inline: 'center',
            });
        }
    }, [listingId]);

    if (!listingId)
        return null;

    return <>
        <Wrap ref={ref}>
            <StepLink to={
                generatePath(AppRoute.listingById, { listingId })
            }>
                View Listing
            </StepLink>
            <Flex $justify="flex-end">
                <StepLink to={
                    generatePath(AppRoute.editListing, { listingId })
                }>
                    Edit Basics
                </StepLink>
                <StepLink to={
                    generatePath(AppRoute.editListingDetails, { listingId })
                }>
                    Edit Details
                </StepLink>
                <StepLink to={
                    generatePath(AppRoute.editListingChoices, { listingId })
                }>
                    Edit Choices
                </StepLink>
                <StepLink to={
                    generatePath(AppRoute.editListingPhotos, { listingId })
                }>
                    Edit Photos
                </StepLink>
            </Flex>
        </Wrap>
    </>;
};
