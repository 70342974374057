import React, {
    FC,
    useState,
    createContext,
    ReactNode,
    useMemo,
    useContext,
} from "react";
import {
    API_LOCALSTORAGE_KEY,
    getStoredApiToken
} from "./storage";

const tokenContext = createContext<{
    token: string | undefined,
    refresh: () => void;
    remove: () => void;
}>({
    token: undefined,
    refresh: () => {},
    remove: () => {},
});

export const TokenProvider: FC<{ children: ReactNode; }> = ({ children }) => {
    const [token, setToken] = useState<string | undefined>(getStoredApiToken);
    const tokenApi = useMemo(() => ({
        token,
        refresh: () => setToken(getStoredApiToken()),
        remove: () => {
            setToken(undefined);
            window.localStorage.removeItem(API_LOCALSTORAGE_KEY);
        },
    }), [token]);

    return (
        <tokenContext.Provider value={tokenApi}>
            {children}
        </tokenContext.Provider>
    );
};
export const useApiTokenContext = () => useContext(tokenContext);

export const useIsLoggedIn = () => !!useApiTokenContext().token;
