import React, { FC } from "react";
import styled from "styled-components";

type Variant = 'light' | 'dark';

const StyledSvg = styled.svg<{ $width: number, $height: number }>`
    ${props => `enable-background: new 0 0 ${props.$width} ${props.$height};`}
    
`;
const StyledPath = styled.path`
    fill: #532b77;
`;
const InvestmentsPath = styled.path<{ $variant: Variant }>`
    fill: ${props => props.$variant === 'dark' ? '#000000' : '#ffffff' };
`;
const TaglinePath = styled.path<{ $variant: Variant }>`
    fill: ${props => props.$variant === 'dark' ? '#000000' : '#ffffff' };
`;
const WorldwidePath = styled.path<{ $variant: Variant }>`
    fill: ${props => props.$variant === 'dark' ? '#000000' : '#ffffff' };
`;

export const Logo: FC<{
    width?: number;
    height?: number;
    className?: string;
    variant?: Variant,
}> = ({
    width = 1117,
    height = 381,
    className,
    variant = 'light',
}) => (
    <StyledSvg
        $width={width}
        $height={height}
        className={className}
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox={`0 0 ${width} ${height}`}
    >
        <InvestmentsPath
            $variant={variant}
            d="M530.1,240.1c-15.7,0-26.4-11.6-31.6-24.4H545c3.9-19.6-7.5-39.6-31.2-39.6c-47.1,0-48,78,1.2,78
            c19.9,0,34.2-12.9,34.5-32.2h-2.3C546.2,234.7,538.8,240.1,530.1,240.1z M507.4,181.8c11.6,0,19.6,14.5,17.3,28.3h-28.3
            C492.5,195.8,495.7,181.8,507.4,181.8z M393.5,238.5v-35.8c0-16.8-10.6-26.5-26.5-26.5c-10.7,0-16.7,4.2-19.4,12v-10.6h-28
            c4.8,2.9,6.2,5.7,6.2,14.2v46.7c0,8.4-1.5,11.2-6.2,14.1h34.2c-4.8-2.9-6.2-5.7-6.2-14.1v-33.2c0-14.8,1.9-23.5,11-23.5
            c8.3,0,13.2,7,13.2,20.9v35.8c0,8.4-1.5,11.2-6.2,14.1h34.2C394.9,249.6,393.5,246.9,393.5,238.5z M310.8,146.4h-36.5
            c4.8,2.9,6.2,5.7,6.2,14.1v78c0,8.4-1.5,11.2-6.2,14.1h36.5c-5.1-2.9-6.2-5.7-6.2-14.1v-78C304.6,152.1,305.7,149.3,310.8,146.4z
            M581,189.9c0-4.8,2.3-8.1,8.3-8.1c11.5,0,9.6,12.2,7.5,20.6l18.7-12.6c-2.9-7.7-14.4-13.6-26.2-13.6c-15.7,0-28,6.8-28,22.2
            c0,25.8,35.5,24.9,35.5,40.3c0,5.9-3.5,9.9-7.1,9.9c-8,0-9.3-15.5-5.8-20.6l-24.8,6.8c2.3,14.4,15.2,19.4,30.2,19.4
            c16.5,0,30.3-7.5,30.3-22.8C619.5,207.3,581,209.9,581,189.9z M462.2,191.8l-15.4,52.9l-19.7-67.1h-29c5.8,2.9,7.8,5.7,10.3,14.2
            l17.7,60.8h24.4l21.8-75h-12C464.2,180.5,464.7,183.2,462.2,191.8z M660.3,248.5c-2.8,0-4.2-3.8-4.2-11.6v-53.7h10v-5.7h-10v-12.5
            l-28,12.8c4.8,2.8,6.2,5.4,6.2,14.1v38.9c0,15.5,6.4,23.3,19.9,23.3c4.9,0,12.5-1.2,13.9-12.9l-3.6-2
            C665.2,245.3,662.8,248.5,660.3,248.5z M1006.3,248.5c-2.8,0-4.2-3.8-4.2-11.6v-53.7h10v-5.7h-10v-12.5l-28,12.8
            c4.8,2.8,6.2,5.4,6.2,14.1v38.9c0,15.5,6.4,23.3,19.9,23.3c4.9,0,12.5-1.2,13.9-12.9l-3.6-2C1011.2,245.3,1008.8,248.5,1006.3,248.5
            z M796,238.5v-35.8c0-16.8-10.6-26.5-24.9-26.5c-11.7,0-19.3,6.7-22.2,17.8c-3-11.5-12.5-17.8-25.4-17.8c-10.7,0-16.7,4.2-19.4,12
            v-10.6h-28c4.8,2.9,6.2,5.7,6.2,14.2v46.7c0,8.4-1.5,11.2-6.2,14.1h34.2c-4.8-2.9-6.2-5.7-6.2-14.1v-33.2c0-14.8,1.9-23.5,11-23.5
            c8.3,0,13.2,7,13.2,20.9v35.8c0,8.4-1.4,11.2-6.2,14.1h34.2c-4.8-2.9-6.2-5.7-6.2-14.1v-33.2c0-14.8,5.4-23.5,12.6-23.5
            c6.7,0,11.5,7,11.5,20.9v35.8c0,8.4-1.3,11.2-6.2,14.1h34.4C797,249.6,796,246.9,796,238.5z M1044.6,189.9c0-4.8,2.3-8.1,8.3-8.1
            c11.5,0,9.6,12.2,7.5,20.6l18.7-12.6c-2.9-7.7-14.4-13.6-26.2-13.6c-15.7,0-28,6.8-28,22.2c0,25.8,35.5,24.9,35.5,40.3
            c0,5.9-3.5,9.9-7.1,9.9c-8,0-9.3-15.5-5.8-20.6l-24.8,6.8c2.3,14.4,15.2,19.4,30.2,19.4c16.5,0,30.3-7.5,30.3-22.8
            C1083.1,207.3,1044.6,209.9,1044.6,189.9z M963.8,238.5v-35.8c0-16.8-10.6-26.5-26.5-26.5c-10.7,0-16.7,4.2-19.4,12v-10.6h-28
            c4.8,2.9,6.2,5.7,6.2,14.2v46.7c0,8.4-1.5,11.2-6.2,14.1h34.2c-4.8-2.9-6.2-5.7-6.2-14.1v-33.2c0-14.8,1.9-23.5,11-23.5
            c8.3,0,13.2,7,13.2,20.9v35.8c0,8.4-1.5,11.2-6.2,14.1H970C965.3,249.6,963.8,246.9,963.8,238.5z M862.6,240.1
            c-15.7,0-26.4-11.6-31.6-24.4h46.6c3.9-19.6-7.5-39.6-31.2-39.6c-47.1,0-48,78,1.2,78c19.9,0,34.2-12.9,34.5-32.2h-2.3
            C878.7,234.7,871.3,240.1,862.6,240.1z M840,181.8c11.6,0,19.6,14.5,17.3,28.3h-28.3C825,195.8,828.2,181.8,840,181.8z"
        />
        <g>
            <WorldwidePath
                $variant={variant}
                d="M258.5,122.3l-24.7-98.5c-2.3-9-4.2-11.9-10.4-15h39.9c-4.3,3.1-5.1,6.1-3.1,15l22,90.1l16.9-69.7L294,23.8
                c-2.3-9-4-11.9-10.2-15h39.9c-4.5,3.1-5.3,6.1-3.1,15l21.9,90.1l22-90.1c2-9,1.4-11.9-3.1-15H381c-6.1,3.1-7.9,6.1-10.4,15
                l-24.8,98.5h-27l-16.6-66.1l-16.4,66.1H258.5z"
            />
            <WorldwidePath
                $variant={variant}
                d="M466.8,82.3c0,25-16,41.7-41.1,41.7c-24.4,0-40.8-16.8-40.8-41.7c0-25,16.4-41.7,40.8-41.7
                C450.8,40.6,466.8,57.3,466.8,82.3z M442.4,82.3c0-21.4-6.7-35.7-16.8-35.7c-9.8,0-16.4,14.3-16.4,35.7c0,21.3,6.7,35.7,16.4,35.7
                C435.8,118,442.4,103.6,442.4,82.3z"
            />
            <WorldwidePath
                $variant={variant}
                d="M539.9,69c3.3-12.6-2.5-22.3-11.8-22.3c-8.8,0-14.1,9.3-14.1,25.1v35.5c0,9,1.6,11.9,6.7,15H484c5.1-3.1,6.7-6.1,6.7-15
                v-50c0-9.2-1.6-12.1-6.7-15.2H514v13.2c3.6-9.6,10.7-14.7,22-14.7c7.4,0,14.1,2.3,18.8,6.8L539.9,69z"
            />
            <WorldwidePath
                $variant={variant}
                d="M575.4,107.3V23.8c0-9-1.6-11.9-6.7-15h29.9v98.5c0,9,1.6,11.9,6.7,15h-36.6C573.8,119.2,575.4,116.3,575.4,107.3z"
            />
            <WorldwidePath
                $variant={variant}
                d="M701.4,116c-0.9,5-4.7,8.1-12.1,8.1c-9.5,0-15.2-4.7-16-13.2c-2.9,8.4-8.5,13.2-18.9,13.2c-18.8,0-31-15.8-31-41.7
                c0-26.2,12.3-41.7,31-41.7c10.2,0,16,4.5,18.9,13V23.8c0-9-1.1-11.9-6.7-15h30.3V115c0,2.2,0.6,2.9,1.6,2.9c1.1,0,2-0.5,2.5-2.3
                L701.4,116z M673.3,71.8c0-15.8-1.6-25.1-10.4-25.1c-10.1,0-15.2,12.7-15.2,35.7c0,22.8,5.1,35.7,15.2,35.7
                c8.8,0,10.4-9.5,10.4-25.1V71.8z"
            />
            <WorldwidePath
                $variant={variant}
                d="M746.2,122.3l-18.9-65c-2.6-9.2-4.8-12.1-11-15.2h31l21.1,71.8l11.8-40.5l-4.7-16.1c-2.8-9.2-5.1-12.1-11.3-15.2h31.2
                l21.1,71.8l16.4-56.6c2.8-9.2,2.2-12.1-2.3-15.2h13l-23.3,80.2h-26.1l-10.9-38l-11.2,38H746.2z"
            />
            <WorldwidePath
                $variant={variant}
                d="M865.8,107.3v-50c0-9.2-1.6-12.1-6.7-15.2h29.9v65.2c0,9,1.6,11.9,6.7,15h-36.6C864.3,119.2,865.8,116.3,865.8,107.3z
                M877.6,38.6l-15.8-15.7l15.8-15.8l15.7,15.8L877.6,38.6z"
            />
            <WorldwidePath
                $variant={variant}
                d="M990.1,116c-0.9,5-4.7,8.1-12.1,8.1c-9.5,0-15.2-4.7-16-13.2c-2.9,8.4-8.5,13.2-18.9,13.2c-18.8,0-31-15.8-31-41.7
                c0-26.2,12.3-41.7,31-41.7c10.2,0,16,4.5,18.9,13V23.8c0-9-1.1-11.9-6.7-15h30.3V115c0,2.2,0.6,2.9,1.6,2.9c1.1,0,2-0.5,2.5-2.3
                L990.1,116z M962,71.8c0-15.8-1.6-25.1-10.4-25.1c-10.1,0-15.2,12.7-15.2,35.7c0,22.8,5.1,35.7,15.2,35.7c8.8,0,10.4-9.5,10.4-25.1
                V71.8z"
            />
            <WorldwidePath
                $variant={variant}
                d="M1085.5,89.6c-0.3,20.6-15.7,34.4-36.9,34.4c-52.6,0-51.7-83.5-1.2-83.5c25.3,0,37.5,21.4,33.4,42.4h-49.8
                c5.6,13.7,17.1,26.1,33.8,26.1c9.3,0,17.2-5.7,18.3-19.4H1085.5z M1028.7,76.9h30.3c2.5-14.7-6.1-30.3-18.5-30.3
                C1027.9,46.6,1024.5,61.7,1028.7,76.9z"
            />
        </g>
        <TaglinePath
            $variant={variant}
            d="M1083.7,347.7c-0.3-1.6-0.8-3-1.6-4.2c-0.8-1.3-2-2.3-3.6-3.1c-1.6-0.8-3.2-1.2-4.9-1.2c-3.3,0-6,1.2-8,3.6
            s-3.1,5.5-3.1,9.3c0,3.9,1,6.9,3.1,9c2,2.1,4.5,3.2,7.4,3.2c1.2,0,2.3-0.1,3.2-0.4c1.8-0.4,3.3-1.2,4.5-2.4c0.7-0.7,1.4-1.5,1.9-2.6
            c0.6-1.1,0.9-2,1-2.9h-3.9c-0.3,1.1-0.8,2-1.5,2.8c-1.2,1.4-2.9,2.1-4.9,2.1c-2.2,0-3.8-0.7-4.9-2.2c-1.1-1.5-1.6-3.4-1.7-5.8h17.2
            C1084,350.6,1083.9,348.8,1083.7,347.7z M1066.9,349.8c0.1-2,0.7-3.7,1.9-5c1.2-1.3,2.7-2,4.6-2c2.5,0,4.3,1,5.4,2.9
            c0.6,1,1,2.4,1.1,4.1H1066.9z M1089.2,328.8h3.5v8.8h2.1v-8.8h3.5V327h-9.1V328.8z M476.4,339.2c-1.4,0-2.7,0.3-3.9,0.9
            c-1.2,0.6-2.3,1.5-3.1,2.6V331h-3.9v32.6h3.7v-3c0.8,1.1,1.6,2,2.4,2.5c1.2,0.9,2.8,1.3,4.6,1.3c3.2,0,5.7-1.2,7.6-3.7
            c1.8-2.5,2.7-5.6,2.7-9.3c0-3.9-0.9-6.9-2.8-9C481.8,340.2,479.4,339.2,476.4,339.2z M480.7,358.2c-1.1,1.7-2.7,2.6-4.8,2.6
            c-2.9,0-4.8-1.3-5.9-3.9c-0.6-1.4-0.8-3.2-0.8-5.4c0-3.1,0.7-5.3,2.1-6.6s3-2,4.8-2c2.1,0,3.7,0.8,4.7,2.5s1.6,3.7,1.6,6.2
            C482.3,354.2,481.7,356.5,480.7,358.2z M581.2,360.8c-0.2-0.6-0.3-1.5-0.3-2.8l-0.2-4.4c-0.1-1.9-0.4-3.2-1.1-4.1
            c-0.6-0.8-1.6-1.5-2.9-2c1.4-0.8,2.6-1.7,3.4-3c0.8-1.2,1.2-2.8,1.2-4.8c0-3.7-1.5-6.3-4.5-7.7c-1.6-0.7-3.6-1.1-6-1.1h-14.7v32.4
            h4.4v-13.9h9.7c1.7,0,3,0.2,3.8,0.6c1.4,0.7,2.2,2.1,2.3,4.3l0.3,5.4c0.1,1.3,0.1,2.1,0.2,2.6c0.1,0.4,0.2,0.8,0.4,1.1h5.4v-0.7
            C581.9,362.5,581.4,361.8,581.2,360.8z M575.1,344.7c-1.2,0.8-2.8,1.2-4.8,1.2h-9.9v-11.1h10.4c1.5,0,2.6,0.2,3.5,0.7
            c1.6,0.8,2.4,2.4,2.4,4.7C576.9,342.4,576.3,343.9,575.1,344.7z M515,360.8c-0.5,0-0.9-0.1-1.1-0.4c-0.2-0.2-0.3-0.6-0.3-1v-13.6
            c0-2.2-0.9-3.9-2.6-4.9c-1.7-1.1-4-1.6-6.8-1.6c-2.4,0-4.5,0.6-6.3,1.7s-2.8,3.2-2.8,6.1h3.7c0.1-1.2,0.5-2.2,0.9-2.8
            c0.9-1.1,2.3-1.7,4.4-1.7c1.8,0,3.2,0.3,4.1,0.9c1,0.6,1.4,1.6,1.4,3c0,0.7-0.1,1.2-0.3,1.5c-0.3,0.6-0.9,1-1.8,1.1l-6.3,0.8
            c-2.2,0.3-4,1-5.4,2.2c-1.4,1.2-2.1,2.9-2.1,5.1c0,2,0.7,3.7,2.1,5s3.2,2,5.4,2c1.8,0,3.5-0.4,5-1.2c1.5-0.8,2.7-1.7,3.5-2.8
            c0.1,0.9,0.4,1.7,0.7,2.2c0.6,1,1.6,1.5,3,1.5c0.6,0,1,0,1.3-0.1c0.3,0,0.8-0.2,1.4-0.3v-2.9c-0.3,0-0.5,0.1-0.7,0.1
            C515.4,360.8,515.2,360.8,515,360.8z M509.7,354.7c0,2.4-1.1,4.2-3.3,5.3c-1.3,0.6-2.7,1-4.1,1c-1.1,0-2.1-0.3-3-1s-1.3-1.6-1.3-2.7
            c0-1.5,0.6-2.6,1.8-3.3c0.7-0.4,1.8-0.7,3.2-0.9l2.4-0.3c0.7-0.1,1.5-0.2,2.2-0.4c0.8-0.2,1.4-0.5,1.9-0.8V354.7z M524.4,363.5h4
            v-32.4h-4V363.5z M446,339.2c-3.4,0-6.1,1.2-8.1,3.5c-2,2.4-3,5.5-3,9.5c0,3.7,1,6.7,2.9,8.9c1.9,2.2,4.5,3.3,7.7,3.3
            c3.9,0,6.7-1.2,8.6-3.7c1.8-2.5,2.8-5.6,2.8-9.4c0-3.9-1-6.9-3.1-9C451.6,340.2,449.1,339.2,446,339.2z M451.2,357.9
            c-1,2-2.8,3-5.4,3c-2.4,0-4.1-0.8-5.2-2.5c-1.1-1.7-1.6-3.8-1.6-6.3c0-2.6,0.5-4.9,1.6-6.7c1.1-1.9,2.8-2.8,5.2-2.8
            c2.7,0,4.6,1.1,5.7,3.3c0.7,1.4,1,3.2,1,5.4C452.7,353.7,452.2,355.9,451.2,357.9z M319.4,339.3c-1.9,0-3.5,0.4-4.8,1.3
            c-0.8,0.5-1.6,1.3-2.5,2.5V331h-4v32.6h4V351c0-3.2,0.7-5.3,2-6.4c1.3-1.1,2.8-1.7,4.5-1.7c2,0,3.3,0.6,4.1,1.9
            c0.4,0.8,0.7,2.1,0.7,3.8v14.9h4.1v-15.2c0-2.3-0.3-4.1-1-5.5C325.2,340.5,322.8,339.3,319.4,339.3z M357.1,347.7
            c-0.3-1.6-0.8-3-1.6-4.2c-0.8-1.3-2-2.3-3.6-3.1c-1.6-0.8-3.2-1.2-4.9-1.2c-3.3,0-6,1.2-8,3.6s-3.1,5.5-3.1,9.3c0,3.9,1,6.9,3.1,9
            c2,2.1,4.5,3.2,7.4,3.2c1.2,0,2.3-0.1,3.2-0.4c1.8-0.4,3.3-1.2,4.5-2.4c0.7-0.7,1.4-1.5,1.9-2.6c0.6-1.1,0.9-2,1-2.9h-3.9
            c-0.3,1.1-0.8,2-1.5,2.8c-1.2,1.4-2.9,2.1-4.9,2.1c-2.2,0-3.8-0.7-4.9-2.2c-1.1-1.5-1.6-3.4-1.7-5.8h17.2
            C357.4,350.6,357.3,348.8,357.1,347.7z M340.3,349.8c0.1-2,0.7-3.7,1.9-5c1.2-1.3,2.7-2,4.6-2c2.5,0,4.3,1,5.4,2.9
            c0.6,1,1,2.4,1.1,4.1H340.3z M274.2,334.9h10.9v28.6h4.4v-28.6h10.9v-3.9h-26.3V334.9z M422.6,363.5h4v-32.4h-4V363.5z M1108.5,327
            l-2.7,7.6l-2.7-7.6h-3v10.6h1.9V329l3,8.6h1.7l2.9-8.6v8.6h1.9V327H1108.5z M1050.1,343.1c-0.8-1-1.5-1.7-2.3-2.3
            c-1.4-0.9-3-1.4-4.8-1.4c-2.7,0-5.1,1.1-7,3.3c-1.9,2.2-2.9,5.3-2.9,9.3c0,4.2,1,7.2,2.9,9c1.9,1.8,4.1,2.7,6.7,2.7
            c1.8,0,3.3-0.3,4.4-0.9c1.1-0.6,2.1-1.5,2.9-2.7c0,3.3-0.2,5.5-0.8,6.8c-0.9,2.1-2.9,3.2-6,3.2c-1.9,0-3.3-0.4-4.2-1.3
            c-0.6-0.6-1-1.4-1.1-2.5h-4c0.2,2.4,1.2,4.2,2.9,5.4c1.7,1.1,3.9,1.7,6.4,1.7c4.6,0,7.7-1.6,9.3-4.8c0.9-1.7,1.3-4.1,1.3-7.1v-21.5
            h-3.7V343.1z M1049.1,356.7c-1.2,2.6-3.2,3.9-6,3.9c-1.8,0-3.3-0.7-4.3-2.1c-1.1-1.4-1.6-3.4-1.6-6.1c0-2.3,0.3-4.1,0.8-5.5
            c1-2.6,2.9-3.9,5.7-3.9c1.9,0,3.4,0.7,4.6,2.1c1.2,1.4,1.8,3.6,1.8,6.5C1050.1,353.5,1049.8,355.2,1049.1,356.7z M397.4,349.8h10.2
            c0,3.8-1,6.5-2.9,8.2c-1.9,1.7-4.3,2.6-7.1,2.6c-3.8,0-6.5-1.2-8.1-3.6c-1.7-2.4-2.5-5.6-2.5-9.4c0-4.5,1-7.8,3-10.1
            c2-2.3,4.5-3.4,7.4-3.4c2.5,0,4.5,0.5,6.2,1.6c1.6,1.1,2.7,2.8,3.2,5.2h4.4c-0.7-4.2-2.7-7.2-5.8-8.9c-2.2-1.2-4.8-1.8-7.8-1.8
            c-4.4,0-8,1.5-10.7,4.6c-2.9,3.2-4.4,7.5-4.4,13c0,5.4,1.5,9.7,4.5,12.9c2.7,2.5,6,3.7,9.8,3.7c2.9,0,5.3-0.5,7.2-1.6
            c1.1-0.6,2.4-1.8,3.9-3.4l1.1,4.2h2.8v-17.4h-14.2V349.8z M746.7,333.2h-4v6.6h-3.2v3.2h3.2v15.7c0,1.5,0.3,2.8,1,3.7
            c0.7,0.9,1.9,1.4,3.8,1.4c0.6,0,1.1,0,1.6-0.1c0.5-0.1,1-0.2,1.4-0.3v-3.2c-0.3,0-0.6,0.1-0.8,0.1s-0.4,0-0.6,0
            c-0.7,0-1.2-0.1-1.5-0.2c-0.6-0.3-0.8-0.8-0.8-1.7v-15.4h3.8v-3.2h-3.8V333.2z M824.2,347.7c-0.3-1.6-0.8-3-1.6-4.2
            c-0.8-1.3-2-2.3-3.6-3.1c-1.6-0.8-3.2-1.2-4.9-1.2c-3.3,0-6,1.2-8,3.6s-3.1,5.5-3.1,9.3c0,3.9,1,6.9,3.1,9c2,2.1,4.5,3.2,7.4,3.2
            c1.2,0,2.3-0.1,3.2-0.4c1.8-0.4,3.3-1.2,4.5-2.4c0.7-0.7,1.4-1.5,1.9-2.6c0.6-1.1,0.9-2,1-2.9h-3.9c-0.3,1.1-0.8,2-1.5,2.8
            c-1.2,1.4-2.9,2.1-4.9,2.1c-2.2,0-3.8-0.7-4.9-2.2c-1.1-1.5-1.6-3.4-1.7-5.8h17.2C824.5,350.6,824.4,348.8,824.2,347.7z
            M807.4,349.8c0.1-2,0.7-3.7,1.9-5c1.2-1.3,2.7-2,4.6-2c2.5,0,4.3,1,5.4,2.9c0.6,1,1,2.4,1.1,4.1H807.4z M871.6,356.8
            c-1.7,2.5-4.1,3.7-7.3,3.7c-3.5,0-6.1-1.2-7.8-3.5c-1.7-2.4-2.6-5.4-2.6-9.3c0-4.6,1-8.1,2.9-10.3c1.9-2.2,4.5-3.4,7.6-3.4
            c2.6,0,4.6,0.6,6,1.8c1.4,1.2,2.4,2.9,2.9,5h4.3c-0.2-2.8-1.5-5.2-3.7-7.4c-2.2-2.2-5.4-3.2-9.5-3.2c-4.8,0-8.5,1.7-11.2,5
            c-2.5,3.1-3.8,7-3.8,11.8c0,6.3,1.7,11,5,14c2.5,2.3,5.7,3.4,9.4,3.4c4.3,0,7.8-1.4,10.3-4.2c2.1-2.3,3.4-5.2,3.8-8.6h-4.3
            C873.1,353.7,872.4,355.5,871.6,356.8z M792.6,333.2h-4v6.6h-3.2v3.2h3.2v15.7c0,1.5,0.3,2.8,1,3.7c0.7,0.9,1.9,1.4,3.8,1.4
            c0.6,0,1.1,0,1.6-0.1c0.5-0.1,1-0.2,1.4-0.3v-3.2c-0.3,0-0.6,0.1-0.8,0.1s-0.4,0-0.6,0c-0.7,0-1.2-0.1-1.5-0.2
            c-0.6-0.3-0.8-0.8-0.8-1.7v-15.4h3.8v-3.2h-3.8V333.2z M972.2,335.6h4v-4.5h-4V335.6z M954.6,342.7c1.9,0,3.2,0.5,4,1.4
            c0.8,1,1.4,2.2,1.6,3.6h3.9c-0.3-3.2-1.3-5.4-3-6.7c-1.7-1.3-3.8-1.9-6.5-1.9c-3.1,0-5.7,1.2-7.7,3.5c-2,2.3-3,5.5-3,9.7
            c0,3.4,0.9,6.2,2.8,8.5c1.8,2.3,4.3,3.4,7.4,3.4c2.8,0,5-0.7,6.8-2.2c1.8-1.5,2.9-3.7,3.3-6.9h-3.9c-0.4,1.7-1,3.1-2,4.1
            c-1,1-2.3,1.5-3.9,1.5c-2.2,0-3.7-0.8-4.7-2.4c-1-1.6-1.5-3.6-1.5-6c0-2.4,0.4-4.3,1.1-5.9C950.3,344,952.1,342.7,954.6,342.7z
            M896.5,339.2c-3.4,0-6.1,1.2-8.1,3.5c-2,2.4-3,5.5-3,9.5c0,3.7,0.9,6.7,2.9,8.9c1.9,2.2,4.5,3.3,7.7,3.3c3.9,0,6.7-1.2,8.6-3.7
            c1.8-2.5,2.8-5.6,2.8-9.4c0-3.9-1-6.9-3.1-9C902.1,340.2,899.5,339.2,896.5,339.2z M901.7,357.9c-1,2-2.8,3-5.4,3
            c-2.4,0-4.1-0.8-5.2-2.5c-1.1-1.7-1.6-3.8-1.6-6.3c0-2.6,0.5-4.9,1.6-6.7c1.1-1.9,2.8-2.8,5.2-2.8c2.7,0,4.6,1.1,5.7,3.3
            c0.7,1.4,1,3.2,1,5.4C903.2,353.7,902.7,355.9,901.7,357.9z M778.5,360.8c-0.5,0-0.9-0.1-1.1-0.4s-0.3-0.6-0.3-1v-13.6
            c0-2.2-0.9-3.9-2.6-4.9c-1.7-1.1-4-1.6-6.8-1.6c-2.4,0-4.5,0.6-6.3,1.7s-2.8,3.2-2.8,6.1h3.7c0.1-1.2,0.5-2.2,0.9-2.8
            c0.9-1.1,2.3-1.7,4.4-1.7c1.8,0,3.2,0.3,4.1,0.9c1,0.6,1.4,1.6,1.4,3c0,0.7-0.1,1.2-0.3,1.5c-0.3,0.6-0.9,1-1.8,1.1l-6.3,0.8
            c-2.2,0.3-4,1-5.4,2.2c-1.4,1.2-2.1,2.9-2.1,5.1c0,2,0.7,3.7,2.1,5s3.2,2,5.4,2c1.8,0,3.5-0.4,5-1.2s2.7-1.7,3.5-2.8
            c0.1,0.9,0.4,1.7,0.7,2.2c0.6,1,1.6,1.5,3,1.5c0.6,0,1,0,1.3-0.1c0.3,0,0.8-0.2,1.4-0.3v-2.9c-0.3,0-0.5,0.1-0.7,0.1
            C778.9,360.8,778.7,360.8,778.5,360.8z M773.2,354.7c0,2.4-1.1,4.2-3.3,5.3c-1.3,0.6-2.7,1-4.1,1c-1.1,0-2.1-0.3-3-1
            c-0.8-0.7-1.3-1.6-1.3-2.7c0-1.5,0.6-2.6,1.8-3.3c0.7-0.4,1.8-0.7,3.2-0.9l2.4-0.3c0.7-0.1,1.5-0.2,2.2-0.4c0.8-0.2,1.4-0.5,1.9-0.8
            V354.7z M927.8,339.3c-1.5,0-2.9,0.3-4.2,0.9s-2.4,1.6-3.6,3v-3.4h-3.8v23.7h4v-12.4c0-1.5,0.1-2.7,0.3-3.6c0.2-0.8,0.6-1.6,1.3-2.4
            c0.8-1,1.8-1.6,2.9-1.9c0.6-0.2,1.4-0.3,2.3-0.3c1.9,0,3.1,0.7,3.8,2.2c0.4,0.9,0.6,2,0.6,3.5v14.9h4v-15.2c0-2.4-0.3-4.2-1-5.5
            C933.4,340.5,931.1,339.3,927.8,339.3z M650.3,363.5h4v-32.4h-4V363.5z M641,360.8c-0.5,0-0.9-0.1-1.1-0.4s-0.3-0.6-0.3-1v-13.6
            c0-2.2-0.9-3.9-2.6-4.9c-1.7-1.1-4-1.6-6.8-1.6c-2.4,0-4.5,0.6-6.3,1.7s-2.8,3.2-2.8,6.1h3.7c0.1-1.2,0.5-2.2,0.9-2.8
            c0.9-1.1,2.3-1.7,4.4-1.7c1.8,0,3.2,0.3,4.1,0.9c1,0.6,1.4,1.6,1.4,3c0,0.7-0.1,1.2-0.3,1.5c-0.3,0.6-0.9,1-1.8,1.1l-6.3,0.8
            c-2.2,0.3-4,1-5.4,2.2c-1.4,1.2-2.1,2.9-2.1,5.1c0,2,0.7,3.7,2.1,5s3.2,2,5.4,2c1.8,0,3.5-0.4,5-1.2s2.7-1.7,3.5-2.8
            c0.1,0.9,0.4,1.7,0.7,2.2c0.6,1,1.6,1.5,3,1.5c0.6,0,1,0,1.3-0.1c0.3,0,0.8-0.2,1.4-0.3v-2.9c-0.3,0-0.5,0.1-0.7,0.1
            C641.3,360.8,641.1,360.8,641,360.8z M635.7,354.7c0,2.4-1.1,4.2-3.3,5.3c-1.3,0.6-2.7,1-4.1,1c-1.1,0-2.1-0.3-3-1
            c-0.8-0.7-1.3-1.6-1.3-2.7c0-1.5,0.6-2.6,1.8-3.3c0.7-0.4,1.8-0.7,3.2-0.9l2.4-0.3c0.7-0.1,1.5-0.2,2.2-0.4c0.8-0.2,1.4-0.5,1.9-0.8
            V354.7z M1025.9,339.3c-1.6,0-3,0.6-4.2,1.7c-1.2,1.1-2,2.1-2.3,2.9v-4.1h-3.8v23.7h4v-13.6c0-1.7,0.5-3.2,1.6-4.5
            c1.1-1.3,2.6-1.9,4.6-1.9c0.2,0,0.5,0,0.7,0c0.2,0,0.4,0,0.7,0.1v-4.2c-0.4,0-0.7-0.1-0.9-0.1S1026,339.3,1025.9,339.3z
            M611.5,347.7c-0.3-1.6-0.8-3-1.6-4.2c-0.8-1.3-2-2.3-3.6-3.1c-1.6-0.8-3.2-1.2-4.9-1.2c-3.3,0-6,1.2-8,3.6s-3.1,5.5-3.1,9.3
            c0,3.9,1,6.9,3.1,9c2,2.1,4.5,3.2,7.4,3.2c1.2,0,2.3-0.1,3.2-0.4c1.8-0.4,3.3-1.2,4.5-2.4c0.7-0.7,1.4-1.5,1.9-2.6
            c0.6-1.1,0.9-2,1-2.9h-3.9c-0.3,1.1-0.8,2-1.5,2.8c-1.2,1.4-2.9,2.1-4.9,2.1c-2.2,0-3.8-0.7-4.9-2.2c-1.1-1.5-1.6-3.4-1.7-5.8h17.2
            C611.9,350.6,611.8,348.8,611.5,347.7z M594.8,349.8c0.1-2,0.7-3.7,1.9-5c1.2-1.3,2.7-2,4.6-2c2.5,0,4.3,1,5.4,2.9
            c0.6,1,1,2.4,1.1,4.1H594.8z M686.2,348.6H704v-3.8h-17.8V335h19.3v-4h-23.5v32.4h23.9v-3.9h-19.6V348.6z M1006.1,347.7
            c-0.3-1.6-0.8-3-1.6-4.2c-0.8-1.3-2-2.3-3.6-3.1c-1.6-0.8-3.2-1.2-4.9-1.2c-3.3,0-6,1.2-8,3.6s-3.1,5.5-3.1,9.3c0,3.9,1,6.9,3.1,9
            c2,2.1,4.5,3.2,7.4,3.2c1.2,0,2.3-0.1,3.2-0.4c1.8-0.4,3.3-1.2,4.5-2.4c0.7-0.7,1.4-1.5,1.9-2.6c0.6-1.1,0.9-2,1-2.9h-3.9
            c-0.3,1.1-0.8,2-1.5,2.8c-1.2,1.4-2.9,2.1-4.9,2.1c-2.2,0-3.8-0.7-4.9-2.2c-1.1-1.5-1.6-3.4-1.7-5.8h17.2
            C1006.4,350.6,1006.3,348.8,1006.1,347.7z M989.3,349.8c0.1-2,0.7-3.7,1.9-5c1.2-1.3,2.7-2,4.6-2c2.5,0,4.3,1,5.4,2.9
            c0.6,1,1,2.4,1.1,4.1H989.3z M972.2,363.5h4V340h-4V363.5z M731.2,351.9c-1-0.6-2.9-1.3-5.9-2l-2.7-0.6c-1.2-0.3-2.1-0.6-2.7-1
            c-1-0.6-1.6-1.4-1.6-2.5c0-0.9,0.4-1.7,1.2-2.3s2-0.9,3.5-0.9c2.3,0,3.9,0.5,4.9,1.6c0.6,0.7,0.9,1.5,1,2.4h3.8c0-1.5-0.5-3-1.5-4.3
            c-1.6-2.1-4.2-3.1-7.8-3.1c-2.8,0-5,0.7-6.6,2.1c-1.6,1.4-2.5,3.2-2.5,5.4c0,1.9,0.9,3.4,2.6,4.5c1,0.6,2.5,1.2,4.5,1.7l3.2,0.8
            c1.7,0.4,2.9,0.8,3.5,1.1c0.9,0.5,1.4,1.3,1.4,2.3c0,1.3-0.6,2.3-1.7,3s-2.5,1-4,1c-2.5,0-4.3-0.6-5.3-1.9c-0.6-0.7-0.9-1.7-1-3
            h-3.8c0.1,2.4,0.9,4.3,2.4,5.9s4,2.4,7.6,2.4c3.3,0,5.8-0.8,7.4-2.3c1.6-1.5,2.4-3.3,2.4-5.5C733.5,354.5,732.8,352.9,731.2,351.9z"
        />
        <StyledPath
            d="M239.3,129.2C183.6,112.9,147,74.4,131.1,20.6c-2-6.9-9.4-6.6-11.1-0.1c-14,53.4-52.4,90.9-106.3,107.8
            c-10.2,3.2-10.1,9.2-0.5,12.2c54.9,17,89.2,54.7,105.8,105.9c3,9.2,9.4,9.3,11.9,0.5c14.5-52.4,51.9-89.4,107.5-106.8
            C246.7,137.6,247.3,131.5,239.3,129.2z M151.3,163.1c-17.6-9.2-35.4-9.3-51.6-0.2c-2.7,1.5-4.2,0-2.7-3c8.3-16.3,9.1-33.6,0-50.9
            c-1.6-3-0.2-4.5,3.1-2.8c17,8.9,35.3,9.1,51.5-0.4c2-1.2,3.8,0.5,2.6,2.7c-9.1,16.8-9.6,34.8-0.1,52.1
            C155.5,163.1,153.9,164.4,151.3,163.1z"
        />
    </StyledSvg>
);
