import React, { FC } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import styled from "styled-components";
import { ListingDetailField } from "src/types";
import { FormInput } from "src/ui/primitives/form";
import { mediaQuery } from "src/ui/styles";

const Input = styled(FormInput)`
    min-width: 100%;
    box-sizing: border-box;
    ${mediaQuery.verticalmedium(`
        min-width: 33.3%;
        &:last-child {
            max-width: 50%;
        }
    `)}
    ${mediaQuery.xlarge(`
        min-width: 20%;
        &:last-child {
            max-width: 33%;
        }
    `)}
`;

export const ListingDetailDateField: FC<{
    field: ListingDetailField,
    registerOptions: RegisterOptions,
    fieldLabel: string,
}> = ({
    field,
    registerOptions,
    fieldLabel,
}) => {
    const { register } = useFormContext();

    registerOptions.pattern = {
        value: /\d{4}-\d{2}-\d{2}/,
        message: `${fieldLabel} must be a valid date`
    };
    
    return (
        <Input
            type="date"
            label={fieldLabel}
            {...register(field.name, registerOptions)}
        />
    );
}
