import { FieldValues } from "react-hook-form";

type Values = Record<string, any>;

const isUnset = (value: any) =>
    !value && value !== 0;

export const pickDirtyFieldValues = (
    values: FieldValues,
    defaultValues: Values,
): Values => Object.entries(values).reduce(
    (dirtyValues, [fieldName, value]) => {
        if (isUnset(defaultValues[fieldName]) && isUnset(value)) {
            // if they're both some form of falsy, don't send.
            return dirtyValues;
        }
        if (defaultValues[fieldName] !== value) {
            dirtyValues[fieldName] = values[fieldName];
        }
        return dirtyValues;
    },
    {} as Values
);
