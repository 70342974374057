import React, { FC, useMemo } from "react";
import { generatePath, Link } from "react-router-dom";
import { Listing, ListingStatus } from "src/types";
import { Flex, H3, IconGrid, IconGridItem } from "src/ui/primitives";
import { ConstrainedImg } from "src/ui/primitives/ConstrainedImg";
import { cssVar, mediaQuery } from "src/ui/styles";
import styled from "styled-components";
import { defaultPhotoUrl } from "./useListingPhotoUrls";
import { useActivePermissions } from "src/ui/roles";

const Wrap = styled.div<{ $gap?: string }>`
    box-sizing: border-box;
    position: relative;
    width: ${props => props.$gap ? `calc(100% - ${props.$gap})` : '100%'};
    background-color: white;
    border: 1px solid #ccc;
    ${props => mediaQuery.verticalmedium(`
        width: ${props.$gap ? `calc(50% - ${props.$gap})` : '50%'};
    `)}
    ${props => mediaQuery.xlarge(`
        width: ${props.$gap ? `calc(25% - ${props.$gap})` : '25%'};
    `)}
    ${props => mediaQuery.xxlarge(`
        width: ${props.$gap ? `calc(16.6666% - ${props.$gap})` : '16.6666%'};
    `)}
`;
const Content = styled.div`
    padding: .5rem;
    overflow: hidden;
    white-space: nowrap;
    max-width: 100%;
    > * {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
`;
const PendingFlag = styled.div`
    position: absolute;
    top: .5rem;
    left: -5px;
    background-color: var(${cssVar.transparentthick});
    color: var(${cssVar.lightshade});
    font-size: .8rem;
    padding: .2rem .8rem .2rem .5rem;
    border-radius: 0 1rem 1rem 0;
`;

const useListingTitle = (listing: Listing) => useMemo(() => {
    const address = [
        listing.address1,
        listing.city,
        listing.region,
    ].filter(n => n).join(', ');

    return address || listing.title;
}, [listing]);

export const ListingCard: FC<{
    listing: Listing,
    route: string,
    gap?: string,
}> = ({
    listing,
    route,
    gap,
}) => {
    const title = useListingTitle(listing);
    const permissions = useActivePermissions();

    return (
        <Wrap $gap={gap}>
            <Link to={generatePath(route, {
                listingId: `${listing.id}`,
            })}>
                <ConstrainedImg
                    src={listing.cover_photo ? listing.cover_photo.url : defaultPhotoUrl}
                    height="15rem"
                    alt={`Cover Photo for listing "${listing.title}"`}
                />
            </Link>
            {permissions.viewDateOnListing && (
                <Flex $justify="space-between">
                    <div>
                        Start: {listing.start_date ?(
                            new Date(listing.start_date).toLocaleDateString()
                        ) : 'Not set'}
                    </div>
                    <div>
                        End: {listing.end_date ?(
                            new Date(listing.end_date).toLocaleDateString()
                        ) : 'Not set'}
                    </div>
                </Flex>
            )}
            <Content>
                <Link to={generatePath(route, {
                    listingId: `${listing.id}`,
                })}>
                    <H3 title={title}>
                        <div>{listing.address1}</div>
                        <div>{listing.city}, {listing.region}</div>
                    </H3>
                </Link>
                <IconGrid $small>
                    <IconGridItem
                        icon="bed"
                        title="Bedrooms"
                        fields={listing}
                        name="bedrooms"
                    />
                    <IconGridItem
                        icon="bath"
                        title="Bathrooms"
                        fields={listing}
                        name="bathrooms_full"
                    />
                    <IconGridItem
                        icon="ruler"
                        title="Living Area"
                        fields={listing}
                        name="living_area"
                    />
                    <IconGridItem
                        icon="tree"
                        title="Lot Size"
                        fields={listing}
                        name="lot_size"
                    />
                </IconGrid>
                Listing by: {listing.seller_name}
                {listing.status === ListingStatus.pending && (
                    <PendingFlag>Pending</PendingFlag>
                )}
            </Content>
        </Wrap>
    );
}
