import React, { FC, useCallback, useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { StepNavigation } from "./StepNavigation";
import {
    Button,
    H1,
    Loading,
    MultiSelect,
    MultiselectOption,
    PagePadding,
} from "src/ui/primitives";
import { useFetch, useFetchAllPages } from "src/api";
import { ListingOptionChoice } from "src/types";
import { FieldError, FieldErrorScrollOnMount, Form } from "src/ui/primitives/form";
import { useParams } from "react-router-dom";

const useTypeChoiceOptions = (
    type: string,
    allChoices: ListingOptionChoice[] | undefined
): MultiselectOption[] =>
    useMemo(() => {
        if (!allChoices)
            return [];
        
        return allChoices
            .filter(choice => choice.type === type)
            .map(option => ({
                label: option.name,
                value: option.id,
            }));
    }, [type, allChoices]);

export const choiceTypeToLabel: Record<string, string> = {
    "category": "Categories",
    "appliance": "Appliances",
    "architecturalStyle": "Architectural Styles",
    "coolingSystem": "Cooling Systems",
    "exteriorType": "Exterior Types",
    "floorCovering": "Floor Coverings",
    "heatingFuel": "Heating Fuels",
    "heatingSystem": "Heating Systems",
    "propertyType": "Property Type",
    "roofType": "Roof Types",
    "viewType": "View Types",
};

const ChoiceField: FC<{
    name: string;
    placeholder: string;
    allOptions: ListingOptionChoice[];
    allChoices: ListingOptionChoice[];
    makeSetter: (name: string) => (selected: MultiselectOption[]) => void;
}> = ({
    name,
    placeholder,
    allOptions,
    allChoices,
    makeSetter,
}) => {
    const label = choiceTypeToLabel[name];
    const options = useTypeChoiceOptions(name, allOptions);
    const selected = useTypeChoiceOptions(name, allChoices);

    return (
        <MultiSelect
            label={label}
            placeholder={placeholder}
            options={options}
            onChange={makeSetter(name)}
            selectedValues={selected}
        />
    );
};

const optionChoiceToFormState = (
    allChoices: ListingOptionChoice[] | undefined
): Record<string, MultiselectOption[]> => {
    const state: Record<string, MultiselectOption[]> = {};
    
    (allChoices || []).forEach(choice => {
        if (!state[choice.type]) {
            state[choice.type] = [];
        }
        state[choice.type].push({
            label: choice.name,
            value: choice.id,
        });
    });

    return state;
};

const ListingChoicesForm: FC<{
    listingId: string;
    allOptions: ListingOptionChoice[];
    allChoices: ListingOptionChoice[] | undefined;
    updateChoices: (choices: ListingOptionChoice[]) => void;
}> = ({
    listingId,
    allOptions,
    allChoices,
    updateChoices,
}) => {
    const {
        fetcher: saveForm,
        loading: saving,
        error: saveError,
        status: saveStatus,
    } = useFetch();

    const form = useForm({
        defaultValues: optionChoiceToFormState(allChoices),
    });
    const {
        reset,
        formState: { isDirty },
        setValue,
    } = form;

    const submit = useCallback(async (values: FieldValues) => {
        const choices = new Set();

        Object.values(values)
            .forEach((options: MultiselectOption[]) => {
                options?.forEach((option: MultiselectOption) =>
                    choices.add(option.value)
                )
            });

        const { response, json } = await saveForm(
            `/api/listings/${listingId}/choices`,
            {
                method: 'PUT',
                body: JSON.stringify({
                    choices: Array.from(choices)
                }),
            }
        );
        if (response && response.status > 199 && response.status < 300) {
            updateChoices(json);
            reset(optionChoiceToFormState(json));
        }
    }, [saveForm, listingId, updateChoices, reset]);

    const makeSetter = (name: string) =>
        (selected: MultiselectOption[]) => {
            setValue(
                name,
                selected,
                { shouldDirty: true }
            );
        };


    return <>
        <StepNavigation />
        <Form
            onSubmit={submit}
            footer={
                <Button
                    type="submit"
                    disabled={!isDirty || saving}
                >
                    {isDirty ? 'Save Changes' : 'Changes Saved'}
                </Button>
            }
            {...form}
        >
            <H1>Listing Choices</H1>
            {(saveError || (saveStatus && saveStatus !== 200)) && (
                <FieldErrorScrollOnMount>
                    Something went wrong, unable to save.
                </FieldErrorScrollOnMount>
            )}
            <ChoiceField
                name="category"
                placeholder="Select categories..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="appliance"
                placeholder="Select appliances..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="architecturalStyle"
                placeholder="Select architectural styles..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="coolingSystem"
                placeholder="Select cooling systems..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="exteriorType"
                placeholder="Select exterior types..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="floorCovering"
                placeholder="Select floor coverings..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="heatingFuel"
                placeholder="Select heating fuels..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="heatingSystem"
                placeholder="Select heating systems..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="propertyType"
                placeholder="Select property type..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="roofType"
                placeholder="Select roof types..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
            <ChoiceField
                name="viewType"
                placeholder="Select view types..."
                allOptions={allOptions}
                allChoices={allChoices || []}
                makeSetter={makeSetter}
            />
        </Form>
    </>;
}

export const ListingChoices: FC = () => {
    const { listingId } = useParams();
    const {
        loading: loadingOptions,
        error: errorOptions,
        data: allOptions,
    } = useFetchAllPages<ListingOptionChoice>('/api/listings/choice/options');
    const {
        loading: loadingChoices,
        error: errorChoices,
        status: choiceStatus,
        data: allChoices,
        replaceData: replaceChoiceData,
    } = useFetch<ListingOptionChoice[]>(`/api/listings/${listingId}/choices`);

    if (loadingOptions || loadingChoices)
        return <>
            <StepNavigation />
            <Loading />
        </>;

    if (errorOptions || !allOptions || errorChoices || choiceStatus !== 200) {
        return <>
            <StepNavigation />
            <PagePadding>
                <FieldError>
                    An error occurred, unable to load listing options.
                </FieldError>
            </PagePadding>
        </>;
    }

    return (
        <ListingChoicesForm
            listingId={`${listingId}`}
            allOptions={allOptions}
            allChoices={allChoices}
            updateChoices={replaceChoiceData}
        />
    )
}