import React, { FC } from "react";
import {
    Flex,
    H1,
    Loading,
    PagePadding,
    Pagination,
} from "src/ui/primitives";
import { ListingCard } from "./ListingCard";
import styled from "styled-components";
import { AppRoute } from "src/ui/routes";
import {
    FilterPanel,
    useFilterListingFetch,
    useFilterStateParams,
} from "./Filter";
import { Icon } from "src/ui/primitives/Icon";

const gap = '.5rem';
const ListingsWrap = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 1rem -${gap} 1rem 0;
    gap: ${gap};
`;

export const Listings: FC<{
    publicPage?: boolean;
}> = ({ publicPage }) => {
    const filterState = useFilterStateParams();
    const {
        loading,
        error,
        items,
        pageData,
    } = useFilterListingFetch(filterState);

    return (
        <PagePadding>
            {!publicPage && <H1>Listings</H1>}
            <FilterPanel state={filterState} />
            {loading ? (
                <Loading />
            ) : error ? (
                'Unable to load listings'
            ) : (
                <>
                    {pageData && !publicPage && <Pagination pageData={pageData} />}
                    <ListingsWrap>
                        {items && items.length ? (
                            items.map(item => (
                                <ListingCard
                                    listing={item}
                                    key={item.id}
                                    gap={gap}
                                    route={publicPage
                                        ? AppRoute.publicListingById
                                        : AppRoute.listingById
                                    }
                                />
                            )
                        )) : (
                            <Flex
                                $justify="center"
                                $align="center"
                                $direction="column"
                                $flex="1"
                                $gap="2rem"
                                $pad="2rem 0 2rem 0"
                            >
                                <Icon
                                    name="magnifyingMinus"
                                    size="5x"
                                    color="#ccc"
                                />
                                No listings with these filters
                            </Flex>
                        )}
                    </ListingsWrap>
                    {pageData && <Pagination pageData={pageData} />}
                </>
            )}
        </PagePadding>
    );
};
