import { useCallback, useEffect, useState } from "react";

// so we can avoid prompting for navigation
// by waiting until the form is not dirty.
// you should call "formApi.reset" right
// before invoking this.
export const useWhenClean = (
    isDirty: boolean,
) => {
    const [whenClean, setWhenClean] = useState<(() => void)[]>([]);

    useEffect(() => {
        if (whenClean.length && !isDirty) {
            whenClean.forEach((fn) => fn());
            setWhenClean([]);
        }
    }, [isDirty, whenClean]);

    return useCallback(
        (whenClean: () => void) => setWhenClean(wc => [
            ...wc,
            whenClean,
        ]),
        []
    );
};
