import { Permission } from "./permissions"

export enum Role {
    admin = 'admin',
    sellerEnroller = 'sellerEnroller',
    seller = 'seller',
    buyerEnroller = 'buyerEnroller',
    buyer = 'buyer',
    sellerOrgAdmin = 'sellerOrgAdmin',
};

export const RolePermissions: Record<Role, Permission[]> = {
    [Role.admin]: [
        Permission.dashboard,
        Permission.createUserAnyRole,
        Permission.editAnyListing,
        Permission.viewDashAdmin,
        Permission.postListing,
        Permission.inviteUserBuyer,
        Permission.inviteUserSeller,
        Permission.viewDateOnListing,
    ],
    [Role.sellerEnroller]: [
        Permission.dashboard,
        Permission.inviteUserSeller,
    ],
    [Role.seller]: [
        Permission.dashboard,
        Permission.postListing,
        Permission.archiveListing,
        Permission.manageOffers,
    ],
    [Role.buyerEnroller]: [
        Permission.dashboard,
        Permission.inviteUserBuyer,
    ],
    [Role.buyer]: [
        Permission.dashboard,
        Permission.makeOffer,
        Permission.rescindOffer,
    ],
    [Role.sellerOrgAdmin]: [
        Permission.dashboard,
        Permission.inviteUserSeller,
        Permission.sellerOrganizationReports,
    ],
};
