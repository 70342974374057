import React, {
    FC,
    PropsWithChildren,
    forwardRef,
    useCallback,
    useEffect,
    useState,
} from "react";
import styled from "styled-components";
import SortableList, { SortableItem, SortableKnob } from 'react-easy-sort';
import { Button, Flex, H1, LightboxSimple, Loading, PagePadding, Paragraph } from "src/ui/primitives";
import { StepNavigation } from "./StepNavigation";
import {
    FieldValues,
    useFieldArray,
    useForm,
    useFormContext,
} from "react-hook-form";
import { FieldError, FieldErrorScrollOnMount, Form, FormInput, uriRegexp } from "src/ui/primitives/form";
import { assignRefs } from "src/util";
import { mediaQuery } from "src/ui/styles";
import { useFetch } from "src/api";
import { useParams, useSearchParams } from "react-router-dom";
import { ListingPhoto } from "src/types";

const StyledSortableList = styled(SortableList)`
    display: flex;
    flex-direction: column;
    gap: .5rem;
`;
const Row = styled.div`
    display: flex;
    flex-direction: column;
    padding: .5rem;
    align-items: stretch;
    ${mediaQuery.verticalmedium(`
        flex-direction: row;
        padding: 0 .5rem 0 0;
        align-items: center;
    `)}
    gap: .5rem;
    background: #ddd;
    border: 1px solid #aaa;
    border-radius: 1rem;
`;
const Dragger = styled.div`
    display: none;
    ${mediaQuery.verticalmedium(`
        display: flex;
    `)}
    min-width: 2rem;
    min-height: 2.5rem;
    align-items: center;
    justify-content: center;
    user-select: none;
    ::after {
        content: '≡';
        font-size: 2rem;
    }
`;
const SmallPreview = styled.button<{ $src: string, $fade: boolean, }>`
    width: 100px;
    min-width: 100px;
    max-width: 100px;
    height: 100px;
    min-height: 100px;
    max-height: 100px;
    background: none;
    border: none;
    position: relative;
    cursor: pointer;
    margin: 0 auto;
    ${props => props.$fade ? 'opacity: .5;' : ''}
    ::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        background-image: url(${props => props.$src });
        background-size: contain;
        background-repeat: no-repeat;
        background-position: center center;
    }
`;
const DirectionArrow = styled(Button)<{ $hidden: boolean; }>`
    display: block;
    ${mediaQuery.verticalmedium(`
        display: none;
        visibility: hidden;
    `)}
    ${props => props.$hidden && `
        display: none;
        visibility: hidden;
    `}
`;
const RemovedControl = styled.div`
    display: flex;
    flex: 1;
    > label {
        display: flex;
        color: white;
        align-items: center;
        gap: .5rem;
    }
`;

const PhotoField: FC<PropsWithChildren<{
    index: number;
    isLast: boolean;
    move: (oldIndex: number, newIndex: number) => void;
    remove: (index: number) => void;
    disabled: boolean;
}>> = forwardRef(
    (
        {
            children,
            index,
            isLast,
            move,
            remove,
            disabled,
        },
        ref
    ) => {
        const { register, watch, setValue } = useFormContext();
        const [showPreview, setShowPreview] = useState<boolean>(false);
        const [showLightbox, setShowLightbox] = useState<boolean>(false);

        const fieldName = `photos.${index}`;
        const field = watch(fieldName) || {};

        const handleRemove = useCallback(
            () => {
                if (!field.id) {
                    remove(index);
                } else {
                    setValue(
                        `${fieldName}.status`,
                        0,
                        { shouldDirty: true }
                    );
                }
            },
            [fieldName, setValue, field.id, remove, index]
        );
        const undoRemove = useCallback(
            () => setValue(
                `${fieldName}.status`,
                1,
                { shouldDirty: true }
            ),
            [fieldName, setValue]
        );
        const selectErrors = useCallback(
            (errors: any) => errors?.photos?.[index]?.url,
            [index]
        );
        const openLightbox = useCallback(
            () => setShowLightbox(true),
            []
        );
        const closeLightbox = useCallback(
            () => setShowLightbox(false),
            []
        );
        const moveUp = useCallback(
            () => move(index, index - 1),
            [move, index]
        );
        const moveDown = useCallback(
            () => move(index, index + 1),
            [move, index]
        );

        useEffect(() => {
            setShowPreview(false);
            if (field?.url?.match(uriRegexp)) {
                const img = new Image();
                img.onload = () => setShowPreview(true);
                img.src = field.url;
            }
        }, [field?.url]);

        return (
            <Row ref={assignRefs(ref)}>
                {children}
                <FormInput
                    placeholder="Photo URL"
                    selectErrors={selectErrors}
                    {...register(`${fieldName}.url`, {
                        required: "Please enter a URL or remove this photo",
                        pattern: {
                            value: uriRegexp,
                            message: "Please enter a valid email",
                        },
                        disabled,
                    })}
                    readOnly={field.status === 0}
                />
                {showPreview && (
                    <SmallPreview
                        type="button"
                        $src={field.url}
                        $fade={field.status === 0}
                        onClick={openLightbox}
                    />
                )}
                {showLightbox && (
                    <LightboxSimple
                        image={field.url}
                        onClose={closeLightbox}
                    />
                )}
                <Flex $direction="row" $justify="center">
                    <DirectionArrow
                        type="button"
                        onClick={moveUp}
                        $hidden={index === 0}
                        disabled={disabled}
                    >⬆</DirectionArrow>
                    {field.status === 0 ? (
                        <Button
                            type="button"
                            onClick={undoRemove}
                            disabled={disabled}
                        >
                            Undo Remove
                        </Button>
                    ) : (
                        <Button
                            type="button"
                            onClick={handleRemove}
                            disabled={disabled}
                        >
                            Remove
                        </Button>
                    )}
                    <DirectionArrow
                        type="button"
                        onClick={moveDown}
                        $hidden={isLast}
                        disabled={disabled}
                    >⬇</DirectionArrow>
                </Flex>
            </Row>
        );
    }
);

export const ListingPhotosForm: FC = () => {
    const { listingId } = useParams();
    const [searchParams, setSearchParams] = useSearchParams();
    const form = useForm();

    const {
        control,
        formState: { isDirty },
        reset,
    } = form;

    const showRemoved = !!searchParams.get('removed');
    const toggleRemoved = useCallback(() => {
        setSearchParams(showRemoved ? undefined : { removed: '1' });
    }, [setSearchParams, showRemoved]);
    const removedApiParam = (showRemoved ? '?showRemoved=1' : '')

    const {
        loading,
        data: photoData,
        error: loadError,
        status: loadStatus,
        replaceData: replacePhotoData,
    } = useFetch<ListingPhoto[]>(`/api/listings/${listingId}/photos` + removedApiParam);

    const {
        fetcher: saveForm,
        loading: saving,
        error: saveError,
        status: saveStatus,
    } = useFetch();

    const submit = useCallback(async (values: FieldValues) => {
        const { response, json } = await saveForm(
            `/api/listings/${listingId}/photos` + removedApiParam,
            {
                method: 'PUT',
                body: JSON.stringify(values),
            }
        );
        if (response && response.status > 199 && response.status < 300) {
            replacePhotoData(json);
        }
    }, [saveForm, listingId, replacePhotoData, removedApiParam]);

    const {
        fields,
        append,
        move,
        remove,
    } = useFieldArray({
        control,
        name: "photos",
    });

    const add = useCallback(() => {
        append({});
    }, [append]);
    const onSortEnd = useCallback((oldIndex: number, newIndex: number) => {
        move(oldIndex, newIndex);
    }, [move]);

    useEffect(() => {
        if (loadStatus === 200 && photoData) {
            reset({ photos: photoData });
        }
    }, [reset, photoData, loadStatus])

    const loadingOrFailed = loading || loadError || loadStatus !== 200;

    return <>
        <StepNavigation />
        <Form
            onSubmit={submit}
            footer={
                <>
                    <RemovedControl>
                        <label>
                            <input
                                type="checkbox"
                                checked={showRemoved}
                                onChange={toggleRemoved}
                                disabled={saving || loadingOrFailed}
                            />
                            Show removed?
                        </label>
                    </RemovedControl>
                    <Button
                        type="button"
                        onClick={add}
                        disabled={saving || loadingOrFailed}
                    >
                        Add Image by URL
                    </Button>
                    <Button
                        type="submit"
                        disabled={!isDirty || saving || loadingOrFailed}
                    >
                        {isDirty ? 'Save Changes' : 'Changes saved'}
                    </Button>
                </>
            }
            {...form}
        >
            <H1>Listing Photos</H1>
            {loading ? (
                <Loading />
            ) : loadError || loadStatus !== 200 ? (
                <PagePadding>
                    <FieldError>
                        An error occurred, unable to load listing photos.
                    </FieldError>
                </PagePadding>
            ) : <>
                {(saveError || (saveStatus && saveStatus !== 200)) && (
                    <FieldErrorScrollOnMount>
                        Something went wrong, unable to save.
                    </FieldErrorScrollOnMount>
                )}
                {!fields.length && (
                    <Flex $direction="column" $align="center">
                        <Paragraph>Currently no photos</Paragraph>
                        <Button type="button" onClick={add}>
                            Add an Image URL
                        </Button>
                    </Flex>
                )}
                <StyledSortableList
                    onSortEnd={onSortEnd}
                    lockAxis='y'
                    allowDrag={true}
                >
                    {fields.map((field, index) =>
                        <SortableItem key={field.id}>
                            <PhotoField
                                index={index}
                                isLast={index === (fields.length - 1)}
                                move={move}
                                disabled={saving}
                                remove={remove}
                            >
                                <SortableKnob>
                                    <Dragger />
                                </SortableKnob>
                            </PhotoField>
                        </SortableItem>
                    )}
                </StyledSortableList>
            </>}
        </Form>
    </>;
}