import React, { FC, useMemo } from "react";
import styled from "styled-components";
import { generatePath, useLocation, useSearchParams } from "react-router-dom";
import { useFetchPaginate } from "src/api";
import { User } from "src/types";
import { cssVar } from "src/ui/styles";
import {
    H1,
    SearchByParam,
    Table,
    TableRow,
    EditRowLink,
    LinkButton,
    PagePadding,
} from "src/ui/primitives";
import { roleToDisplayText } from "src/ui/roles";
import { AppRoute } from "src/ui/routes";

const InactiveRow = styled(TableRow)`
    color: var(${cssVar.lightaccent});
    &:nth-child(even) {
        color: var(${cssVar.darkaccent});
    }
`;

export const AllUsers: FC = () => {
    const [pageParams] = useSearchParams();
    const pageSearch = pageParams.get('search');

    const apiParams = useMemo(() =>
        pageSearch
            ? new URLSearchParams({ search: pageSearch })
            : undefined,
        [pageSearch]
    );

    const {
        loading,
        error,
        items,
        pageData,
    } = useFetchPaginate<User>('/api/users', apiParams);

    const location = useLocation();
    const backUrl = useMemo(
        () => encodeURIComponent(`${location.pathname}${location.search}`),
        [location.pathname, location.search]
    );

    return (
        <PagePadding>
            <H1>All Users</H1>
            <LinkButton to={AppRoute.createUser + '?back=' + backUrl}>
                Create User
            </LinkButton>
            <SearchByParam />
            {error ? 'Unable to load info' : (
                <Table<User>
                    loading={loading}
                    columns={[
                        {
                            dataKey: 'name',
                            header: 'Name',
                        },
                        {
                            dataKey: 'email',
                            header: 'Email',
                        },
                        {
                            dataKey: 'role',
                            header: 'Role',
                            renderCell: (user) =>
                                roleToDisplayText[user.role]
                        },
                        {
                            dataKey: 'status',
                            header: 'Status',
                            renderCell: (user) =>
                                user.status === 1 ? 'Active' : 'Inactive',
                        },
                        {
                            dataKey: 'id',
                            header: '',
                            renderCell: (user) =>
                                <EditRowLink to={generatePath(AppRoute.editUser, {
                                    userId: `${user.id}`,
                                }) + '?back=' + backUrl} />
                        },
                    ]}
                    items={items}
                    pageData={pageData}
                    pickRowComponent={(user, rowIndex) =>
                        user.status === 0
                            ? InactiveRow
                            : undefined
                    }
                />
            )}
        </PagePadding>
    );
};
