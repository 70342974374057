import React, { FC } from 'react';
import styled from "styled-components";
import {
  createBrowserRouter,
  RouterProvider,
  createRoutesFromElements,
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";
import { UserProvider } from 'src/ui/user';
import {
  Dashboard,
  Login,
  Logout,
} from 'src/ui/pages';
import { AppRoute, routeToComponentMap } from 'src/ui/routes';
import {
  MainMenu,
  MenuHeaderBar,
  useMainMenu,
  useRoutesWithPermission,
} from 'src/ui/navigation';
import {
  CacheProvider,
  TokenProvider,
  useRedirectWithoutToken,
} from 'src/api';
import { GlobalStyle } from 'src/ui/styles';
import { Homepage } from './ui/pages/public';

const Layout = styled.div`
  display: flex;
  flex-direction: row;
  flex: 1;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  min-width: 100vw;
  max-width: 100vw;
  overflow: hidden;
`;
const ContentWrap = styled.div`
  width: 100%;
  max-width: 100vw;
  box-sizing: border-box;
  overflow: auto;
  display: flex;
  flex-direction: column;
  flex: 1
`;

const LoggedInWrap: FC = () => {
  const hasToken = useRedirectWithoutToken();
  const mainMenuApi = useMainMenu();

  if (!hasToken) {
    return null;
  }

  return (
    <MenuHeaderBar {...mainMenuApi}>
      <MainMenu {...mainMenuApi} />
      <ContentWrap>
        <Outlet />
      </ContentWrap>
    </MenuHeaderBar>
  );
}

const ApplicationRoutes: FC = () => {
  const permissibleRoutes = useRoutesWithPermission();

  return (
    <Routes>
      <Route path={AppRoute.homepage} element={<Homepage />} />
      <Route path={AppRoute.publicListingById + '/*'} element={<Homepage />} />
      <Route path={AppRoute.login} element={<Login />} />
      <Route path={AppRoute.logout} element={<Logout />} />
      <Route path={AppRoute.dashboard} element={<LoggedInWrap />}>
        <Route index element={<Dashboard />} />
        {permissibleRoutes.map(path => (
          routeToComponentMap[path as AppRoute]
            ? <Route
                key={path}
                path={path + "/*"}
                element={routeToComponentMap[path as AppRoute]}
              />
            : null
        ))}
        <Route path="*" element={
          // any unknown routepath will redirect to the dashboard
          <Navigate to={AppRoute.dashboard} replace={true} />
        } />
      </Route>
      <Route path="*" element={<div>Route not found</div>} />
    </Routes>
  );
};

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path='/*'
      element={<ApplicationRoutes />}
    />
  )
);

const App: FC = () => (
  <TokenProvider>
    <CacheProvider>
      <Layout>
        <GlobalStyle />
        <UserProvider>
          <RouterProvider router={router} />
        </UserProvider>
      </Layout>
    </CacheProvider>
  </TokenProvider>
);

export default App;
