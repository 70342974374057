import { getStoredApiToken } from "./storage";

const apiBase = process.env.REACT_APP_API_BASE || 'https://api.worldwideinvestments.com';

export const fetchApi = async (
    url: string,
    options: RequestInit = {},
) => {
    const token = getStoredApiToken();

    return await fetch(
        apiBase + url,
        {
            mode: "cors",
            credentials: "include",
            ...options,
            headers: {
                // 'X-XSRF-TOKEN': getCookie('XSRF-TOKEN'),
                'Accept': 'application/json',
                ...(token ? { 'Authorization': `Bearer ${token}` } : {}),
                ...options.headers,
            } as HeadersInit,
        }
    );
};
