import styled from "styled-components";
import { cssVar } from "src/ui/styles";
import { ComponentProps, FC, useEffect, useRef } from "react";

export const FieldError = styled.div`
    background-color: var(${cssVar.accent});
    color: var(${cssVar.lightshade});
    border: 2px dashed var(${cssVar.darkshade});
    padding: .25rem .5rem;
    margin: .25rem 0;
    font-style: italic;
`;

export const ScrollOnMount: FC = () => {
    const ref = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (ref.current)
            ref.current.scrollIntoView();
    }, []);

    return <div ref={ref} />
};

export const FieldErrorScrollOnMount: FC<ComponentProps<typeof FieldError>> = props => <>
    <ScrollOnMount />
    <FieldError {...props} />
</>