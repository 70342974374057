import React, { ReactNode } from "react";
import {
    AllUsers,
    // EditProfile,
    ListingById,
    Listings,
    ManageOffers,
    MyOffers,
    // BuyersList,
    // InviteBuyer,
    // ViewProfile,
    // SellersList,
    // InviteSeller,
    ApiQuery,
    EditUserForm,
    UserForm,
    ListingForm,
    EditListing,
    ListingPhotosForm,
    ListingDetailsForm,
    ListingChoices,
  } from 'src/ui/pages';
import { AppRoute } from "./AppRoutes";

export const routeToComponentMap: Partial<Record<AppRoute, ReactNode>> = {
    [AppRoute.allUsers]: <AllUsers />,
    // [AppRoute.editProfile]: <EditProfile />,
    [AppRoute.listings]: <Listings />,
    [AppRoute.listingById]: <ListingById />,
    [AppRoute.postListing]: <ListingForm />,
    [AppRoute.editListing]: <EditListing />,
    [AppRoute.editListingPhotos]: <ListingPhotosForm />,
    [AppRoute.editListingDetails]: <ListingDetailsForm />,
    [AppRoute.editListingChoices]: <ListingChoices />,
    [AppRoute.manageOffers]: <ManageOffers />,
    [AppRoute.myOffers]: <MyOffers />,
    // [AppRoute.buyerList]: <BuyersList />,
    // [AppRoute.inviteBuyer]: <InviteBuyer />,
    // [AppRoute.buyerProfile]: <ViewProfile />,
    // [AppRoute.sellerList]: <SellersList />,
    // [AppRoute.sellerProfile]: <ViewProfile />,
    // [AppRoute.inviteSeller]: <InviteSeller />,
    [AppRoute.apiQuery]: <ApiQuery />,
    [AppRoute.editUser]: <EditUserForm />,
    [AppRoute.createUser]: <UserForm />,
  };
  