export enum Permission {
    dashboard = 'dashboard',
    createUserAnyRole = 'createUserAnyRole',
    inviteUserSeller = 'inviteUserSeller',
    editAnyListing = 'editAnyListing',
    inviteUserBuyer = 'inviteUserBuyer',
    viewDashAdmin = 'viewDashAdmin',
    postListing = 'postListing',
    archiveListing = 'archiveListing',
    manageOffers = 'manageOffers',
    makeOffer = 'makeOffer',
    rescindOffer = 'rescindOffer',
    sellerOrganizationReports = 'sellerOrganizationReports',

    // FRONTEND ONLY PERMISSION
    viewDateOnListing = 'viewDateOnListing',
};
