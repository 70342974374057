import React, { FC, useCallback, useMemo } from "react";
import { FieldValues, useForm } from "react-hook-form";
import { generatePath, useNavigate, useParams } from "react-router-dom";
import { useCache, useFetch } from "src/api";
import { Listing, ListingStatus } from "src/types";
import { AddressInputs } from "src/ui/address";
import {
    Button,
    Flex,
    H1,
    Loading,
} from "src/ui/primitives";
import {
    FieldError,
    Form,
    pickDirtyFieldValues,
    FormInput,
    InputOption,
    useWhenClean,
    FieldErrorScrollOnMount,
} from "src/ui/primitives/form";
import { AppRoute } from "src/ui/routes";
import { StepNavigation } from "./StepNavigation";

export const EditListing: FC = () => {
    const { listingId } = useParams();
    const {
        loading,
        error,
        data,
        status,
        replaceData,
    } = useFetch<Listing>('/api/listings/' + listingId);

    if (loading)
        return <>
            <StepNavigation />
            <Loading />
        </>;

    if (error || status !== 200 || !data)
        return (
            <FieldError>
                An error occurred, unable to load listing.
            </FieldError>
        );

    return (
        <ListingForm
            listing={data}
            replaceData={replaceData}
        />
    );
}

export const useFetchTimezoneOptions = () => {
    const { fetchers } = useCache();

    return useCallback(async () => {
        const timezones = await fetchers.timezones();

        return Object.entries(timezones).map(
            ([value, label]) => ({ value, label } as InputOption)
        );
    }, [fetchers]);
};

const maxPrice = 9999999999;

const statusChoices: InputOption[] = Object.entries(ListingStatus)
    .filter(([label]) => isNaN(parseInt(label)))
    .map(([label, value]) => ({
        label: label.charAt(0).toUpperCase() + label.slice(1),
        value: `${value}`,
    }));

export const ListingForm: FC<{
    listing?: Listing;
    replaceData?: (listing: Listing | undefined) => void;
}> = ({
    listing,
    replaceData,
}) => {
    const form = useForm({
        defaultValues: listing,
    });
    const {
        register,
        reset,
        formState: { isDirty },
    } = form;
    const {
        fetcher,
        loading,
        error,
        status,
    } = useFetch();
    const navigate = useNavigate();
    const whenClean = useWhenClean(isDirty);
    const submit = useCallback(async (values: FieldValues) => {
        const dirtyValues = listing
            ? pickDirtyFieldValues(values, listing)
            : values;

        const { response, json } = await fetcher(
            '/api/listings/' + (listing ? listing.id : ''),
            {
                method: listing ? 'PUT' : 'POST',
                body: JSON.stringify(dirtyValues),
            }
        );

        if (response && response.status > 199 && response.status < 300) {
            if (!listing) {
                reset(json); // make the form not dirty
                whenClean(() => {
                    navigate(
                        generatePath(AppRoute.editListing, {
                            listingId: json.id
                        }),
                        { replace: true }
                    );
                    navigate(
                        generatePath(AppRoute.editListingDetails, {
                            listingId: json.id
                        })
                    );
                })
            } else {
                if (replaceData) {
                    // if we have replaceData method, do it so logic in the JSX remains consistent
                    replaceData(json);
                    // also reset the form so it is not dirty
                    reset(json);
                } else {
                    reset(json);
                    whenClean(() => {
                        // otherwise, forcibly refresh the page (although in practice this shouldn't happen)
                        navigate(0);
                    });
                }
            }
        }
    }, [fetcher, navigate, whenClean, listing, replaceData, reset]);
    const defaultTimezone = useMemo(
        () => Intl.DateTimeFormat().resolvedOptions().timeZone,
        []
    );
    const fetchTimezoneOptions = useFetchTimezoneOptions();

    return <>
        <StepNavigation />
        <Form
            onSubmit={submit}
            footer={
                <Button
                    type="submit"
                    disabled={loading || !isDirty}
                >
                    {!listing ? (
                        'Save & Next'
                    ) : isDirty ? (
                        'Save Changes'
                    ) : (
                        'Changes Saved'
                    )}
                </Button>
            }
            {...form}
        >
            {listing ? <H1>Edit Listing Basics</H1> : <H1>Create Listing</H1>}
            {(error || (status && (status < 200 || status > 299))) && (
                <FieldErrorScrollOnMount>
                    Something went wrong, unable to save listing.
                </FieldErrorScrollOnMount>
            )}
            <FormInput
                type="text"
                placeholder="Listing Title"
                label="Listing Title"
                {...register("title", {
                    required: "Title is required",
                    maxLength: {
                        value: 65000,
                        message: "Title is too long"
                    },
                    disabled: loading,
                })}
            />
            {listing && (
                <FormInput
                    options={statusChoices}
                    label="Listing Status"
                    {...register('status', {
                        disabled: loading,
                    })}
                />
            )}
            <Flex $gap=".5rem">
                <FormInput
                    type="number"
                    placeholder="Price"
                    label="Price"
                    step="0.01"
                    {...register("price", {
                        min: {
                            value: 1,
                            message: "Price must be higher than 0"
                        },
                        max: {
                            value: maxPrice,
                            message: `Price must be ${maxPrice.toLocaleString()} or lower`
                        },
                        disabled: loading,
                        valueAsNumber: true,
                    })}
                />
                <FormInput
                    type="number"
                    placeholder="Price Low"
                    label="Price Low"
                    step="0.01"
                    {...register("price_low", {
                        min: {
                            value: 1,
                            message: "Price Low must be higher than 0"
                        },
                        max: {
                            value: maxPrice,
                            message: `Price Low must be ${maxPrice.toLocaleString()} or lower`
                        },
                        disabled: loading,
                        valueAsNumber: true,
                    })}
                />
            </Flex>
            <Flex $gap=".5rem">
                <FormInput
                    type="text"
                    placeholder="Listing Key"
                    label="Listing Key"
                    {...register("listing_key", {
                        maxLength: {
                            value: 65000,
                            message: "Listing Key is too long"
                        },
                        disabled: loading,
                    })}
                />
                <FormInput
                    type="text"
                    placeholder="Listing ID"
                    label="Listing ID"
                    {...register("listing_id", {
                        maxLength: {
                            value: 65000,
                            message: "Listing ID is too long"
                        },
                        disabled: loading,
                    })}
                />
            </Flex>
            <FormInput
                loadOptions={fetchTimezoneOptions}
                label="Listing Timezone"
                defaultValue={defaultTimezone}
                {...register("timezone", {
                    required: "Timezone is required",
                    disabled: loading,
                })}
            />
            <AddressInputs
                legend="Listing Address"
                disabled={loading}
                required={true}
            />
        </Form>
    </>;
};
