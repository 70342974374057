import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { installEventContext } from "./installEventContext";
import { Button } from "../primitives";
import { cssVar } from "../styles";

const Wrap = styled.div`
    position: relative;
    background: var(${cssVar.darkaccent});
    margin: .5rem;
    border-radius: .5rem;
    padding: .3rem;
    color: var(${cssVar.lightshade});
    > p {
        margin: .1rem .1rem .3rem .1rem;
    }
`;
const DismissButton = styled.button`
    position: absolute;
    top: .2rem;
    right: .2rem;
    background: #ffffff20;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    padding: none;
    width: 1.5rem;
    height: 1.5rem;
    border-radius: 1rem;
`;
const StyledButton = styled(Button)`
    width: 100%;
`;

export const InstallPrompt: FC = () => {
    const [hasHadEvent, setHasHadEvent] = useState<boolean>(
        () => !!installEventContext.event
    );
    const [dismissed, setDismissed] = useState<boolean>(false);

    useEffect(() => {
        if (!hasHadEvent) {
            setTimeout(() => {
                setHasHadEvent(!!installEventContext.event);
            }, 30000);
        }
    }, [hasHadEvent]);

    const handleInstall = useCallback(async () => {
        if (!installEventContext.event)
            return;
        installEventContext.event.prompt();

        installEventContext.event = null;
        setDismissed(true);
    }, []);
    const handleDismiss = useCallback(() => setDismissed(true), []);

    if (!installEventContext.event || dismissed)
        return null;

    return (
        <Wrap>
            <DismissButton onClick={handleDismiss}>✕</DismissButton>
            <p>
                Install the Worldwide Investments app
            </p>
            <StyledButton type="button" onClick={handleInstall}>
                Install App
            </StyledButton>
        </Wrap>
    );
};
