import React, { FC } from "react";
import { useActivePermissions, useRole } from "src/ui/roles";
import { H1, Paragraph, PagePadding } from "src/ui/primitives";

export const Dashboard: FC = () => {
    const role = useRole();
    const permissions = useActivePermissions();

    return <PagePadding>
        <H1>Dashboard for {role}</H1>
        {permissions.createUserAnyRole && <>
            <Paragraph>
                Show any open invitations, count of all system users, system messages, etc.
            </Paragraph>
            <Paragraph>
                Saved searches.
            </Paragraph>
        </>}
        {permissions.postListing && <>
            <Paragraph>
                Quick view of open offers with links to the Manage Offers page.
            </Paragraph>
            <Paragraph>
                Views of your listings with links to Listings page.
            </Paragraph>
        </>}
        {permissions.makeOffer && <>
            <Paragraph>
                Quick view of your offers with links to the My Offers page.
            </Paragraph>
            <Paragraph>
                Recommended listings for you.
            </Paragraph>
            <Paragraph>
                Saved searches.
            </Paragraph>
        </>}
        {permissions.inviteUserSeller && <>
            <Paragraph>
                Show any open invitations, count of signed-up users, system messages, etc.
            </Paragraph>
        </>}
        {permissions.inviteUserBuyer && <>
            <Paragraph>
                Show any open invitations, count of signed-up users, system messages, etc.
            </Paragraph>
        </>}
        {permissions.sellerOrganizationReports && <>
            <Paragraph>
                Daily Tracking Data: Leads submitted - location, submitting agent, and price point, etc. Daily communications – review daily communications between acquisition team and agents. Leads closed All leads closed each day are tracked and can be sent to the internal system allowing the brokerage admin to search by agent and property information. Accepted terms (closing date) and closing agent contact information for each lead
            </Paragraph>
            <Paragraph>
                Agent Data: How many leads agent has submitted, How many leads have been accepted/denied, What percentage of leads were on vs. off-market, Review all communication between agent & buyer on each deal for training and response time analysis, Search by state to identify volume of leads and/or closed transactions, Search by non-accepted leads – get more information on who represented them, property info, acquisition team’s reason for passing, etc., Admin notification for non-answered agent questions
            </Paragraph>
        </>}
    </PagePadding>;
};
