const breakpoints = {
    // medium: 768,
    // large: 992,
    // xlarge: 1200,
    // medium: 480,
    // large: 768,
    // xlarge: 992,
    verticalmedium: 700,
    medium: 961,
    large: 1025,
    xlarge: 1281,
    xxlarge: 1400,
};
type Breakpoints = typeof breakpoints;
type BreakpointName = keyof Breakpoints;

export const mediaQuery = (Object.keys(breakpoints) as BreakpointName[]).reduce(
    (acc, description) => {
        acc[description] = (styles: string) => `
            @media (min-width: ${breakpoints[description]}px) {
                ${styles}
            }
        `;
        return acc;
    },
    {} as Record<BreakpointName, (styles: string) => string>
);
