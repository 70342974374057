import React, { FC, useCallback, useEffect, useState } from "react";
import styled from "styled-components";
import { useCombobox, useMultipleSelection } from "downshift";
import { Fieldset, Input } from "./form";
import { cssVar, mediaQuery } from "../styles";
import { Button } from "./Button";
import { ModalBackdrop } from "./ModalBackdrop";

const Relative = styled.div`
    position: relative;
    padding: 0;
`;
const Wrap = styled(Fieldset)<{ $open: boolean; }>`
    border-radius: ${props => props.$open ? '.5rem .5rem 0 0' : '.5rem'};
    ${props => props.$open ? 'border-bottom: none;' : ''}
    gap: 0;
    margin: 0;
`;
const OptionsList = styled.ul<{ $open: boolean; }>`
    background-color: #CCC;
    box-shadow: ${props => props.$open ? '0 10px 10px #000000AA' : ''};
    border: ${props => props.$open ? '1px solid #00000022' : ''};
    border-top: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    max-height: ${props => props.$open ? '70vh' : '0'};
    transition: max-height .3s, box-shadow .5s;
    overflow: auto;
    border-radius: 0 0 0 .5rem;
    box-sizing: border-box;
    list-style-type: none;
    margin: 0;
    padding: 0;
    z-index: 10;
`;
const StyledInput = styled(Input)`
    display: none;
    ${mediaQuery.medium(`
        display: block;
    `)}
`;
const MobileAdd = styled(Button)`
    ${mediaQuery.medium(`
        display: none;
    `)}
`;
const MobileOptionWindow = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 2000;
    display: flex;
    flex-direction: column;
    padding: .5rem;
    box-sizing: border-box;
    gap: .5rem;
    overflow: hidden;
`;
const MobileOptionList = styled.ul`
    list-style-type: none;
    flex: 1;
    background: #ccc;
    margin: 0;
    padding: 0;
    overflow: auto;
`;
const OptionItem = styled.li<{ $highlight: boolean; }>`
    padding: 1rem .5rem;
    ${props => props.$highlight
        ? `
            background: var(${cssVar.accent});
            color: white;
        `
        : ''
    }
`;
const SelectedOption = styled.div`
    padding: .5rem;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin: 0;
    :hover {
        background: #FFFFFF44;
    }
`;
const RemoveButton = styled.button`
    background: none;
    border: none;
    cursor: pointer;
`;

export type MultiselectOption = {
    label: string;
    value: string | number;
}

const filterOptions = (
    options: MultiselectOption[],
    selectedItems: MultiselectOption[],
    inputValue: string
) => {
    const lowerCasedInputValue = inputValue.toLowerCase();

    return options.filter((option) => {
        let itemPreselected = false;
        if (selectedItems) {
            itemPreselected = !!selectedItems.find(item => option.value === item.value)
        }
        return !itemPreselected && option.label.toLowerCase().includes(lowerCasedInputValue);
    });
};

export const MultiSelect: FC<{
    label?: string;
    placeholder?: string;
    onChange?: (values: MultiselectOption[]) => void,
    options: MultiselectOption[],
    selectedValues?: MultiselectOption[],
}> = ({
    label,
    placeholder,
    onChange,
    options,
    selectedValues,
}) => {
    const [inputValue, setInputValue] = useState('');
    const [selected, setSelected] = useState<MultiselectOption[]>(
        selectedValues || []
    );
    const [showMobileChoices, setShowMobileChoices] = useState<boolean>(false);

    useEffect(() => {
        setSelected(selectedValues || []);
    }, [selectedValues]);

    const items = React.useMemo(
      () => filterOptions(options, selected, inputValue),
      [options, selected, inputValue],
    );

    const {
        getSelectedItemProps,
        getDropdownProps,
        removeSelectedItem,
    } = useMultipleSelection({
        selectedItems: selected,
        onStateChange: ({ selectedItems, type }) => {
            switch (type) {
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownBackspace:
                    setSelected(selectedItems || []);
                    onChange && onChange(selectedItems || []);
                    setInputValue('');
                break;
                case useMultipleSelection.stateChangeTypes.SelectedItemKeyDownDelete:
                case useMultipleSelection.stateChangeTypes.DropdownKeyDownBackspace:
                case useMultipleSelection.stateChangeTypes.FunctionRemoveSelectedItem:
                    setSelected(selectedItems || []);
                    onChange && onChange(selectedItems || []);
                break;
            }
        },
    });
    const {
        isOpen,
        getLabelProps,
        getMenuProps,
        getInputProps,
        highlightedIndex,
        getItemProps,
    } = useCombobox({
        items,
        itemToString: (item) => item ? item.label : '',
        defaultHighlightedIndex: 0,
        selectedItem: null,
        stateReducer: (state, actionAndChanges) => {
            const { changes, type } = actionAndChanges;

            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    return {
                        ...changes,
                        inputValue,
                        isOpen: true,
                        highlightedIndex: 0,
                    };
                default:
                    return changes;
            }
        },
        onStateChange: ({
            inputValue: newInputValue,
            type,
            selectedItem: newSelectedItem,
        }) => {
            switch (type) {
                case useCombobox.stateChangeTypes.InputKeyDownEnter:
                case useCombobox.stateChangeTypes.ItemClick:
                    if (newSelectedItem) {
                        const newData = [
                            ...(selected || []),
                            newSelectedItem
                        ];
                        setSelected(newData);
                        onChange && onChange(newData);
                    }
                break;

                case useCombobox.stateChangeTypes.InputBlur:
                    setInputValue('');
                break;

                case useCombobox.stateChangeTypes.InputChange:
                    setInputValue(newInputValue || '');
                break;
            }
        },
    });

    const closeMobileOptions = useCallback(() => {
        setShowMobileChoices(false);
        onChange && onChange(selected)
    }, [selected, onChange]);
    const handleMobileOptionClick = (option: MultiselectOption) => () => setSelected(selected => {
        const filtered = selected.filter(s => s.value !== option.value);

        return (filtered.length !== selected.length) ? filtered : [
            ...selected,
            option,
        ];
    });

    return (
        <Relative>
            <Wrap
                $open={!!(isOpen && items.length)}
                legend={<label {...getLabelProps()}>{label}</label>}
            >
                {(selected || []).map((item, index) => (
                    <SelectedOption key={`selected-item-${index}`}>
                        <div {...getSelectedItemProps({
                            selectedItem: item,
                            index,
                        })}>
                            {item.label}
                        </div>
                        <RemoveButton
                            type="button"
                            onClick={() => removeSelectedItem(item)}
                        >
                            &#10005;
                        </RemoveButton>
                    </SelectedOption>
                ))}
                <StyledInput
                    placeholder={placeholder}
                    {...getInputProps(getDropdownProps({
                        preventKeyAction: isOpen
                    }))}
                />
                <MobileAdd
                    type="button"
                    onClick={useCallback(() => setShowMobileChoices(true), [])}
                >
                    Add Choices
                </MobileAdd>
            </Wrap>
            <OptionsList
                $open={!!(isOpen && items.length)}
                {...getMenuProps()}
            >
                {isOpen && items.map((item, index) => (
                    <OptionItem
                        $highlight={highlightedIndex === index}
                        key={`${item.value}${index}`}
                        {...getItemProps({item, index})}
                    >
                        {item.label}
                    </OptionItem>
                ))}
            </OptionsList>
            {showMobileChoices && <>
                <MobileOptionWindow>
                    <MobileOptionList>
                        {options.map((option, index) => {
                            const isSelected = !!selected.find(
                                selectedOption => selectedOption.value === option.value
                            )
                            return (
                                <OptionItem
                                    $highlight={isSelected}
                                    key={`${option.value}${index}`}
                                    data-value={option.value}
                                    onClick={handleMobileOptionClick(option)}
                                >
                                    {isSelected ? '✓' : ''} {option.label}
                                </OptionItem>
                            )
                        })}
                    </MobileOptionList>
                    <Button
                        type="button"
                        onClick={closeMobileOptions}
                    >
                        Close Options
                    </Button>
                </MobileOptionWindow>
                <ModalBackdrop $open={true} $zIndex={1999} />
            </>}
        </Relative>
    );
};
