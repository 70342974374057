import React, { FC, useCallback, useEffect, useMemo, useState } from "react";
import { Navigate, useLocation, useSearchParams } from "react-router-dom";
import { FieldValues, useForm, FormProvider } from "react-hook-form";
import styled, {createGlobalStyle} from "styled-components";
import { useApiTokenContext, useAuthenticateApi } from "src/api";
import { AppRoute } from "src/ui/routes";
import { cssVar, mediaQuery } from "src/ui/styles";
import {
    H1,
    H4,
    H5,
    Paragraph,
    Strong,
} from "src/ui/primitives";
import {
    emailRegexp,
    FieldError,
    FormInput,
} from "src/ui/primitives/form";
import { useClearUser } from "src/ui/user";
import { Logo } from "src/icons";

export const useLoginRouteWithReturn = () => {
    const { pathname, search } = useLocation();

    return useMemo(
        () => `${AppRoute.login}?backUrl=${encodeURIComponent(pathname + search)}`,
        [pathname, search]
    );
};

export const Logout: FC = () => {
    const { remove } = useApiTokenContext();
    const clearUser = useClearUser();

    useEffect(() => {
        remove();
        clearUser();
        window.location.assign(AppRoute.homepage);
    }, [remove, clearUser]);

    return null;
}

export const BodyStyle = createGlobalStyle`
    html, body {
        background: var(${cssVar.accent});
    }
`;

export const H1Login = styled(H1)`
    text-align: center;
`;

const LoginLogo = styled(Logo)`
    width: auto;
    height: 100px;
    margin: 0 auto;
    display: block;
`;

const LoginHolder = styled.div`
    background-color: var(${cssVar.lightshade});
    box-shadow: 5px 5px 10px 0 var(${cssVar.lightaccent});
    max-width: 65%;
    padding: 1rem 1.5rem 2rem 1.5rem;
    border-radius: .5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    box-sizing: border-box;
    color: var(${cssVar.darkshade});
    border: 1px solid var(${cssVar.darkshade});

    min-width: 90%;
    ${mediaQuery.large(`
        min-width: 35%;
    `)}
`;
const Form = styled.form`
    margin-bottom: 2rem;
`;
const SubmitButton = styled.button`
    width: 100%;
    margin:  .5rem 0;
    padding: .25rem;
    color: var(${cssVar.lightshade});
    background-color: var(${cssVar.accent});
    border-color: var(${cssVar.darkaccent});
    border-radius: .25rem;
    box-shadow: 1px 1px 5px 0px var(${cssVar.darkshade});
    font: var(${cssVar.maintext});
    box-sizing: border-box;  
    font-size: 1.25rem;
    border: 1px solid var(${cssVar.darkshade});

    &:hover {
        box-shadow: 3px 3px 8px 0px var(${cssVar.darkshade});
        background-color: var(${cssVar.darkaccent});
    }
`;
const AboutLinks = styled.div`
    display: flex;
    flex-direction: row;
    align-content: stretch;
    justify-content: space-between;

    a {
        text-align: center;
        color: var(${cssVar.accent});
        font-weight: 600;
        text-decoration: none;

        &:hover {
            text-decoration: underline;
            color: var(${cssVar.darkaccent});
        }
    }
`;
const Center = styled.p`text-align: center;`;

export const Login: FC = () => {
    const formMethods = useForm();
    const {
        register,
        handleSubmit,
        watch,
    } = formMethods;
    const [loading, setLoading] = useState<boolean>(false);
    const [submitError, setSubmitError] = useState<boolean>(false);
    const { token } = useApiTokenContext();
    const authenticate = useAuthenticateApi();
    const [params] = useSearchParams();

    const submit = useCallback(async (values: FieldValues) => {
        setLoading(true);
        setSubmitError(false);
        try {
            await authenticate(
                values.email,
                values.password
            );
        } catch {
            setSubmitError(true);
        }
        setLoading(false);
    }, [authenticate]);

    const email = watch("email");
    const password = watch("password");

    useEffect(() => {
        setSubmitError(false);
    }, [email, password]);

    if (token) {
        const backParam = params.get('backUrl');
        const backUrl = backParam === '/' || !backParam
            ? AppRoute.dashboard
            : backParam;
        return (
            <Navigate to={backUrl} replace={true} />
        );
    }

    return (
        <LoginHolder>
            <BodyStyle />
            <H1Login>Welcome to</H1Login>
            <LoginLogo variant="dark" />
            <H4>Please Login</H4>
            <FormProvider {...formMethods}>
                <Form onSubmit={handleSubmit(submit)}>
                    <FormInput
                        type="text"
                        placeholder="Email"
                        aria-label="Email"
                        {...register("email", {
                            required: "Email is required",
                            pattern: {
                                value: emailRegexp,
                                message: "Please enter a valid email",
                            },
                            disabled: loading,
                        })}
                    />
                    <FormInput
                        type="password"
                        placeholder="Password"
                        aria-label="Password"
                        {...register("password", {
                            required: "Password is required",
                            disabled: loading
                        })}
                    />
                    {submitError && (
                        <FieldError>
                            <H5>Error: </H5>
                            <Paragraph>Email or Password not recognized</Paragraph>
                        </FieldError>
                    )}
                    <SubmitButton type="submit" disabled={loading}>
                        Login
                    </SubmitButton>
                </Form>
            </FormProvider>
            <Center>
                What is <Strong>Worldwide Investments</Strong>?
            </Center>
            <AboutLinks>
                <a href="#investors">What we offer investors</a>
                <a href="#sellers">What we offer sellers</a>
            </AboutLinks>
        </LoginHolder>
    );
};
