import { Countries, Regions, Timezones } from "src/types";

export enum CacheKeys {
    countries = 'countries',
    timezones = 'timezones',
    regions = 'regions',
};

export type CacheTypes = {
    [CacheKeys.countries]: Countries,
    [CacheKeys.timezones]: Timezones,
    [CacheKeys.regions]: Regions,
};

export const cacheUrls: Record<keyof CacheTypes, string> = {
    countries: '/api/intl/countries/',
    timezones: '/api/intl/timezones/',
    regions: '/api/intl/regions/',
};
