import React, { FC, ReactNode, useCallback, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { mediaQuery, cssVar } from "src/ui/styles";
import { useTopLevelRoutes } from "./hooks";
import { UserMenu } from "./UserMenu";
import { ModalBackdrop } from "src/ui/primitives";
import { Logo } from "src/icons";
import { InstallPrompt } from "../install";

const width = '300px';
const buttonSizeRem = 3;

const StyledLogo = styled(Logo)`
    width: auto;
    height: 50px;
`;
const DesktopStyledLogo = styled(Logo)`
    width: auto;
    height: 75px;
`;
const Container = styled.div<{
    'data-open': boolean,
}>`
    position: fixed;
    display: flex;
    flex-direction: column;
    top: 0;
    left: ${props => props['data-open'] ? '0' : '-310px'};
    min-width: ${width};
    max-width: ${width};
    box-sizing: border-box;
    height: 100%;
    background: var(${cssVar.darkshade});
    border-right: 3px solid var(${cssVar.accent});
    transition: left .25s ease;
    font-size: 1.15rem;
    ${mediaQuery.large(`
        position: static;
        left: 0;
    `)}
    z-index: 1000;
`;
const Backdrop = styled(ModalBackdrop)`
    ${mediaQuery.large(`
        display: none;
    `)}
`;
const MenuButton = styled.button`
    width: ${buttonSizeRem}rem;
    height: ${buttonSizeRem}rem;
    background: var(${cssVar.accent});
    border: none;
    color: var(${cssVar.lightshade});
    border-radius: .3rem;
    border: 1px solid var(${cssVar.lightaccent});
    font-size: 2rem;
    margin: .5rem;
    font-weight: 700;
    ${mediaQuery.large(`
        display: none;
    `)};
`;
const MenuLink = styled(NavLink)`
    display: block;
    padding: 1rem 2rem;
    line-height: 1.5rem;
    border-bottom: 2px solid var(${cssVar.darkaccent});
    &:first-of-type {
        border-top: 1px solid var(${cssVar.darkaccent});
    }
    color: var(${cssVar.lightshade});
    font-weight: 600;
    &:hover, &.active {
        background: var(${cssVar.lightaccent});
        color: var(${cssVar.accent});
        font-size: 1.25rem;
        border-color: var(${cssVar.accent});
    }
    &.active {
        background: var(${cssVar.lightshade});
    }
`;
const MenuHeaderWrap = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    max-width: 100vw;
    ${mediaQuery.large(`
        flex-direction: row;
    `)};
`;
const MenuHeader = styled.div`
    background: var(${cssVar.darkaccent});
    border-bottom: 2px solid var(${cssVar.accent});
    display: flex;
    justify-content: space-between;
    align-items: center;
    ${mediaQuery.large(`
        display: none;
    `)};
`;
const MobileLogo = styled.div`
    color: var(${cssVar.lightshade});
    
    text-align: right;
    padding-right: 1rem;
    font-size: 1.35rem;
    font-weight: 600;
    box-sizing: border-box;
`;
const DesktopLogo = styled.div`
    display: none;
    color: var(${cssVar.lightshade});
    background-color: var(${cssVar.darkaccent});
    border-bottom: 2px solid var(${cssVar.lightshade});
    text-align: center;
    font-size: 1.5rem;
    font-weight: 600;
    padding: 1.75rem .5rem;
    ${mediaQuery.large(`
        display: block;
    `)}
`;
const Scrollable = styled.div`
    flex: 1;
    overflow-y: auto;
`;

export const useMainMenu = () => {
    const [open, setOpen] = useState<boolean>(false);
    const [userMenuOpen, setUserMenuOpen] = useState<boolean>(false);
    const toggleMenu = useCallback(
        () => setOpen(o => !o),
        []
    );
    const toggleUserMenu = useCallback(
        () => setUserMenuOpen(o => !o),
        []
    );
    const closeMenu = useCallback(() => {
        setOpen(false);
        setUserMenuOpen(false);
    }, []);
    const closeUserMenu = useCallback(() => setUserMenuOpen(o => !o), []);

    return {
        open,
        userMenuOpen,
        toggleMenu,
        toggleUserMenu,
        closeMenu,
        closeUserMenu,
    };
};

export type MainMenuApi = ReturnType<typeof useMainMenu>;

export const MenuHeaderBar: FC<MainMenuApi & {
    children: ReactNode,
}> = ({
    toggleMenu,
    children,
}) => {
    return (
        <MenuHeaderWrap>
            <MenuHeader>
                <MenuButton onClick={toggleMenu}>
                    ≡
                </MenuButton>
                <MobileLogo>
                    <StyledLogo />
                </MobileLogo>
            </MenuHeader>
            {children}
        </MenuHeaderWrap>
    );
};

export const MainMenu: FC<MainMenuApi> = ({
    open,
    toggleMenu,
    closeMenu,
    userMenuOpen,
    toggleUserMenu,
    closeUserMenu,
}) => {
    const menuItems = useTopLevelRoutes();

    return <>
        <Backdrop
            $open={open}
            $zIndex={990}
            onClick={closeMenu}
        />
        <Container data-open={open}>
            <MenuButton onClick={toggleMenu}>
                ✕
            </MenuButton>
            <DesktopLogo>
                <DesktopStyledLogo />
            </DesktopLogo>
            <Scrollable>
                {menuItems.map(item => (
                    <MenuLink
                        key={item.route}
                        to={item.route}
                        onClick={closeMenu}
                    >
                        {item.label}
                    </MenuLink>
                ))}
            </Scrollable>
            <InstallPrompt />
            <UserMenu
                isOpen={userMenuOpen}
                toggle={toggleUserMenu}
                close={closeUserMenu}
                onItemClick={closeMenu}
            />
        </Container>
    </>;
};
