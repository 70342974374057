import { CountryShortCode, TimezoneValue } from "./intl";
import { PaginatedReponse } from "./pagination";

export enum ListingStatus {
    delisted = 0,
    listed = 1,
    pending = 2,
    sold = 3,
}

export type Listing = {
    id: number;
    title: string;
    listing_key: string;
    listing_id: string;
    timezone: TimezoneValue;
    status: ListingStatus;
    price: number;
    price_low: number;
    created_at: string; // datetime
    updated_at: string; // datetime
    address_id: number;
    country: CountryShortCode;
    address1: string;
    address2: string;
    city: string;
    region: string;
    postal: string;
    email: string;
    user_id: number;
    seller_name: string;
    cover_photo: ListingPhoto;
    bedrooms: number | null;
    bathrooms_full: number | null;
    living_area: number | null;
    lot_size: number | null;
    start_date: string | null; // datetime
    end_date: string | null; // datetime
};

export type ListingDetailField = {
    type: 'text' | 'url' | 'email' | 'number' | 'address' | 'date';
    name: string;
    required: boolean;
    max_length?: number;
    min?: number;
    max?: number;
    unit_choices?: null | string[];
};

export type ListingDetailSection = {
    section_name: string;
    fields: ListingDetailField[];
};

export type ListingOptionChoice = {
    id: number;
    name: string;
    type: string;
    order: number;
};

export type ListingChoiceOptions = PaginatedReponse<ListingOptionChoice>;

export type ListingPhoto = {
    id: number;
    created_at: string; // datetime
    updated_at: string; // datetime
    url: string;
    order: number;
    status: number;
    listing_id: number;
};
