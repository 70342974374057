import React, { FC, useMemo } from "react";
import { RegisterOptions, useFormContext } from "react-hook-form";
import { ListingDetailField } from "src/types";
import { FormInput, InputOption } from "src/ui/primitives/form";
import { mediaQuery } from "src/ui/styles";
import styled from "styled-components";

const Wrap = styled.div<{ $multi?: boolean }>`
    flex: 1;
    display: flex;
    flex-direction: row;
    min-width: 100%;
    box-sizing: border-box;
    ${mediaQuery.verticalmedium(`
        min-width: 33.3%;
        &:last-child {
            max-width: 50%;
        }
    `)}
    ${mediaQuery.xlarge(`
        min-width: 20%;
        &:last-child {
            max-width: 33%;
        }
    `)}
    gap: .5rem;
    align-items: flex-end;
    ${props => props.$multi && `
        background-color: #00000011;
        padding: 0 .5rem;
        border-radius: .5rem;
        > :first-child {
            flex: 2;
        }
    `}
`;

const boolOptions: InputOption[] = [
    { label: ' ', value: '' },
    { label: 'Yes', value: '1' },
    { label: 'No', value: '0' },
]

export const ListingDetailNumberField: FC<{
    field: ListingDetailField,
    registerOptions: RegisterOptions,
    fieldLabel: string,
}> = ({
    field,
    registerOptions,
    fieldLabel,
}) => {
    const { register } = useFormContext();

    // can return NaN
    // registerOptions.valueAsNumber = true;
    registerOptions.setValueAs = (value: string) => {
        const num = parseFloat(value);

        return isNaN(num) ? null : num;
    };

    if (typeof field.min === 'number') {
        registerOptions.min = {
            value: field.min,
            message: `${fieldLabel} must be ${field.min.toLocaleString()} or more`,
        };
    }

    if (typeof field.max === 'number') {
        registerOptions.max = {
            value: field.max,
            message: `${fieldLabel} must be ${field.max.toLocaleString()} or less`,
        };
    }

    const isBool = field.min === 0 && field.max === 1;

    const unitOptions: InputOption[] | undefined = useMemo(() => {
        if (!field.unit_choices) {
            return undefined;
        }
        return [
            { label: ' ', value: '' },
            ...field.unit_choices.map(choice => ({
                label: choice,
                value: choice
            }))
        ];
    }, [field.unit_choices]);

    return (
        <Wrap $multi={!!unitOptions}>
            <FormInput
                type="number"
                label={fieldLabel}
                min={field.min}
                max={field.max}
                placeholder="Number"
                options={isBool ? boolOptions : undefined}
                {...register(field.name, registerOptions)}
            />
            {unitOptions && (
                <FormInput
                    options={unitOptions}
                    {...register(field.name + '$unit', {
                        disabled: registerOptions.disabled,
                    })}
                />
            )}
        </Wrap>
    );
}