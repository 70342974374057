import React, { ChangeEvent, FC, FocusEventHandler, KeyboardEventHandler, useCallback, useState } from "react";
import { fetchApi } from "src/api";
import { Button, PagePadding } from "src/ui/primitives";
import styled from "styled-components";

const Wrap = styled(PagePadding)`
    flex: 1;
    display: flex;
    flex-direction: column;
`;

export const ApiQuery: FC = () => {
    const [url, setUrl] = useState<string>('');
    const [output, setOutput] = useState<string>('');
    const [status, setStatus] = useState<string>('');
    const [fetching, setFetching] = useState<boolean>(false);

    const handleChange = useCallback(
        (ev: ChangeEvent<HTMLInputElement>) => setUrl(ev.target.value),
        []
    );
    const handleFocusOutput: FocusEventHandler<HTMLPreElement> = useCallback(
        (ev) => window.getSelection()?.selectAllChildren(ev.target),
        []
    )
    const runQuery = useCallback(async () => {
        setFetching(true);
        setOutput('Loading...');
        let response;
        try {
            response = await fetchApi('/api/' + url);
            const json = await response.json();
            setStatus(`${response.status}`);
            setOutput(JSON.stringify(json, null, 2));
        } catch {
            setStatus(`${response?.status}`);
            setOutput('FETCH FAILED, CHECK CONSOLE');
        } finally {
            setFetching(false);
        }
    }, [url]);
    const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = useCallback(
        (ev) => {
            if (ev.key === "Enter")
                runQuery();
        },
        [runQuery]
    );

    return (
        <Wrap>
            <input
                type="text"
                value={url}
                onChange={handleChange}
                onKeyDown={handleKeyDown}
                disabled={fetching}
                placeholder="API endpoint excluding /api/ (example: user)"
            />
            <Button
                onClick={runQuery}
                disabled={fetching}
            >
                Fetch
            </Button>
            {status && <pre>HTTP status: {status}</pre>}
            <pre tabIndex={0} onFocus={handleFocusOutput}>
                {output}
            </pre>
        </Wrap>
    )
};