import React, { FC } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { icon } from '@fortawesome/fontawesome-svg-core/import.macro'
import { SizeProp } from "@fortawesome/fontawesome-svg-core";
import {
    faBed,
    faBath,
    faRuler,
    faMountain,
    faToilet,
    faCaretDown,
    faCaretUp,
    faCaretRight,
    faTableCellsLarge,
    faCalendar,
    faArrowUpRightFromSquare,
    faStairs,
    faCar,
    faTree,
    faMagnifyingGlassMinus,
} from "@fortawesome/free-solid-svg-icons";

const ICON_MAP = {
    'bed': faBed,
    'bath': faBath,
    'halfBath': faToilet,
    'ruler': faRuler,
    'mountain': faMountain,
    'caretDown': faCaretDown,
    'caretUp': faCaretUp,
    'caretRight': faCaretRight,
    'cells': faTableCellsLarge,
    'calendar': faCalendar,
    'externalLink': faArrowUpRightFromSquare,
    'stairs': faStairs,
    'car': faCar,
    'tree': faTree,
    'magnifyingMinus': faMagnifyingGlassMinus,
}

export const Icon: FC<{
    name: keyof typeof ICON_MAP;
    size?: SizeProp;
    title?: string;
    color?: string;
    className?: string;
}> = ({
    name,
    size,
    title,
    color = '#999',
    className,
}) => (
    <FontAwesomeIcon
        // icon={icon({ name, style })}
        icon={ICON_MAP[name]}
        size={size}
        title={title}
        color={color}
        className={className}
    />
);
