import React, { FC } from "react";
import { useIsLoggedIn } from "src/api";
import { ListingById, Listings } from "../listings";
import styled from "styled-components";
import { cssVar } from "src/ui/styles";
import { Logo } from "src/icons";
import { Link, useParams } from "react-router-dom";
import { AppRoute } from "src/ui/routes";
import { useLoginRouteWithReturn } from "../Login";

const Wrap = styled.div`
    display: flex;
    flex: 1;
    overflow: auto;
    flex-direction: column;
`;
const Header = styled.div`
    background: var(${cssVar.darkaccent});
    border-bottom: 2px solid var(${cssVar.accent});
    display: flex;
    justify-content: flex-end;
    align-items: stretch;
`;
const LogoWrap = styled.div`
    color: var(${cssVar.lightshade});
    
    text-align: right;
    padding-right: 1rem;
    margin: .5rem;
    font-size: 1.35rem;
    font-weight: 600;
    box-sizing: border-box;
`;
const StyledLogo = styled(Logo)`
    width: auto;
    height: 50px;
`;
const StyledLink = styled(Link)`
    color: #ddd;
    display: flex;
    align-items: center;
    :hover {
        color: #eee;
    }
`;
const HeroImage = styled.div`
    min-height: 33vh;
    background-image: url(homepagehero.jpg);
    background-size: cover;
`;

export const Homepage: FC = () => {
    const { listingId } = useParams();
    const loggedIn = useIsLoggedIn();
    const loginRoute = useLoginRouteWithReturn();

    return (
        <Wrap>
            <Header>
                {loggedIn ? (
                    <StyledLink to={AppRoute.dashboard}>
                        Dashboard
                    </StyledLink>
                ) : (
                    <StyledLink to={loginRoute}>
                        Log in
                    </StyledLink>
                )}
                <Link to={AppRoute.homepage}>
                    <LogoWrap>
                        <StyledLogo />
                    </LogoWrap>
                </Link>
            </Header>
            {listingId ? (
                <ListingById route={AppRoute.publicListingById} />
            ) : <>
                <HeroImage />
                <Listings publicPage={true} />
            </>}
        </Wrap>
    );
};
