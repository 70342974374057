import React, {
    FC,
    ComponentProps,
    forwardRef,
    useState,
    useEffect,
    ElementType,
    useCallback,
    useRef,
} from "react";
import styled from "styled-components";
import { useFormContext } from "react-hook-form";
import { cssVar } from "src/ui/styles";
import { FieldError } from "./FieldError";
import { assignRefs } from "src/util";

const inputStyles = `
    width: 100%;
    margin: .5rem 0rem;
    padding: .5rem;
    box-sizing: border-box;
    font-size: 1.25rem;
    font: var(${cssVar.maintext});
    border: 1px solid var(${cssVar.darkshade});
    border-radius: 4px;
    background: white;

    &:disabled {
        background: var(${cssVar.lightshade});
    }
`;

export const Input = styled.input`
    ${inputStyles}

    // to hide arrow buttons for input type number
    -moz-appearance: textfield;
    appearance: textfield;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0;
    }
    &:read-only {
        background: #ddd;
        text-decoration: line-through;
        color: #555;
    }
`;
export const TextArea = styled.textarea`
    ${inputStyles}
    min-height: 3rem;
    resize: none;
    overflow: hidden;
`;
const Select = styled.select`
    position: relative;
    padding-right: .5rem;
    ${inputStyles}
`;
const LoadingOptions = styled.div`
    ${inputStyles}
    background-color: #eee;
    ::before {
        content: 'Loading';
    }
`;

const FieldWrap = styled.div`
    flex: 1;
`;

export type InputOption = {
    value: string,
    label: string,
}

export type OnOptionsLoaded = (
    ref: HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement | undefined,
    options: InputOption[]
) => void;

export const FormInput: FC<
    ComponentProps<typeof Input> &
    {
        label?: string,
        options?: InputOption[],
        multiline?: boolean,
        loadOptions?: () => Promise<InputOption[]>,
        selectErrors?: (errors: any) => Record<string, string> | undefined,
        onOptionsLoaded?: OnOptionsLoaded,
    }
> = forwardRef(
    (
        {
            label,
            options,
            multiline,
            loadOptions,
            onOptionsLoaded,
            className,
            selectErrors,
            ...props
        },
        ref
    ) => {
        const formContext = useFormContext();
        const {
            formState: { errors },
        } = formContext || { formState: {} };
        const [loadedOptions, setLoadedOptions] = useState<InputOption[]>([]);
        const [optionsLoaded, setOptionsLoaded] = useState<boolean>(false);
        const localRef = useRef<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>();

        // THIS MEANS, DO NOT USE USECALLBACK FOR ANY ONOPTIONSLOADED
        // this is pretty much only for addresses messed up region.
        const onLoaded = useRef<OnOptionsLoaded>();
        onLoaded.current = onOptionsLoaded;

        useEffect(() => {
            (async () => {
                if (loadOptions) {
                    setOptionsLoaded(false);
                    const newOptions = await loadOptions();
                    setLoadedOptions(newOptions);
                    onLoaded.current && onLoaded.current(
                        localRef.current,
                        newOptions
                    );
                    setOptionsLoaded(true);
                }
            })();
        }, [loadOptions]);

        const InputComponent: ElementType = multiline ? TextArea : Input;
        const setMultilineSize = useCallback(
            () => {
                if (!multiline) return;

                const target = localRef.current as unknown as HTMLTextAreaElement;
                if (target) {
                    target.style.height = '0';
                    target.style.height = (target.scrollHeight + 3) + 'px';
                }
            },
            [multiline]
        );

        const selectedErrors = selectErrors
            ? selectErrors(errors)
            : errors && errors[props.name];

        useEffect(() => {
            if (multiline) {
                setMultilineSize();
            }
        }, [multiline, setMultilineSize])

        return (
            <FieldWrap className={className}>
                <label>
                    {label}
                    {loadOptions && !optionsLoaded ? (
                        <LoadingOptions />
                    ) : options || loadedOptions.length ? (
                        <Select
                            {...props}
                            ref={assignRefs(ref, localRef)}
                            aria-invalid={errors[props.name] ? "true" : "false"}
                        >
                            {(options || loadedOptions).map(option =>
                                <option
                                    value={option.value}
                                    key={option.value + option.label}
                                >
                                    {option.label}
                                </option>
                            )}
                        </Select>
                    ) : (
                        <InputComponent
                            {...props}
                            ref={assignRefs(ref, localRef)}
                            onInput={setMultilineSize}
                            aria-invalid={selectedErrors ? "true" : "false"}
                        />
                    )}
                    {selectedErrors && (
                        <FieldError>
                            { /* @ts-ignore */ }
                            {selectedErrors.message || "Error"}
                        </FieldError>
                    )}
                </label>
            </FieldWrap>
        );
    }
);
