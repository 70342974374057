import { createGlobalStyle } from "styled-components";

export const cssVar = {
    darkshade: '--darkshade',
    darkaccent: '--darkaccent',
    accent: '--accent',
    lightshade: '--lightshade',
    lightaccent: '--lightaccent',
    maintext: '--maintext',
    headingtext: '--headingtext',
    transparentaccent: '--transparentaccent',
    transparentthick: '--transparentthick',
    transparentshade: '--transparentshade',
    transparentbarely: '--transparentbarely',
};

export const GlobalStyle = createGlobalStyle`
    :root {
        ${cssVar.darkshade}: #39363b;
        ${cssVar.darkaccent}: #746e7a;
        ${cssVar.accent}: #532b77;
        ${cssVar.lightshade}: #f8f8f8;
        ${cssVar.lightaccent}: #97919c;
        ${cssVar.transparentaccent}: #532b7770; 
        ${cssVar.transparentthick}: #532b77c0; 
        ${cssVar.transparentshade}: #7b7e8125;
        ${cssVar.transparentbarely}: #532b77f0;
        ${cssVar.maintext}:  'Helvetica Neue', Helvetica, 'Questrial', sans-serif;;
        ${cssVar.headingtext}: 'Evoria Modern','Abril Fatface', serif;
    }
    html, body {
        background: var(${cssVar.lightshade});
        font-family: var(${cssVar.maintext});
        color: var(${cssVar.darkshade});
        margin: 0;
        padding: 0;
        font-weight: 400;
        font-style: normal;
        font-size: 18px;
    }
    * {
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }
    a {
        text-decoration: none;
        color: var(${cssVar.accent});
        &:hover {
            var(${cssVar.lightaccent});
        }
    }
`;
