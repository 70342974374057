import { Ref, MutableRefObject } from "react";

// https://stackoverflow.com/a/72550533
// what if you're using forwardRef and also need a local ref?
// refs can be functions or more modern objects with "current".
// this lets you assign multiple refs to one component.
/*
    example:
    <input ref={assignRefs(foreignRef, localRef)} />
*/
export const assignRefs = <T extends unknown>(...refs: Ref<T | null>[]) =>
    (node: T | null) => {
        refs.forEach((r) => {
            if (typeof r === "function") {
                r(node);
            } else if (r) {
                (r as MutableRefObject<T | null>).current = node;
            }
        });
    };
