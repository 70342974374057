import React, { FC, useCallback } from "react";
import {
    unstable_useBlocker as useBlocker,
    useBeforeUnload,
} from "react-router-dom";
import styled from "styled-components";
import { Button } from "./Button";
import { Flex } from "./Flex";
import { ModalBackdrop } from "./ModalBackdrop";
import { H2, Paragraph } from "./text";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
`;
const Dialog = styled.div`
    background-color: white;
    padding: 1rem;
    border-radius: .3rem;
    margin: 1rem;
`;

export const NavPrompt: FC<{
    text?: string;
    active?: boolean;
}> = ({
    text = 'Are you sure you want to leave? You could lose changes.',
    active,
}) => {
    const {
        state,
        reset,
        proceed,
    } = useBlocker(!!active);
    const blocked = state === 'blocked';

    useBeforeUnload(
        useCallback((event) => {
            if (active) {
                event.preventDefault();
                return (event.returnValue = text);
            }
        }, [active, text])
    );

    return <>
        <ModalBackdrop
            $open={blocked}
            $zIndex={999}
        />
        {!blocked ? null : (
            <Container>
                <Dialog>
                    <H2>Are you sure?</H2>
                    <Paragraph>
                        {text}
                    </Paragraph>
                    <Flex
                        $align="center"
                        $justify="center"
                    >
                        <Button
                            type="button"
                            onClick={reset}
                        >
                            Stay here
                        </Button>
                        <Button
                            type="button"
                            onClick={proceed}
                        >
                            Leave
                        </Button>
                    </Flex>
                </Dialog>
            </Container>
        )}
    </>;
};
